import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        cart: [],
        department:[],
        municipalities:[],
        zones:[],
        eventstypes:[],
        tokenwompi:'',
        paywompi:{},
        order:0
    },

    getters: {
        cart(state) {
            const initialTotals = { total: 0, qty: 0, shipping: 0 };

            const totals = state.cart.reduce((acc, item) => {
                // Incrementa la cantidad total de productos
                acc.qty += item.qty;

                if (item.isShipping) {
                    // Incrementa el costo de envío total si el ítem es un producto de envío
                    acc.shipping += 3.00;
                } else {
                    // Incrementa el total general del carrito
                    acc.total += (item.price * item.qty);
                }

                return acc;
            }, initialTotals);

            return {
                ...totals,
                cart: state.cart
            };
        },

        department(state){
            return state.department;
        },
        municipalities(state){
            return state.municipalities;
        },
        zones(state){
            return state.zones;
        },
        eventstypes(state){
            return state.eventstypes;
        },
        inCart: (state) => (id) => {
            let product = state.cart.find(item => item.id === id)

            return product
        },
        tokenwompi(state){
            return state.tokenwompi;
        },
        paywompi(state){
            return state.paywompi;
        },
        order(state){
            return state.order;
        }
    },

    mutations: {
        addToCart(state, payload) {
            let item = state.cart.find(item => {
                return (item.id == payload.id) && (item.date == payload.date)
            })
            if(item) {
                item.qty += Number(payload.qty)
            } else {
                state.cart.push(payload)
            }
        },
        removeFromCart(state, id) {
            let index = state.cart.findIndex(p => p.id == id)

            if(index >= 0) {
                state.cart.splice(index, 1)
            }
        },
        setCart(state, cart) {
            Vue.set(state, 'cart', cart)
        },

        setDepartment(state, department) {
            Vue.set(state, 'department', department)
        },
        setMunicipalities(state, municipalities) {
            Vue.set(state, 'municipalities', municipalities)
        },
        setZones(state, zones) {
            Vue.set(state, 'zones', zones)
        },
        setEventsTypes(state, eventstypes) {
            Vue.set(state, 'eventstypes', eventstypes)
        },
        setTokenWompi(state, tokenwompi) {
            Vue.set(state, 'tokenwompi', tokenwompi)
        },
        setPayWompi(state, paywompi) {
            Vue.set(state, 'paywompi', paywompi)
        },
        setOrder(state,order){
            Vue.set(state,'order',order)
        }

    },

    actions: {

        async getCart({commit}) {
            try {
                let {data} = await window.axios.get(`/cart`)
                commit('setCart', data)
                return Promise.resolve(data)
            } catch (error) {
                return Promise.reject(data)
            }
        },
        WompiAuth: async ({commit}) => {
            var payload={
                appId: "2c4685dd-9ca3-456c-b21c-59edc0376f15",
                apiSecret: "7a81ec38-e8c1-41f2-87f4-1602a491bd90"
            }
            try {
                let {data} = await window.axios_wompi.post("/Auth", payload)
                commit('setTokenWompi', data.access_token)
                return Promise.resolve(data)

            } catch (error) {
                return Promise.reject(error)
            }
        },
        WompiPay: async ({commit},datacard) => {
            var payload={
                tarjetaCreditoDebido: {
                  numeroTarjeta: datacard.cardnumber,
                  cvv: datacard.ccv,
                  mesVencimiento: parseInt(datacard.expiredate.split('/')[0]),
                  anioVencimiento: parseInt(datacard.expiredate.split('/')[1])
                },
                monto: datacard.monto,
                emailCliente: datacard.email,
                nombreCliente: datacard.name_holder,
                formaPago: "PagoNormal",
                configuracion: {
                  emailsNotificacion: "info@eventosydetalles.com.sv",
                  notificarTransaccionCliente: true
                },
                token: datacard.token
              }
              console.log(payload)
            try {
                let {data} = await window.axios_wompi.post("/Payments", payload)
                commit('setPayWompi', data)
                return Promise.resolve(data)

            } catch (error) {
                return Promise.reject(error)
            }
        },
        addOrder:async ({commit},payload)=>{
            try {
                console.log('Payload for save order: ',  { payload })
                let {data} = await window.axios.post(`/order/add`, payload)
                commit('setOrder',data);
                console.log('Data for response in save order: ', { data })
                return Promise.resolve(data);
            } catch (error) {
                return Promise.reject(data);
            }
        },
        calculateTotalOrder: async => {

        },
        addToCart: async ({commit}, payload) => {
            console.log('addToCart => ', payload)
            commit('addToCart', {
                id: payload.id,
                date: payload.date,
                price: payload.price_float,
                qty: payload.qty,
                thumbnail: payload.thumbnail,
                name: payload.name,
                description: payload.description,
                isShipping: payload.isShipping,
            })
            try {
                let {data} = await window.axios.post(`/cart/add`, {
                    id: payload.id,
                    date: payload.date,
                    qty: payload.qty,
                    price: payload.price_float,
                    isShipping: payload.isShipping,
                })
                 commit('setCart', data)
                return Promise.resolve(data)
            } catch (error) {
                return Promise.reject(error)
            }
        },
        removeFromCart: async ({commit}, payload) => {
            commit('removeFromCart', {
                id: payload.id,
                date: payload.date,
                price: payload.price,
                qty: payload.qty
            })
            try {
                let {data} = await window.axios.post(`/cart/remove`, {
                    id: payload.id,
                    date: payload.date,
                    qty: payload.qty
                })
                commit('setCart', data)
                return Promise.resolve(data)
            } catch (error) {
                return Promise.reject(error)
            }
        },
        updateCart: async ({commit}, payload) => {
            try {
                let {data} = await window.axios.post(`/cart/update`, payload)
                commit('setCart', data)
                return Promise.resolve(data)
            } catch (error) {
                return Promise.reject(error)
            }
        },
        getDepartment: async ({commit})=>{
            try {
                let {data} = await window.axios.get(`/location/department`)
                commit('setDepartment',data);
                console.log(JSON.stringify(data))
                return Promise.resolve(data);
            } catch (error) {
                return Promise.reject(data);
            }
        },
        getMunicipalities: async ({commit},payload)=>{
            try {
                let {data} = await window.axios.get(`/location/municipalities/`+payload)
                commit('setMunicipalities',data);
                console.log(JSON.stringify(data))
                return Promise.resolve(data);
            } catch (error) {
                return Promise.reject(data);
            }
        },
        getZones: async ({commit},payload)=>{
            try {
                let {data} = await window.axios.get(`/location/zones/`+payload)
                commit('setZones',data);
                console.log(JSON.stringify(data))
                return Promise.resolve(data);
            } catch (error) {
                return Promise.reject(data);
            }
        },
        getEventsType: async ({commit},payload)=>{
            try {
                let {data} = await window.axios.get(`/events/events_types`)
                commit('setEventsTypes',data);
                console.log(JSON.stringify(data))
                return Promise.resolve(data);
            } catch (error) {
                return Promise.reject(data);
            }
        },
        addEvent:async ({commit},payload)=>{
            try {
                let {data} = await window.axios.post(`/events/add`,payload)
                return Promise.resolve(data);
            } catch (error) {
                return Promise.reject(error);
            }
        },
        addContact:async ({commit},payload)=>{
            try {
                let {data} = await window.axios.post(`/contact/add`,payload)
                return Promise.resolve(data);
            } catch (error) {
                return Promise.reject(data);
            }
        },
        archiveOrden:async ({commit},payload)=>{
            try {
                let {data} = await window.axios.post(`/administrador/ordenes/archive`,payload)
                return Promise.resolve(data);
            } catch (error) {
                return Promise.reject(data);
            }
        },
        deleteOrden:async ({commit},payload)=>{
            try {
                let {data} = await window.axios.post(`/administrador/ordenes/eliminar`,payload)
                return Promise.resolve(data);
            } catch (error) {s
                return Promise.reject(data);
            }
        },
        showLoading:({commit})=>{
            try {
                var domloading=document.querySelector(".loading");
                domloading.className ="loading showloading"
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                document.getElementsByTagName("body")[0].className="hiddenscroll"
                console.log("show loading ....")
            } catch (error) {
            }
        },
        showDeposite:({commit})=>{
            try {
                const domloading=document.querySelector(".deposite");
                domloading.className ="deposite showloading"
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                document.getElementsByTagName("body")[0].className="hiddenscroll"
                console.log("show deposite ....")
            } catch (error) {
            }
        },
        hiddenLoading:({commit})=>{
            try {
                var domloading=document.querySelector(".loading");
                domloading.className ="loading"
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                document.getElementsByTagName("body")[0].className="showcroll"
                console.log("hidden loading ....")
            } catch (error) {
            }
        },
        hiddenDeposite:({commit})=>{
            try {
                var domloading=document.querySelector(".deposite");
                domloading.className ="deposite"
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                document.getElementsByTagName("body")[0].className="showcroll"
                console.log("hidden deposite ....")
            } catch (error) {
            }
        }
    }
})


