<template>
    <div class="py-4 px-4">
        <div class="mx-auto">
            <div class="custom-file" style="width: 35%; margin-left: 30%">
                <div>
                    <input
                        type="file"
                        class="custom-file-input"
                        accept="application/pdf"
                        v-on:change="uploadPdf"
                    />
                    <label class="custom-file-label" for="inputGroupFile01">
                        {{ display_title }}
                        <i class="fa fa-upload" aria-hidden="true"></i>
                    </label>
                    <span class="text-danger" v-if="validation.isPdf">
                        Vaya! solo se aceptan archivos PDF (archivo no actualizado)
                    </span>
                </div>
            </div>
            <div class="container py-4 px-4 w-100">
                <embed v-if="pdfSrc" :src="pdfSrc" type="application/pdf" width="100%" height="700px" />
                <div v-else>
                    <h1>No hay archivo guardado</h1>
                </div>
            </div>
        </div>
        <!-- start error modal -->
        <vue-modal ref="generalError" title="">
            <template v-slot:header></template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <div class="container">
                        <center>
                            <p style="margin-top: 8px; font-size: 20px; font-weight: bold;">
                                Vaya! ha sucedido un error!
                            </p>
                            <p style="margin-top: 8px; font-size: 20px; font-weight: bold;">
                                Si el error persiste, contacte con soporte dronautic@gmail.com
                            </p>
                        </center>
                    </div>
                    <div class="row" style="margin-right: 0px; margin-left: 0px">
                        <div class="col-12" style="padding: 5px">
                            <div class="container">
                                <button
                                    @click="closeErrorModal()"
                                    class="form-control btn btn-danger"
                                    style="margin-top: 8.8%; background-color: #df438b; border-color: #df438b;"
                                >
                                    Entendido!
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </vue-modal>
        <!-- end error modal -->
        <!-- start load modal -->
        <vue-modal ref="modalLoad" title="" :showclosed="false">
            <template v-slot:header></template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <center>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="94"
                            height="94"
                            viewBox="0 0 94 94"
                        >
                            <g id="Group_2373" data-name="Group 2373" transform="translate(-5834.694 -10263.994)">
                                <path
                                    id="Path_4721"
                                    data-name="Path 4721"
                                    d="M18,3V20.6"
                                    transform="translate(5863.694 10263.994)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4722"
                                    data-name="Path 4722"
                                    d="M18,27V44.6"
                                    transform="translate(5863.694 10310.395)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4723"
                                    data-name="Path 4723"
                                    d="M7.4,7.4,19.847,19.847"
                                    transform="translate(5843.191 10272.491)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4724"
                                    data-name="Path 4724"
                                    d="M24.36,24.36,36.812,36.812"
                                    transform="translate(5875.99 10305.29)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4725"
                                    data-name="Path 4725"
                                    d="M3,18H20.6"
                                    transform="translate(5834.694 10292.994)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4726"
                                    data-name="Path 4726"
                                    d="M27,18H44.6"
                                    transform="translate(5881.094 10292.994)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4727"
                                    data-name="Path 4727"
                                    d="M7.4,36.812,19.847,24.36"
                                    transform="translate(5843.191 10305.29)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4728"
                                    data-name="Path 4728"
                                    d="M24.36,19.847,36.812,7.4"
                                    transform="translate(5875.99 10272.491)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                            </g>
                        </svg>

                        <h3>Espere, por favor...</h3>
                    </center>
                </div>
            </template>
        </vue-modal>
        <!-- end load modal -->
    </div>
</template>

<script>
import VueModal from "./VueModal.vue";
export default {
    props: ['term', 'input_title'],
    components: {
        VueModal,
    },
    data() {
        return {
            pdfFile: null,
            pdfSrc: this.term && this.term.src ? this.term.src : null,
            validation: {
                isPdf: false,
            },
            display_title: this.input_title == 'terms' ? 'Subir/actualizar Términos' : 'Subir/actualizar Privacidad'
        };
    },
    methods: {
        closeErrorModal() {
            this.$refs.generalError.close();
        },
        uploadPdf(e) {
            this.pdfFile = e.target.files[0];
            this.validPdfFile(this.pdfFile);
            if (!this.validation.isPdf) {
                this.$refs.modalLoad.open();
                const data = new FormData();
                data.append("pdf", this.pdfFile);
                data.append("type", this.input_title);
                axios
                    .post("/administrador/saveFile", data)
                    .then((res) => {
                        if (res.data.message === "success") {
                            this.pdfSrc = URL.createObjectURL(this.pdfFile);
                        } else {
                            console.error(res.data.errorMessage);
                            this.$refs.generalError.open();
                        }
                        this.$refs.modalLoad.close();
                    })
                    .catch((err) => {
                        console.error(err);
                        this.$refs.generalError.open();
                        this.$refs.modalLoad.close();
                    });
            }
        },
        validPdfFile(pdfFile) {
            if (pdfFile != null) {
                if (!/\.(pdf)$/i.test(pdfFile.name)) {
                    this.validation.isPdf = true;
                } else {
                    this.validation.isPdf = false;
                }
            }
        },
    },
};
</script>

<style scoped></style>
