<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data() {
        return {
            form: {
                typeevent: "",
                otherevent: "",
                numperson: "",
                othertheme: "",
                globos: false,
                flores: false,
                ambos: false,
                date: "",
                initime: "",
                finitime: "",
                name: "",
                email: "",
                money: "",
                phone: "",
                location: "",
                comments: "",
                presu: "",
            },
            saving: false,
            exito: false,
            errors: {
                otherevent: false,
                typeevent: false,
                numperson: false,
                othertheme: false,
                globos: false,
                flores: false,
                ambos: false,
                date: false,
                initime: false,
                finitime: false,
                name: false,
                email: false,
                money: false,
                phone: false,
                location: false,
                comments: false,
                presu: false,
            },
        };
    },
    computed: {
        ...mapGetters({
            eventstypes: "eventstypes",
        }),
    },
    methods: {
        clearError() {
            this.errors = {
                otherevent: false,
                typeevent: false,
                numperson: false,
                othertheme: false,
                globos: false,
                flores: false,
                ambos: false,
                date: false,
                initime: false,
                finitime: false,
                name: false,
                email: false,
                money: false,
                phone: false,
                location: false,
                comments: false,
                presu: false,
            };
        },
        async handlerAddEvent() {
            this.clearError();

            if (this.form.typeevent == "" && this.form.otherevent == "") {
                this.errors.typeevent = true;
                return;
            }
            if (this.form.otherevent == "" && this.form.typeevent == "") {
                this.errors.otherevent = true;
                return;
            }
            if (this.form.numperson == "") {
                this.errors.numperson = true;
                return;
            }
            if (this.form.othertheme == "") {
                this.errors.othertheme = true;
                return;
            }
            if (this.form.name == "") {
                this.errors.name = true;
                return;
            }
            if (this.form.email == "") {
                this.errors.email = true;
                return;
            }
            if (this.form.phone == "") {
                this.errors.phone = true;
                return;
            }
            if (this.form.location == "") {
                this.errors.location = true;
                return;
            }
            if (this.form.date == "") {
                this.errors.date = true;
                return;
            }
            if (this.form.initime == "") {
                this.errors.initime = true;
                return;
            }
            if (this.form.finitime == "") {
                this.errors.finitime = true;
                return;
            }
            if (this.form.presu == "") {
                this.errors.presu = true;
                return;
            }

            this.showLoading();
            var vm = this;
            this.validateEmail(vm.form.email);
            this.validatePhoneNumber(vm.form.phone);
            try {
                //validations added to avoid null values
                vm.form.globos == true
                    ? (vm.form.globos = 1)
                    : (vm.form.globos = 0);
                vm.form.flores == true
                    ? (vm.form.flores = 1)
                    : (vm.form.flores = 0);
                vm.form.ambos == true
                    ? (vm.form.ambos = 1)
                    : (vm.form.ambos = 0);
                //validation added to avoid an error 500 from server
                if (vm.form.comments == "")
                    vm.form.comments = "No se agrego comentario";
                if (vm.form.otherevent == "" && vm.form.typeevent != "")
                    vm.form.otherevent = "no";
                //setting a default event if other is selected
                if (vm.form.typeevent == "" && vm.form.otherevent != "")
                    vm.form.typeevent = 10;
                console.log(vm.form);
                if (!this.errors.phone && !this.errors.email) {
                    let result = await vm.addEvent(vm.form);
                    vm.exito = true;
                    this.saving = true;
                }
                vm.hiddenLoading();
                this.resetForm();
            } catch (error) {
                console.log(error);
                vm.hiddenLoading();
            }
        },
        //? validate right email format
        validateEmail(email) {
            var emailRegEx =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!String(email).match(emailRegEx)) {
                this.errors.email = true;
            } else {
                this.errors.email = !true;
            }
        },
        //? validate phone number format
        validatePhoneNumber(phone) {
            let phoneWithDashRegEx = /^\+?(\d{1,4})\)?[-]?(\d{1,4})[-]?(\d{1,4})$/;
            let phoneNoDashRegEx = /^\+?\d{1,12}$/;

            if (
                !(
                    String(phone).match(phoneWithDashRegEx) ||
                    String(phone).match(phoneNoDashRegEx)
                )
            ) {
                this.errors.phone = true;
            } else {
                this.errors.phone = !true;
            }
        },
        resetForm() {
            this.form = {
                typeevent: "",
                otherevent: "",
                numperson: "",
                othertheme: "",
                globos: false,
                flores: false,
                ambos: false,
                date: "",
                initime: "",
                finitime: "",
                name: "",
                email: "",
                money: "",
                phone: "",
                location: "",
                comments: "",
                presu: "",
            };
        },
        ...mapActions({
            getEventsType: "getEventsType",
            addEvent: "addEvent",
            showLoading: "showLoading",
            hiddenLoading: "hiddenLoading",
        }),
    },
    mounted() {
        this.getEventsType();
    },
};
</script>
<template>
    <div class="row d-flex">
        <div class="col-12 col-md-6">
            <div class="form-events">
                <div>
                    <h6>
                        Cuéntanos más detalles sobre tu evento y nosotros nos
                        pondremos en contacto contigo lo antes posible.
                    </h6>
                </div>
                <div class="row mx-n2">
                    <div class="col-12 col-sm-4 py-2">
                        <select
                            class="form-control"
                            v-model="form.typeevent"
                            :class="errors.typeevent ? 'is-invalid' : ''"
                        >
                            <option value="" disabled>Tipo de Evento</option>
                            <option
                                v-for="(eventtype, index) in eventstypes"
                                :key="`eventtype_${index}`"
                                :value="eventtype.id"
                            >
                                {{ eventtype.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-12 col-sm-4 py-2">
                        <input
                            class="form-control"
                            :class="errors.otherevent ? 'is-invalid' : ''"
                            type="text"
                            placeholder="Otro tipo de evento"
                            v-model="form.otherevent"
                        />
                    </div>
                    <div class="col-12 col-sm-4 py-2">
                        <input
                            class="form-control"
                            type="number"
                            :class="errors.numperson ? 'is-invalid' : ''"
                            placeholder="Cant. invitados"
                            v-model="form.numperson"
                        />
                    </div>

                    <div class="col-12 col-sm-6 py-2">
                        <label class="label-product"
                            >Quiero para mi evento</label
                        >
                        <div class="row">
                            <div class="col-12 col-sm-4">
                                <label class="input-radio">
                                    <input
                                        type="radio"
                                        name="product"
                                        v-model="form.globos"
                                    />
                                    <span class="input">
                                        <span class="icon"></span>
                                    </span>
                                    <span class="ml-2 small">Globos</span>
                                </label>
                            </div>
                            <div class="col-12 col-sm-4">
                                <label class="input-radio">
                                    <input
                                        type="radio"
                                        name="product"
                                        v-model="form.flores"
                                    />
                                    <span class="input">
                                        <span class="icon"></span>
                                    </span>
                                    <span class="ml-2 small">Flores</span>
                                </label>
                            </div>
                            <div class="col-12 col-sm-4">
                                <label class="input-radio">
                                    <input
                                        type="radio"
                                        name="product"
                                        v-model="form.ambos"
                                    />
                                    <span class="input">
                                        <span class="icon"></span>
                                    </span>
                                    <span class="ml-2 small">Ambos</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-6 py-2">
                        <input
                            class="form-control"
                            :class="errors.othertheme ? 'is-invalid' : ''"
                            type="text"
                            placeholder="Otro tipo de temática"
                            v-model="form.othertheme"
                        />
                    </div>

                    <div class="col-12 col-sm-6 py-2">
                        <input
                            class="form-control"
                            :class="errors.name ? 'is-invalid' : ''"
                            type="text"
                            placeholder="Nombre completo"
                            v-model="form.name"
                        />
                    </div>

                    <div class="col-12 col-sm-6 py-2">
                        <input
                            class="form-control"
                            :class="errors.email ? 'is-invalid' : ''"
                            type="email"
                            placeholder="Correo electrónico"
                            v-model="form.email"
                        />
                    </div>

                    <div class="col-12 col-sm-4 py-2">
                        <input
                            class="form-control"
                            :class="errors.phone ? 'is-invalid' : ''"
                            type="text"
                            placeholder="Número de teléfono (fijo o móvil)"
                            v-model="form.phone"
                        />
                    </div>

                    <div class="col-12 col-sm-8 py-2">
                        <input
                            class="form-control"
                            :class="errors.location ? 'is-invalid' : ''"
                            type="text"
                            placeholder="Lugar del evento"
                            v-model="form.location"
                        />
                    </div>
                    <div class="col-12 col-sm-6 py-2">
                        <select
                            class="form-control"
                            v-model="form.presu"
                            :class="errors.presu ? 'is-invalid' : ''"
                        >
                            <option value="" disabled>Presupuesto</option>
                            <option value="$250 - $500">$250 - $500</option>
                            <option value="$500 - $1,000">$500 - $1,000</option>
                            <option value="$1,000 - $2,000">
                                $1,000 - $2,000
                            </option>
                            <option value="+-$2,000">+$2,000</option>
                        </select>
                    </div>
                    <div class="col-12 col-sm-6 py-2">
                        <input
                            class="form-control"
                            :class="errors.date ? 'is-invalid' : ''"
                            placeholder="Fecha de evento"
                            type="text"
                            onfocus="(this.type='date')"
                            v-model="form.date"
                        />
                    </div>

                    <div class="col-12 col-sm-6 py-2">
                        <input
                            class="form-control"
                            :class="errors.initime ? 'is-invalid' : ''"
                            type="text"
                            placeholder="Hora de inicio. Ej. 10:00am"
                            v-model="form.initime"
                        />
                    </div>
                    <div class="col-12 col-sm-6 py-2">
                        <input
                            class="form-control"
                            :class="errors.finitime ? 'is-invalid' : ''"
                            type="text"
                            placeholder="Hora de finalización. Ej. 02:30pm"
                            v-model="form.finitime"
                        />
                    </div>
                    <div class="col-12 col-sm-12 py-2">
                        <textarea
                            class="form-control"
                            :class="errors.comments ? 'is-invalid' : ''"
                            v-model="form.comments"
                            placeholder="Cuéntanos más (opcional)"
                            style="width: 100%; height: 38vh; padding: 20px"
                        ></textarea>
                    </div>
                </div>

                <div class="d-flex">
                    <button
                        @click="handlerAddEvent"
                        class="btn btn-lg btn-primary ml-auto"
                        :disabled="saving">
                        Enviar
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 px-1 mt-2">
            <div class="bg-events" v-if="!exito"></div>
            <div
                v-if="exito"
                style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                "
            >
                <img src="/images/exitoevento.png" alt="" />
            </div>
        </div>
    </div>
</template>
