var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{ref:"modal",staticClass:"modal fade",attrs:{"id":"exampleModal","tabindex":"-1","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-lg modal-dialog-centered"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-body",staticStyle:{"padding":"0.3rem"}},[_c('div',{staticClass:"card mb-3"},[_c('div',{staticStyle:{"height":"95px","width":"100%","background-color":"transparent"}},[_c('div',{staticClass:"float-right",staticStyle:{"height":"25px","width":"25px","background-color":"transparent","padding-right":"5px","position":"relative"}}),_vm._v(" "),_c('img',{staticClass:"image",staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.src}})]),_vm._v(" "),_c('div',{staticClass:"py-4 px-4"},[_c('center',[_c('h3',{staticClass:"card-title pt-3"},[_c('b',[_vm._v(_vm._s(_vm.title == "" ? "Titulo" : _vm.title))])]),_vm._v(" "),_c('h5',[_vm._v("\n                                        "+_vm._s(_vm.subtitle == ""
                                                ? "Sub-título"
                                                : _vm.subtitle)+"\n                                    ")]),_vm._v(" "),_c('p',{staticClass:"card-text"},[_vm._v("\n                                        "+_vm._s(_vm.message == ""
                                                ? "Mensaje"
                                                : _vm.message)+"\n                                    ")]),_vm._v(" "),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[(_vm.name_input)?_c('div',{staticClass:"col-6 mx-auto"},[_c('input',{staticClass:"form-control",attrs:{"placeholder":"Ingresar Nombre","type":"text"}})]):_vm._e(),_vm._v(" "),(_vm.email_input)?_c('div',{staticClass:"col-6 mx-auto"},[_c('input',{staticClass:"form-control",attrs:{"placeholder":"Ingresar Correo","type":"text"}})]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"container py-3 px-2"},[_c('center',[_c('button',{staticClass:"btn btn-success",staticStyle:{"background-color":"#df438b","border-color":"#df438b","width":"50%"},attrs:{"data-dismiss":"modal","aria-label":"Close"}},[_vm._v("\n                                                "+_vm._s(_vm.button_text == ""
                                                        ? "Titulo botón"
                                                        : _vm.button_text)+"\n                                            ")])])],1),_vm._v(" "),_c('p',{staticClass:"card-text"},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.footer == ""
                                                ? "Pie de pagina"
                                                : _vm.footer))])])])],1)])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }