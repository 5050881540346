<script>
export default {
    props: {
        products: {
            type: Array,
            default() {
                return [];
            },
        },
        step: {
            type: String,
            default() {
                return 1;
            },
        },
        type_id: {
            type: String,
        },
    },
    data() {
        return {
            form: {
                product_type: this.type_id,
                product_image: null,
                product_title: "",
                product_details: "",
                product_price: "",
                image_src: "",
            },
            validation: {
                image: false,
                title: false,
                details: false,
                price: false,
                negative: false,
            },
            errorMessages: {
                generalError: "Vaya! este campo es requerido!",
                negativeError: "Vaya! el precio debe ser mayor a 0!",
            },
            product_to_delete: "",
        };
    },
    computed: {
        title() {
            if (this.products.length) {
                return this.products[0].type.name;
            }
            return "";
        },
    },
    methods: {
        formatDate(today, days) {
            var date = new Date(today.valueOf());
            date.setDate(date.getDate() + days);
            var mm = date.getMonth() + 1;
            var dd = date.getDate();
            var yyyy = date.getFullYear();
            if (dd < 10) {
                dd = "0" + dd;
            }
            if (mm < 10) {
                mm = "0" + mm;
            }
            var formatedDate = yyyy + "-" + mm + "-" + dd;
            return formatedDate;
        },
        closeModalImage() {
            this.$refs.imageError.close();
        },
        closeErrorModal() {
            this.$refs.generalError.close();
        },
        emptyValidation() {
            var frm = this.form;
            var vl = this.validation;
            //?input validations
            //* empty name validation
            if (frm.product_title == "" || frm.product_title == undefined) {
                if (!vl.title) vl.title = !vl.title;
            } else {
                if (vl.title) vl.title = !vl.title;
            }
            if (frm.product_details == "" || frm.product_details == undefined) {
                if (!vl.details) vl.details = !vl.details;
            } else {
                if (vl.details) vl.details = !vl.details;
            }
            if (frm.product_price == "" || frm.product_price == undefined) {
                if (!vl.price) vl.price = !vl.price;
            } else {
                if (vl.price) vl.price = !vl.price;
            }
            if (frm.product_image == "" || frm.product_image == null) {
                if (!vl.image) vl.image = !vl.image;
            } else {
                if (vl.image) vl.image = !vl.image;
            }
            if (frm.product_price < 0) {
                if (!vl.negative) vl.negative = !vl.negative;
            } else {
                if (vl.negative) vl.negative = !vl.negative;
            }
        },
        loadPicture(e) {
            this.form.product_image = e.target.files[0];
            this.form.image_src = URL.createObjectURL(this.form.product_image);
        },
        moveToSection(section) {
            switch (section) {
                case 1:
                    window.location = "/administrador/custom/box";
                    break;

                case 2:
                    window.location = "/administrador/custom/flowers";
                    break;

                case 3:
                    window.location = "/administrador/custom/balloons";
                    break;

                case 4:
                    window.location = "/administrador/custom/chocolates";
                    break;
                default:
                    window.location = "/administrador/custom/letters";
            }
        },
        saveProduct(e) {
            var vl = this.validation;
            var frm = this.form;
            e.preventDefault();
            this.emptyValidation();
            if (!vl.title && !vl.image && !vl.details && !vl.price) {
                if (this.validImagenFile(frm.product_image)) {
                    //get current date
                    var today = new Date();
                    //formating dates
                    var availableStart = this.formatDate(today, 0);
                    var availableEnd = this.formatDate(today, 30);

                    this.$refs.modalLoad.open();
                    //?creating form data
                    const data = new FormData();
                    //?attaching form values
                    data.append("name", frm.product_title);
                    data.append("availableStart", availableStart);
                    data.append("avalableEnd", availableEnd);
                    data.append("details", frm.product_details);
                    data.append(
                        "price",
                        parseFloat(frm.product_price.replace(",", "."))
                    );
                    data.append("mainPicture", frm.product_image);
                    data.append("productType", frm.product_type);
                    //?se asigno categoria primaveral por defecto a los productos ingresados desde este
                    //?formulario
                    data.append("productCategories", 6);
                    //*valores vacios para evitar errores al agregar
                    data.append("productFilter", "");
                    //undefined value
                    data.append("extraProduct1", "");
                    data.append("extraProduct2", "");
                    data.append("extraProduct3", "");
                    data.append("extraPicture1", "");
                    data.append("extraPicture2", "");
                    data.append("extraPicture3", "");
                    data.append("extraPicture4", "");
                    data.append("extraPicture5", "");
                    data.append("ribbonTitle", "");
                    axios
                        .post("/administrador/inventario/store", data)
                        .then((res) => {
                            //*error validation
                            if (res.data.message == "error") {
                                console.log(res.data.errorMessage);
                                this.$refs.generalError.open();
                                this.$refs.modalLoad.close();
                            } else {
                                location.reload(true);
                                this.$refs.modalLoad.close();
                            }
                        })
                        .catch(function (err) {
                            console.log(err);
                        });
                } else {
                    this.$refs.imageError.open();
                }
            }
        },
        showAlertEliminar(id) {
            this.product_to_delete = id;
            this.$refs.modalEliminar.open();
        },
        validImagenFile(imageFile) {
            if (imageFile != null) {
                if (!/\.(jpg|png|gif)$/i.test(imageFile.name)) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        },
        async elminarProducto() {
            var vm = this;
            try {
                var producto = {
                    id: this.product_to_delete,
                };
                console.log("eliminar");
                vm.$refs.modalEliminar.close();
                vm.$refs.modalLoad.open();
                let { data } = await window.axios.post(
                    `/administrador/inventario/delete`,
                    producto
                );
                vm.$refs.modalLoad.close();
                location.reload(true);
            } catch (error) {
                vm.$refs.modalLoad.close();
                console.log(error);
            }
        },
        hideAlertEliminar() {
            this.$refs.modalEliminar.close();
        },
    },
    mounted() {},
};
</script>
<template>
    <div class="d-flex my-3">
        <div class="bg-transparent">
            <div class="card-body pt-4" style="max-width: 90%; width: 90%">
                <small
                    ><p class="font-weight-bold">Título de la sección</p></small
                >
                <select
                    name="product_type"
                    id="product_type"
                    v-model="form.product_type"
                    class="custom-select card-title"
                >
                    <option value="3">Paso 1</option>
                    <option value="4">Paso 2</option>
                    <option value="5">Paso 3</option>
                    <option value="6">Paso 4</option>
                    <option value="7">Paso 5</option>
                </select>
                <div class="card-text card p-3">
                    <center>
                        <p class="font-weight-bold">NUEVO PRODUCTO</p>
                    </center>
                    <!-- start image input -->
                    <center>
                        <center>
                            <small>
                                <label class="m-0"
                                    ><b>Previsualización</b></label
                                >
                            </small>
                        </center>
                        <div class="m-3 card" style="width: 80%; height: 150px">
                            <div v-if="form.image_src != ''">
                                <img
                                    class="image_preview card"
                                    :src="form.image_src"
                                    alt=""
                                />
                            </div>
                        </div>
                    </center>
                    <div class="input-group mb-3">
                        <div class="custom-file">
                            <input
                                type="file"
                                class="custom-file-input"
                                accept="image/*"
                                v-on:change="loadPicture"
                            />
                            <label
                                class="custom-file-label"
                                for="inputGroupFile01"
                                >Seleccionar</label
                            >
                        </div>
                        &nbsp;
                        <div>
                            <button
                                v-if="typeof form.carrousel_image == 'string'"
                                type="button"
                                class="btn btn-primary"
                            >
                                <i class="fas fa-eye"></i>
                            </button>
                        </div>

                        <small
                            ><span
                                >Cuadrada, mínima 250x250px JPNG o
                                PNG(recomendado)</span
                            ></small
                        >
                        <span class="text-danger" v-if="validation.image">{{
                            errorMessages.generalError
                        }}</span>
                    </div>
                    <!-- end image input -->
                    <!-- start titles inputs -->
                    <div class="form-group">
                        <center>
                            <small>
                                <label for="" class="mt-2"><b>Título</b></label>
                            </small>
                        </center>
                        <input
                            class="form-control"
                            type="text"
                            maxlength="70"
                            v-model="form.product_title"
                        />
                        <span class="text-danger" v-if="validation.title">{{
                            errorMessages.generalError
                        }}</span>
                    </div>
                    <!-- end titles inputs -->
                    <!-- start message input -->
                    <div class="form-group">
                        <center>
                            <small>
                                <label for="" class="mt-2"
                                    ><b>Descripción</b></label
                                >
                            </small>
                        </center>
                        <textarea
                            class="form-control"
                            type="text"
                            maxlength="255"
                            v-model="form.product_details"
                        ></textarea>
                        <span class="text-danger" v-if="validation.details">{{
                            errorMessages.generalError
                        }}</span>
                    </div>
                    <!-- end message input -->

                    <!-- start price inputs -->
                    <div class="form-group">
                        <center>
                            <small>
                                <label for="" class="mt-2"><b>Precio</b></label>
                            </small>
                        </center>
                        <input
                            class="form-control"
                            type="number"
                            step="0.01"
                            min="0"
                            v-model="form.product_price"
                        />
                        <span class="text-danger" v-if="validation.price">{{
                            errorMessages.generalError
                        }}</span>
                        <span class="text-danger" v-if="validation.negative">{{
                            errorMessages.negativeError
                        }}</span>
                    </div>
                    <!-- end price inputs -->
                    <center>
                        <button
                            class="btn btn-success py-2 my-2"
                            style="
                                background-color: #df438b;
                                border-color: #df438b;
                                width: 50%;
                            "
                            @click="saveProduct"
                        >
                            Guardar
                        </button>
                    </center>
                </div>
            </div>
        </div>
        <div class="row d-flex py-2 px-4" style="width: 145%">
            <div class="col-12 products_list">
                <div>
                    <div class="row flex" style="margin-left: 7%; width: 110%">
                        <!--Sección {{ step }} de {{ totalSteps }}-->
                        <div
                            v-for="s in 5"
                            :key="s"
                            class="col-2"
                            style="padding: 0; margin: 0"
                        >
                            <div>
                                <a @click="moveToSection(s)" style="cursor: pointer;">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="227.964"
                                        height="108.022"
                                        viewBox="0 0 350 108.022"
                                    >
                                        <g
                                            id="Component_73_1"
                                            data-name="Component 73 – 1"
                                            transform="translate(0.5 0.5)"
                                        >
                                            <g
                                                id="Group_2396"
                                                data-name="Group 2396"
                                                transform="translate(0 0)"
                                            >
                                                <g
                                                    id="Group_10"
                                                    data-name="Group 10"
                                                    transform="translate(0 0)"
                                                >
                                                    <path
                                                        id="Union_17"
                                                        data-name="Union 17"
                                                        d="M47.986,107.022l2.664-4.614A21.213,21.213,0,0,1,33.8,93.3,21.229,21.229,0,0,1,15.027,57.4h0a21.133,21.133,0,0,0-5.89,11.287,21.226,21.226,0,0,1,.009-34.9A21.231,21.231,0,0,1,33.8,9.117a21.229,21.229,0,0,1,34.87,0,21.23,21.23,0,0,1,24.647,24.7,21.227,21.227,0,0,1,.012,34.833A21.237,21.237,0,0,1,68.672,93.3h0a21.207,21.207,0,0,1-16.793,9.1l2.665,4.618ZM33.8,93.3s0,0,0,0A21.11,21.11,0,0,1,30.011,81.52,21.119,21.119,0,0,0,33.8,93.3Zm23.623-5.881a21.4,21.4,0,0,0,2.455,2.109A21.269,21.269,0,0,1,51.236,73.51,21.151,21.151,0,0,0,57.425,87.417Zm35.9-18.767a21.155,21.155,0,0,0-2.764-7.294A21.179,21.179,0,0,1,93.328,68.651ZM15.051,57.372a21.152,21.152,0,0,1,13.89-6.163h0A21.149,21.149,0,0,0,15.051,57.372Zm16.085-6.163h0q-.547.027-1.1.027Q30.586,51.236,31.136,51.208Zm20.087.015.014-.013h0Zm.012-.015L65.449,36.995a20.743,20.743,0,0,0,1.536-1.533,21.152,21.152,0,0,1,13.448-5.439A21.153,21.153,0,0,0,66.985,35.46,21.262,21.262,0,0,0,70.528,30.1a21.266,21.266,0,0,1-3.543,5.364q-.731.806-1.536,1.534L51.234,51.207Zm0,0L37.022,36.995c-.127-.114-.252-.23-.376-.349q-.4-.421-.824-.824a21.23,21.23,0,0,1-2.55-25.906,21.228,21.228,0,0,0,2.538,25.9l.012.01c.268.283.541.559.823.826.125.117.25.234.377.347ZM9.147,33.784A21.1,21.1,0,0,1,20.11,30.039,21.093,21.093,0,0,0,9.147,33.784ZM51.234,31.1c-.018-.365-.027-.727-.027-1.091Q51.207,30.558,51.234,31.1Zm0-2.2v.011a21.573,21.573,0,0,1,.421-3.259A21.06,21.06,0,0,0,51.234,28.907ZM41,11.829l-.047-.028Zm-.086-.052a21.151,21.151,0,0,0-3.015-1.49A21.151,21.151,0,0,1,40.916,11.777Zm-3.046-1.5-.01,0Z"
                                                        :class="{
                                                            current: s <= step,
                                                        }"
                                                        fill="#707070"
                                                        stroke="rgba(0,0,0,0)"
                                                        stroke-miterlimit="10"
                                                        stroke-width="1"
                                                    />
                                                    <path
                                                        id="Path_34"
                                                        data-name="Path 34"
                                                        d="M3733.738,4506.72Z"
                                                        transform="translate(-3682.502 -4455.511)"
                                                        fill="#707070"
                                                        style="
                                                            mix-blend-mode: color-burn;
                                                            isolation: isolate;
                                                        "
                                                    />
                                                    <path
                                                        id="Path_36"
                                                        data-name="Path 36"
                                                        d="M3733.789,4526.873v-20.043h0Z"
                                                        transform="translate(-3682.553 -4455.564)"
                                                        fill="#707070"
                                                        style="
                                                            mix-blend-mode: luminosity;
                                                            isolation: isolate;
                                                        "
                                                    />
                                                    <path
                                                        id="Path_37"
                                                        data-name="Path 37"
                                                        d="M3733.789,4506.721Z"
                                                        transform="translate(-3682.553 -4455.512)"
                                                        fill="#707070"
                                                        style="
                                                            mix-blend-mode: luminosity;
                                                            isolation: isolate;
                                                        "
                                                    />
                                                    <path
                                                        id="Path_39"
                                                        data-name="Path 39"
                                                        d="M3733.764,4506.721h0l-.028.028h0Z"
                                                        transform="translate(-3682.527 -4455.512)"
                                                        fill="#707070"
                                                        style="
                                                            mix-blend-mode: luminosity;
                                                            isolation: isolate;
                                                        "
                                                    />
                                                    <rect
                                                        id="Rectangle_17"
                                                        data-name="Rectangle 17"
                                                        transform="translate(51.236 51.208)"
                                                        fill="#707070"
                                                        style="
                                                            mix-blend-mode: luminosity;
                                                            isolation: isolate;
                                                        "
                                                    />
                                                    <path
                                                        id="Path_41"
                                                        data-name="Path 41"
                                                        d="M3733.789,4488.292h0v0Z"
                                                        transform="translate(-3682.553 -4437.084)"
                                                        fill="#707070"
                                                        style="
                                                            mix-blend-mode: luminosity;
                                                            isolation: isolate;
                                                        "
                                                    />
                                                    <rect
                                                        id="Rectangle_18"
                                                        data-name="Rectangle 18"
                                                        transform="translate(51.236 51.208)"
                                                        fill="#707070"
                                                        style="
                                                            mix-blend-mode: luminosity;
                                                            isolation: isolate;
                                                        "
                                                    />
                                                    <rect
                                                        id="Rectangle_19"
                                                        data-name="Rectangle 19"
                                                        transform="translate(51.236 51.208) rotate(-45)"
                                                        fill="#707070"
                                                        style="
                                                            mix-blend-mode: luminosity;
                                                            isolation: isolate;
                                                        "
                                                    />
                                                    <path
                                                        id="Path_43"
                                                        data-name="Path 43"
                                                        d="M3733.789,4506.72Z"
                                                        transform="translate(-3682.553 -4455.511)"
                                                        fill="#707070"
                                                        style="
                                                            mix-blend-mode: luminosity;
                                                            isolation: isolate;
                                                        "
                                                    />
                                                    <path
                                                        id="Path_44"
                                                        data-name="Path 44"
                                                        d="M3733.789,4506.72Z"
                                                        transform="translate(-3682.553 -4455.511)"
                                                        fill="#707070"
                                                        style="
                                                            mix-blend-mode: luminosity;
                                                            isolation: isolate;
                                                        "
                                                    />
                                                    <path
                                                        id="Path_45"
                                                        data-name="Path 45"
                                                        d="M3733.789,4506.72Z"
                                                        transform="translate(-3682.553 -4455.511)"
                                                        fill="#707070"
                                                        style="
                                                            mix-blend-mode: luminosity;
                                                            isolation: isolate;
                                                        "
                                                    />
                                                    <rect
                                                        id="Rectangle_20"
                                                        data-name="Rectangle 20"
                                                        transform="translate(51.236 51.208)"
                                                        fill="#707070"
                                                        style="
                                                            mix-blend-mode: luminosity;
                                                            isolation: isolate;
                                                        "
                                                    />
                                                    <path
                                                        id="Path_46"
                                                        data-name="Path 46"
                                                        d="M3733.79,4506.72Z"
                                                        transform="translate(-3682.554 -4455.511)"
                                                        fill="#707070"
                                                        style="
                                                            mix-blend-mode: luminosity;
                                                            isolation: isolate;
                                                        "
                                                    />
                                                    <path
                                                        id="Path_50"
                                                        data-name="Path 50"
                                                        d="M3733.789,4506.721h0v.056h0v-.057Z"
                                                        transform="translate(-3682.553 -4455.512)"
                                                        fill="#707070"
                                                        style="
                                                            mix-blend-mode: hue;
                                                            isolation: isolate;
                                                        "
                                                    />
                                                    <rect
                                                        id="Rectangle_21"
                                                        data-name="Rectangle 21"
                                                        transform="translate(51.236 51.208)"
                                                        fill="#707070"
                                                        style="
                                                            mix-blend-mode: hue;
                                                            isolation: isolate;
                                                        "
                                                    />
                                                    <path
                                                        id="Path_54"
                                                        data-name="Path 54"
                                                        d="M3733.79,4506.72h0Z"
                                                        transform="translate(-3682.554 -4455.511)"
                                                        fill="#707070"
                                                        style="
                                                            mix-blend-mode: hue;
                                                            isolation: isolate;
                                                        "
                                                    />
                                                    <path
                                                        id="Path_55"
                                                        data-name="Path 55"
                                                        d="M3733.79,4506.72Z"
                                                        transform="translate(-3682.554 -4455.511)"
                                                        fill="#707070"
                                                        style="
                                                            mix-blend-mode: hue;
                                                            isolation: isolate;
                                                        "
                                                    />
                                                    <rect
                                                        id="Rectangle_22"
                                                        data-name="Rectangle 22"
                                                        transform="translate(51.236 51.208)"
                                                        fill="#707070"
                                                        style="
                                                            mix-blend-mode: hue;
                                                            isolation: isolate;
                                                        "
                                                    />
                                                    <path
                                                        id="Path_59"
                                                        data-name="Path 59"
                                                        d="M3704.249,4477.232q.4.422.824.824Q3704.671,4477.634,3704.249,4477.232Z"
                                                        transform="translate(-3668.426 -4441.411)"
                                                        fill="#707070"
                                                        style="
                                                            mix-blend-mode: hue;
                                                            isolation: isolate;
                                                        "
                                                    />
                                                    <path
                                                        id="Path_60"
                                                        data-name="Path 60"
                                                        d="M3733.736,4506.774h0Z"
                                                        transform="translate(-3682.527 -4455.538)"
                                                        fill="#707070"
                                                        style="
                                                            mix-blend-mode: hue;
                                                            isolation: isolate;
                                                        "
                                                    />
                                                </g>
                                            </g>
                                            <text
                                                id="_1"
                                                data-name="1"
                                                transform="translate(41.516 66.105)"
                                                fill="#fff"
                                                font-size="42"
                                                font-family="SegoeUI-Bold, Segoe UI"
                                                font-weight="700"
                                            >
                                                <tspan x="0" y="0">
                                                    {{ s }}
                                                </tspan>
                                            </text>
                                            <line
                                                v-if="s < 5"
                                                id="Line_21"
                                                data-name="Line 21"
                                                x2="135.366"
                                                transform="translate(92.098 53.547)"
                                                fill="none"
                                                :stroke="
                                                    s <= step
                                                        ? '#df438b'
                                                        : '#707070'
                                                "
                                                stroke-width="10"
                                            />
                                        </g>
                                    </svg>
                                </a>
                            </div>
                            <div>
                                <h5>
                                    <b
                                        ><a
                                            @click="moveToSection(s)"
                                            :class="
                                                s <= step
                                                    ? 'currentSeccion'
                                                    : 'otherSections'
                                            "
                                        >
                                            Sección {{ s }}
                                        </a></b
                                    >
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content card px-4 py-4">
                    <!-- Cliente soliccita quitar encabezado dinamicco.
                    <h4 class="text-primary m-0 font-weight-bold">
                        Paso {{ step }}
                    </h4>-->
                    <div>
                        <!-- <pre>{{products[0]}}</pre> -->
                    </div>
                    <div class="d-flex flex-wrap" style="min-width: 100%">
                        <div
                            v-for="(product, index) in products"
                            :key="`product_${index}_${product.id}`"
                            class="col-12 col-md-8 col-lg-5 col-xl-3 p-4"
                        >
                            <div class="product_image">
                                <img
                                    :src="product.thumbnail"
                                    :alt="product.name"
                                />
                            </div>

                            <div class="py-2">
                                <div class="product-content">
                                    <h6 class="font-weight-bold">
                                        {{ product.name }}
                                    </h6>
                                    <p
                                        class="m-0 text-muted"
                                        v-html="product.description"
                                    ></p>
                                    <p class="font-weight-bold text-primary">
                                        ${{ product.price_float }}
                                    </p>
                                </div>
                                <div class="row">
                                    <a
                                        :href="
                                            '/administrador/inventario/' +
                                            product.id
                                        "
                                        class="btn btn-ligth font-weight-bold border-primary text-primary btn-block col"
                                    >
                                        Editar
                                    </a>
                                    <button
                                        class="btn btn-ligth font-weight-bold btn-block border col"
                                        @click="showAlertEliminar(product.id)"
                                    >
                                        Eliminar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="custom__nav">
                        <slot name="nav"></slot>
                    </div>
                </div>
            </div>
        </div>
        <!-- start image error modal -->

        <vue-modal ref="imageError" title="">
            <template v-slot:header> </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <div class="container">
                        <center>
                            <p
                                style="
                                    margin-top: 8px;
                                    font-size: 20px;
                                    font-weight: bold;
                                "
                            >
                                Vaya! formato de imagen no soportado!
                            </p>
                        </center>
                    </div>
                    <div
                        class="row"
                        style="margin-right: 0px; margin-left: 0px"
                    >
                        <div class="col-12" style="padding: 5px">
                            <div class="container">
                                <button
                                    @click="closeModalImage()"
                                    class="form-control btn btn-danger"
                                    style="
                                        margin-top: 8.8%;
                                        background-color: #df438b;
                                        border-color: #df438b;
                                    "
                                >
                                    Entendido!
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </vue-modal>
        <!-- end image error modal -->
        <!-- start general error modal  -->

        <vue-modal ref="generalError" title="">
            <template v-slot:header> </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <div class="container">
                        <center>
                            <p
                                style="
                                    margin-top: 8px;
                                    font-size: 20px;
                                    font-weight: bold;
                                "
                            >
                                 Vaya! ha sucedido un error!
                            </p>
                            <p
                                style="
                                    margin-top: 8px;
                                    font-size: 20px;
                                    font-weight: bold;
                                "
                            >
                                Si el error persiste, contacte con soporte dronautic@gmail.com
                            </p>
                        </center>
                    </div>
                    <div
                        class="row"
                        style="margin-right: 0px; margin-left: 0px"
                    >
                        <div class="col-12" style="padding: 5px">
                            <div class="container">
                                <button
                                    @click="closeErrorModal()"
                                    class="form-control btn btn-danger"
                                    style="
                                        margin-top: 8.8%;
                                        background-color: #df438b;
                                        border-color: #df438b;
                                    "
                                >
                                    Entendido!
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </vue-modal>
        <!-- end general error modal -->
        <!-- stary load modal -->

        <vue-modal ref="modalLoad" title="" :showclosed="false">
            <template v-slot:header> </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <center>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="94"
                            height="94"
                            viewBox="0 0 94 94"
                        >
                            <g
                                id="Group_2373"
                                data-name="Group 2373"
                                transform="translate(-5834.694 -10263.994)"
                            >
                                <path
                                    id="Path_4721"
                                    data-name="Path 4721"
                                    d="M18,3V20.6"
                                    transform="translate(5863.694 10263.994)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4722"
                                    data-name="Path 4722"
                                    d="M18,27V44.6"
                                    transform="translate(5863.694 10310.395)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4723"
                                    data-name="Path 4723"
                                    d="M7.4,7.4,19.847,19.847"
                                    transform="translate(5843.191 10272.491)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4724"
                                    data-name="Path 4724"
                                    d="M24.36,24.36,36.812,36.812"
                                    transform="translate(5875.99 10305.29)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4725"
                                    data-name="Path 4725"
                                    d="M3,18H20.6"
                                    transform="translate(5834.694 10292.994)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4726"
                                    data-name="Path 4726"
                                    d="M27,18H44.6"
                                    transform="translate(5881.094 10292.994)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4727"
                                    data-name="Path 4727"
                                    d="M7.4,36.812,19.847,24.36"
                                    transform="translate(5843.191 10305.29)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4728"
                                    data-name="Path 4728"
                                    d="M24.36,19.847,36.812,7.4"
                                    transform="translate(5875.99 10272.491)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                            </g>
                        </svg>

                        <h3>Espere, por favor...</h3>
                    </center>
                </div>
            </template>
        </vue-modal>
        <!-- end load modal -->
        <!-- start delete modal -->
        <vue-modal ref="modalEliminar" title="">
            <template v-slot:header> </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <center>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="81.945"
                            height="90.384"
                            viewBox="0 0 81.945 90.384"
                        >
                            <g
                                id="Icon_feather-trash-2"
                                data-name="Icon feather-trash-2"
                                transform="translate(3 3)"
                            >
                                <path
                                    id="Path_4732"
                                    data-name="Path 4732"
                                    d="M4.5,9H80.445"
                                    transform="translate(-4.5 7.877)"
                                    fill="none"
                                    stroke="#d10043"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4733"
                                    data-name="Path 4733"
                                    d="M66.569,19.877V78.945a8.438,8.438,0,0,1-8.438,8.438H15.938A8.438,8.438,0,0,1,7.5,78.945V19.877m12.658,0V11.438A8.438,8.438,0,0,1,28.6,3H45.473a8.438,8.438,0,0,1,8.438,8.438v8.438"
                                    transform="translate(0.938 -3)"
                                    fill="none"
                                    stroke="#d10043"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4734"
                                    data-name="Path 4734"
                                    d="M15,16.5V41.815"
                                    transform="translate(14.534 21.473)"
                                    fill="none"
                                    stroke="#d10043"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4735"
                                    data-name="Path 4735"
                                    d="M21,16.5V41.815"
                                    transform="translate(25.411 21.473)"
                                    fill="none"
                                    stroke="#d10043"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                            </g>
                        </svg>
                        <br />
                        <p
                            style="
                                margin-top: 8px;
                                font-size: 20px;
                                font-weight: bold;
                            "
                        >
                            ¿Está seguro de eliminar <br />este Producto?
                        </p>
                    </center>
                    <div
                        class="row"
                        style="margin-right: 0px; margin-left: 0px"
                    >
                        <div class="col-6" style="padding: 0px">
                            <svg
                                @click="hideAlertEliminar()"
                                style="cursor: pointer"
                                id="Group_2419"
                                data-name="Group 2419"
                                xmlns="http://www.w3.org/2000/svg"
                                width="150"
                                height="60"
                                viewBox="0 0 150 60"
                            >
                                <g
                                    id="Rectangle_2020"
                                    data-name="Rectangle 2020"
                                    fill="#fff"
                                    stroke="#df438b"
                                    stroke-width="1"
                                >
                                    <path
                                        d="M0,0H150a0,0,0,0,1,0,0V60a0,0,0,0,1,0,0H10A10,10,0,0,1,0,50V0A0,0,0,0,1,0,0Z"
                                        stroke="none"
                                    />
                                    <path
                                        d="M1,.5H149a.5.5,0,0,1,.5.5V59a.5.5,0,0,1-.5.5H10A9.5,9.5,0,0,1,.5,50V1A.5.5,0,0,1,1,.5Z"
                                        fill="none"
                                    />
                                </g>
                                <text
                                    id="Cancelar"
                                    transform="translate(75 35)"
                                    fill="#df438b"
                                    font-size="19"
                                    font-family="SegoeUI-Bold, Segoe UI"
                                    font-weight="700"
                                >
                                    <tspan x="-38.079" y="0">Cancelar</tspan>
                                </text>
                            </svg>
                        </div>
                        <div class="col-6" style="padding: 0px">
                            <svg
                                @click="elminarProducto()"
                                style="cursor: pointer"
                                id="Group_2364"
                                data-name="Group 2364"
                                xmlns="http://www.w3.org/2000/svg"
                                width="150"
                                height="60"
                                viewBox="0 0 150 60"
                            >
                                <path
                                    id="Rectangle_2020"
                                    data-name="Rectangle 2020"
                                    d="M0,0H150a0,0,0,0,1,0,0V50a10,10,0,0,1-10,10H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                                    fill="#df438b"
                                />
                                <text
                                    id="Si_eliminar"
                                    data-name="Si, eliminar"
                                    transform="translate(73 35)"
                                    fill="#fff"
                                    font-size="18"
                                    font-family="SegoeUI-Bold, Segoe UI"
                                    font-weight="700"
                                >
                                    <tspan x="-47.18" y="0">Si, eliminar</tspan>
                                </text>
                            </svg>
                        </div>
                    </div>
                </div>
            </template>
        </vue-modal>
        <!-- end delete modal -->
    </div>
</template>
<style lang="scss" scoped>
.product__added {
    position: absolute;
    z-index: 2;
    bottom: 0.5rem;
    right: 0.5rem;
    background-color: #d10043;
    color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    text-align: center;
    line-height: 25px;
    span {
        line-height: 25px;
    }
}
.custom__nav {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
}
.products_list {
    position: relative;
    padding-bottom: 55px;
}
.product_image {
    width: 100%;
    padding-bottom: 100%;
    height: 0;
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;
    img {
        min-width: 100%;
        position: absolute;
        min-height: 100%;
        object-fit: cover;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }
}
.product-content {
    min-height: 130px;
}
.currentSeccion {
    color: #df438b;
}
.otherSections {
    color: grey;
}
.currentSeccion:hover {
    text-decoration: none;
    cursor: pointer;
}
.otherSections:hover {
    text-decoration: none;
    cursor: pointer;
}
.image_preview {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.current {
    fill: #df438b;
}
</style>
