import { renderBestProducts } from './home'


const routes = {
    bestProducts: '/productos/mejores'
}

const pagesMethods = {
    home: {
        renderBestProducts
    }
}

$(window).on('load', function(){
    $('body').on('click', '[data-ajax]', async function (event) {
        const route = $(this).data('ajax')
        const rData = $(this).data('ajax-data')
        const target = $(this).data('ajax-target')
        const loader = $(this).data('ajax-loader')
        const method = $(this).data('ajax-method') || 'get'
        const cb = $(this).data('ajax-cb').split('.')
        const page = cb[0]
        const callback = cb[1]
        $(loader).addClass('active')
        $(this).hide()
        let data = null
        try {
            if(method == 'get') {
                let response = await window.axios.get(routes[route], {
                    params: rData
                })
                data = response.data
            }
        } catch (error) {
            debugger
        }
        pagesMethods[page][callback]({data, target})

        if(rData.hasOwnProperty('page')) {
            if(data.last_page != rData.page) {
                rData.page = data.current_page + 1
                $(this).show()
            }
        } else {
            $(this).show()
        }

        $(this).data('ajax-data', rData)

        $(loader).removeClass('active')
    })
})
