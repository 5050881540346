<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            cart: 'cart'
        })
    },
    methods: {
        ...mapActions({
            getCart: 'getCart'
        })
    },
    mounted() {
        this.getCart()
    }
}
</script>

<template>
    <div>
        <a :href="cart.cart.length==0 ? '#' : '/payment'" class="btn btn-transparent only-hover py-0" :class="cart.qty ? 'text-primary' : 'text-default' ">
            <span class="icon-bag"></span>
            <span v-if="!cart.qty" class="only-hover__text">
                Sin Items
            </span>
            <span class="header-cart__count" v-else>{{cart.qty}}</span>
        </a>
    </div>
</template>
<style lang="scss" scoped>
.header{
    &-cart{
        &__count{
            position: absolute;
            background-color: #D10043;
            color: #fff;
            width: 1rem;
            height: 1rem;
            display: inline-block;
            font-size: 0.7rem;
            text-align: center;
            line-height: 1.1rem;
            font-weight: 700;
            border-radius: 50%;
            top: 20px;
            right: 5px;
        }
    }
}
</style>
