export const card = (product) => {
    let template = `
            <article class="product">
                <div class="product__top">
                    <img class="product__img"
                        src="${product.thumbnail}"
                        alt="${product.name}">
                    <div class="product__price-from">
                        <div class="product__price">
                            <p class="from">Desde</p>
                            <p class="m-0 price"><span class="small">$</span>${product.price}</p>
                        </div>
                    </div>`;
    if (product.ribbon != null && product.ribbon != "" && product.ribbon != "null") {
        template += `<div class="product__ribbon">
                    ${product.ribbon}
                </div>`;
    }

    template += `</div> <div class="product__body">
                    <h2 class="product__title">${product.name}</h2>
                </div>
                <div class="product__footer px-5">
                    <a href="/productos/detalle/${product.id}" class="btn btn-dark btn-block">
                        VER DETALLES
                    </a>
                </div>
            </article>
    `;
    return template;
};
