<script>
import { mapActions, mapGetters } from "vuex";
import VueModal from "./VueModal.vue";
//select2 import
import Select2 from "v-select2-component";
import loaderIcon from "../../svg/loader-icon.svg";
export default {
    components: {
        VueModal,
        //select2 modal
        Select2,
    },
    props: [
        "cats",
        "filters",
        "products",
        "product_types",
        "product",
        "screen_title",
        "product_categories",
        "product_filters",
        "product_extras",
        "product_photos",
    ],
    data() {
        let pr = this.product;
        return {
            loaderIcon,
            form: {
                liston: false,
                //?input variables
                name: pr.name,
                availableStart: pr.start_date == undefined ? pr.start_date : pr.start_date.substring(0, 10),
                availableEnd: pr.end_date == undefined ? pr.end_date : pr.end_date.substring(0, 10),
                details: pr.description == undefined ? "" : pr.description,
                ribbonTitle: pr.ribbon,
                price: pr.price_float,
                productCategories: this.product_categories,
                productFilter: this.product_filters,
                productType: pr.type_id,
                extraProduct1: this.product_extras[0],
                extraProduct2: this.product_extras[1],
                extraProduct3: this.product_extras[2],
                mainPicture: pr.thumbnail,
                extraPicture1: this.product_photos[0],
                extraPicture2: this.product_photos[1],
                extraPicture3: this.product_photos[2],
                extraPicture4: this.product_photos[3],
                extraPicture5: this.product_photos[4],
            },
            //*variable para carga de opciones de los combobox
            productOptions: [],
            //*placeholder var
            placeholder: "Seleccionar producto",
            //*var to save the product id and value,
            productTypesSelectValues: [],
            //*ordering categories items clases,
            catsid: [],
            unicCatsId: [],
            categorias: [],
            //?error Messages
            errorMessages: {
                generalError: "Vaya! este campo es requerido!",
                categoriesError:
                    "Vaya! Se requiere seleccionar al menos una opción",
                negativeError: "Vaya! el precio debe ser mayor a 0!",
                dateRangeError:
                    "Vaya! la fecha inicio debe ser menor que la fecha final!",
            },
            //?validation flags
            validation: {
                validName: false,
                validStartDate: false,
                validEndDate: false,
                validDetails: false,
                validRibbon: false,
                validPrice: false,
                validCategories: false,
                validMainPic: false,
                validPositivePrice: false,
                validDateRange: false,
                validProductType: false,
            }, //?setting image uri to show it
            urlSrc: "",
            modal: 0,
        };
    },
    methods: {
        activeListon() {
            if (this.form.liston) {
                this.form.ribbonTitle = "";
            }
            this.form.liston = !this.form.liston;
        },
        back() {
            window.history.back();
        },
        //?close modal
        closeModalImage() {
            this.$refs.imageError.close();
        },
        closeErrorModal() {
            this.$refs.generalError.close();
        },
        //?delete product photo
        deleteProductPhoto(url, imageRef) {
            this.$refs.modalLoad.open();
            if (this.screen_title !== "add" && url.toString().includes("/")) {
                axios
                    .post("/administrador/inventario/picture" + url)
                    .then((res) => {
                        if (res.data.message === "eliminado") {
                            this.$refs.modalLoad.close();
                        } else {
                            this.$refs.modalLoad.close();
                            this.$refs.generalError.open();
                            console.log(res.data.message);
                        }
                    })
                    .catch(function (err) {
                        console.log(err);
                    });
            } else {
                this.$refs.modalLoad.close();
            }

            const imageRefMapping = {
                0: { field: "mainPicture" },
                1: { field: "extraPicture1", ref: "extraPic1InputFile" },
                2: { field: "extraPicture2", ref: "extraPic2InputFile" },
                3: { field: "extraPicture3", ref: "extraPic3InputFile" },
                4: { field: "extraPicture4", ref: "extraPic4InputFile" },
                5: { field: "extraPicture5", ref: "extraPic5InputFile" },
            };

            const mapping = imageRefMapping[imageRef];
            if (mapping) {
                this.form[mapping.field] = mapping.ref ? null : "";
                if (mapping.ref) {
                    this.$refs[mapping.ref].value = null;
                }
            }
        },
        //*empty validation function
        emptyValidation() {
            this.validation.validName = this.isEmpty(this.form.name);
            this.validation.validStartDate = this.isEmpty(this.form.availableStart);
            this.validation.validProductType = this.isEmpty(this.form.productType);
            this.validation.validEndDate = this.isEmpty(this.form.availableEnd);
            this.validation.validRibbon = this.form.liston
                ? this.isEmpty(this.form.ribbonTitle)
                : false;
            this.validation.validDetails = this.isEmpty(this.form.details);
            this.validation.validPrice = this.isEmpty(this.form.price) && this.isPositive(this.form.price);
            this.validation.validCategories =
                this.isEmpty(this.form.productCategories) &&
                this.isEmpty(this.form.productFilter);
            this.validation.validMainPic = this.isEmpty(this.form.mainPicture);
            this.validation.validPositivePrice = !this.isPositive(this.form.price);
            this.validation.validDateRange = !this.isValidDateRange(
                this.form.availableStart,
                this.form.availableEnd
            );
        },
        // Helper function to check if a value is empty
        isEmpty(value) {
            return value === "" || value === undefined;
        },
        // Helper function to check if a value is positive
        isPositive(value) {
            return value > +0 && value !== "0.00";
        },
        // Helper function to check if the date range is valid
        isValidDateRange(startDate, endDate) {
            const start = new Date(startDate);
            const end = new Date(endDate);
            console.log('startDate: '+ startDate + ' endDate:'+ endDate, ' isValid: '+ startDate <= endDate)
            return start <= end;
        },
        //*extra picture 1 load
        extraPic1(e) {
            if (this.validImagenFile(e.target.files[0])) {
                this.form.extraPicture1 = e.target.files[0];
            } else {
                this.$refs.imageError.open();
            }
            console.log(this.form.extraPicture1);
        },
        //**extra picture 2 load
        extraPic2(e) {
            if (this.validImagenFile(e.target.files[0])) {
                this.form.extraPicture2 = e.target.files[0];
            } else {
                this.$refs.imageError.open();
            }
        },
        //**extra picture 3 load
        extraPic3(e) {
            if (this.validImagenFile(e.target.files[0])) {
                this.form.extraPicture3 = e.target.files[0];
            } else {
                this.$refs.imageError.open();
            }
        },
        //**extra picture 4 load
        extraPic4(e) {
            if (this.validImagenFile(e.target.files[0])) {
                this.form.extraPicture4 = e.target.files[0];
            } else {
                this.$refs.imageError.open();
            }
        },
        //**extra picture 5 load
        extraPic5(e) {
            if (this.validImagenFile(e.target.files[0])) {
                this.form.extraPicture5 = e.target.files[0];
            } else {
                this.$refs.imageError.open();
            }
        },
        //*main picture load
        mainPic(e) {
            if (this.validImagenFile(e.target.files[0])) {
                this.form.mainPicture = e.target.files[0];
            } else {
                this.$refs.imageError.open();
            }
        },
        async saveProduct(e)  {
            e.preventDefault();
            this.emptyValidation();

            if (this.allValidationsPassed()) {
                if (this.allImagesAreValid()) {
                    try {
                        this.$refs.modalLoad.open();
                        const data = this.createFormData();
                        await this.submitForm(data);
                        window.location.href = "/administrador/inventario";
                    } catch (err) {
                        console.log(err);
                        this.$refs.generalError.open();
                    } finally {
                        this.$refs.modalLoad.close();
                    }
                } else {
                    this.$refs.imageError.open();
                }
            }
        },

        allValidationsPassed() {
            const vl = this.validation;
            return (
                !vl.validName &&
                !vl.validStartDate &&
                !vl.validEndDate &&
                !vl.validDetails &&
                !vl.validPrice &&
                !vl.validCategories &&
                !vl.validMainPic &&
                !vl.validPositivePrice &&
                !vl.validDateRange &&
                !vl.validProductType
            );
        },

        allImagesAreValid() {
            const frm = this.form;
            return (
                this.validImagenFile(frm.mainPicture) &&
                this.validImagenFile(frm.extraPicture1) &&
                this.validImagenFile(frm.extraPicture2) &&
                this.validImagenFile(frm.extraPicture3) &&
                this.validImagenFile(frm.extraPicture4) &&
                this.validImagenFile(frm.extraPicture5)
            );
        },

        createFormData() {
            const frm = this.form;
            const data = new FormData();

            data.append("name", frm.name);
            data.append("availableStart", frm.availableStart);
            data.append("avalableEnd", frm.availableEnd);
            data.append("details", frm.details.replace(/\r?\n/g, "<br/>"));
            data.append(
                "ribbonTitle",
                frm.ribbonTitle == undefined || frm.ribbonTitle == ""
                    ? null
                    : frm.ribbonTitle
            );
            data.append("price", parseFloat(frm.price.replace(",", ".")));
            data.append("productType", frm.productType);
            data.append("productCategories", frm.productCategories);
            data.append("productFilter", frm.productFilter);
            this.appendExtraProducts(data);
            this.appendPictures(data);

            return data;
        },

        appendExtraProducts(data) {
            const frm = this.form;
            for (let i = 1; i <= 3; i++) {
                data.append(
                    `extraProduct${i}`,
                    frm[`extraProduct${i}`] == undefined ? "" : frm[`extraProduct${i}`]
                );
            }
        },

        appendPictures(data) {
            const frm = this.form;
            data.append("mainPicture", frm.mainPicture);
            for (let i = 1; i <= 5; i++) {
                data.append(`extraPicture${i}`, frm[`extraPicture${i}`]);
            }
        },

        async submitForm(data) {
            const url =
                this.screen_title == "add"
                    ? "/administrador/inventario/store"
                    : `/administrador/inventario/${this.product.id}`;

            console.log('URL submitForm'+ url)

            const response = await axios.post(url, data);

            if (response.data.message === "error") {
                throw new Error(response.data.errorMessage);
            }
        },

        setProductOptions() {
            //creating a new array with id and name
            this.products.forEach((element) => {
                this.productOptions.push({
                    id: element.id,
                    text: element.name,
                });
            });
            //creating an array for product types optios
            this.product_types.forEach((element) => {
                var showSelectOption =
                    element.name +
                    " (" +
                    element.slug +
                    ")" +
                    this.productTypeDescription(element.id);
                if (element.id != 9) {
                    this.productTypesSelectValues.push({
                        id: element.id,
                        text: showSelectOption.replace("_", " "),
                    });
                }
            });
        },
        setUrlImage(url) {
            console.log(url);
            this.urlSrc = url;
            this.modal = 1;
        },
        closeModal() {
            this.modal = 0;
        },
        showProductTypesWithForeignKey() {
            //*filtering categories getting product type ids
            this.cats.forEach((element) => {
                this.catsid.push(element.product_type_id);
            });
            //*orderingIds
            const idsOrdered = this.catsid.sort();
            idsOrdered.forEach((element) => {
                if (!this.unicCatsId.includes(element)) {
                    this.unicCatsId.push(element);
                }
            });
            //?created a new object only with the primaries keys name
            this.product_types.forEach((element) => {
                if (this.unicCatsId.includes(element.id)) {
                    this.categorias.push({
                        id: element.id,
                        name: element.name,
                    });
                }
            });
            console.log(this.form.availableEnd);
        },
        validImagenFile(imageFile) {
            if (this.screen_title == "add") {
                if (imageFile != null) {
                    return /\.(svg|jpg|png|gif)$/i.test(imageFile.name);
                } else {
                    return true;
                }
            } else {
                if (imageFile != null && typeof imageFile != "string") {
                    return /\.(svg|jpg|png|gif)$/i.test(imageFile.name);
                } else {
                    return true;
                }
            }
        },
        productTypeDescription(id) {
            if (id == 1 || id == 2) {
                return " Busqueda en sitio web";
            } else if (id == 3 || id == 4 || id == 5 || id == 6 || id == 7) {
                return " Producto personalizado";
            } else {
                return "";
            }
        },
    },
    mounted() {
        this.showProductTypesWithForeignKey();
        this.setProductOptions();
    },
    created() {},
};
</script>

<template>
    <div style="padding: 10px; margin: 10px" class="white">
        <form @submit="saveProduct">
            <div class="row">
                <div class="col-2">
                    <svg
                        style="cursor: pointer"
                        @click="back()"
                        id="Component_42_1"
                        data-name="Component 42 – 1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="150"
                        height="54"
                        viewBox="0 0 150 54"
                    >
                        <rect
                            id="Rectangle_1890"
                            data-name="Rectangle 1890"
                            width="150"
                            height="54"
                            rx="27"
                            fill="#df438b"
                        />
                        <g
                            id="Group_1872"
                            data-name="Group 1872"
                            transform="translate(-295 -148)"
                        >
                            <g
                                id="Icon_feather-arrow-left"
                                data-name="Icon feather-arrow-left"
                                transform="translate(313.811 156.911)"
                            >
                                <path
                                    id="Path_4585"
                                    data-name="Path 4585"
                                    d="M29.5,18H7.5"
                                    transform="translate(0 0.5)"
                                    fill="none"
                                    stroke="#fff"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="3"
                                />
                                <path
                                    id="Path_4586"
                                    data-name="Path 4586"
                                    d="M18.5,29.5l-11-11,11-11"
                                    transform="translate(0 0)"
                                    fill="none"
                                    stroke="#fff"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="3"
                                />
                            </g>
                            <text
                                id="Regresar"
                                transform="translate(354 180)"
                                fill="#fff"
                                font-size="16"
                                font-family="SegoeUI-Bold, Segoe UI"
                                font-weight="700"
                            >
                                <tspan x="0" y="0">Regresar</tspan>
                            </text>
                        </g>
                    </svg>
                </div>
                <div class="col-8" style="text-align: center">
                    <h2>
                        {{
                            screen_title == "add"
                                ? "Agregar producto"
                                : "Editar producto"
                        }}
                    </h2>
                </div>
                <div class="col-2"></div>
            </div>
            <div class="row" style="margin-top: 60px">
                <div class="col-4">
                    <div class="col-12 py-2 px-2">
                        <label for="" style="color: #df438b"><b>Nombre de producto</b></label>
                        <input
                            class="form-control"
                            v-model="form.name"
                            type="text"
                        />
                        <span class="text-danger" v-if="validation.validName">{{
                            errorMessages.generalError
                        }}</span>
                    </div>
                    <div class="col-12 py-2 px-2">
                        <label for="" style="color: #df438b"
                            ><b>Fecha de disponibilidad</b></label
                        >
                        <div class="row">
                            <div class="col-6">
                                <label for=""><b>De:</b></label>
                                <input
                                    class="form-control"
                                    v-model="form.availableStart"
                                    type="date"
                                />
                                <span
                                    class="text-danger"
                                    v-if="validation.validStartDate"
                                    >{{ errorMessages.generalError }}</span
                                >
                            </div>
                            <div class="col-6">
                                <label for=""><b>Hasta:</b></label>
                                <input
                                    class="form-control"
                                    v-model="form.availableEnd"
                                    type="date"
                                />
                                <span
                                    class="text-danger"
                                    v-if="validation.validEndDate"
                                    >{{ errorMessages.generalError }}</span
                                >
                            </div>
                            <div class="container">
                                <span
                                    class="text-danger"
                                    v-if="validation.validDateRange"
                                    >{{ errorMessages.dateRangeError }}</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-12 py-2 px-2">
                        <label for="" style="color: #df438b"
                            ><b>Detalles</b></label
                        >
                        <textarea
                            style="height: 150px"
                            class="form-control"
                            v-model="form.details"
                        ></textarea>
                        <span
                            class="text-danger"
                            v-if="validation.validDetails"
                            >{{ errorMessages.generalError }}</span
                        >
                    </div>
                    <div class="col-12 py-2 px-2">
                        <center>
                            <label for="" style="color: #df438b"
                                ><b>Listón</b></label
                            ><br />
                            <svg
                                @click="activeListon()"
                                v-if="!form.liston"
                                xmlns="http://www.w3.org/2000/svg"
                                width="239"
                                height="40"
                                viewBox="0 0 239 40"
                            >
                                <text
                                    id="Habilitar"
                                    transform="translate(179 25)"
                                    fill="#3b3b3b"
                                    font-size="16"
                                    font-family="SegoeUI, Segoe UI"
                                >
                                    <tspan x="0" y="0">Habilitar</tspan>
                                </text>
                                <text
                                    id="Deshabilitar"
                                    transform="translate(0 25)"
                                    fill="#3b3b3b"
                                    font-size="16"
                                    font-family="SegoeUI, Segoe UI"
                                >
                                    <tspan x="0" y="0">Deshabilitar</tspan>
                                </text>
                                <g
                                    id="Component_47_12"
                                    data-name="Component 47 – 12"
                                    transform="translate(93)"
                                >
                                    <g
                                        id="Rectangle_1919"
                                        data-name="Rectangle 1919"
                                        fill="#fff"
                                        stroke="#707070"
                                        stroke-width="1"
                                    >
                                        <rect
                                            width="80"
                                            height="40"
                                            rx="20"
                                            stroke="none"
                                        />
                                        <rect
                                            x="0.5"
                                            y="0.5"
                                            width="79"
                                            height="39"
                                            rx="19.5"
                                            fill="none"
                                        />
                                    </g>
                                    <g
                                        id="Ellipse_1"
                                        data-name="Ellipse 1"
                                        fill="#707070"
                                        stroke="#707070"
                                        stroke-width="1"
                                    >
                                        <circle
                                            cx="20"
                                            cy="20"
                                            r="20"
                                            stroke="none"
                                        />
                                        <circle
                                            cx="20"
                                            cy="20"
                                            r="19.5"
                                            fill="none"
                                        />
                                    </g>
                                </g>
                            </svg>

                            <svg
                                @click="activeListon()"
                                v-if="form.liston"
                                xmlns="http://www.w3.org/2000/svg"
                                width="239"
                                height="40"
                                viewBox="0 0 239 40"
                            >
                                <text
                                    id="Habilitar"
                                    transform="translate(179 25)"
                                    fill="#3b3b3b"
                                    font-size="16"
                                    font-family="SegoeUI, Segoe UI"
                                >
                                    <tspan x="0" y="0">Habilitar</tspan>
                                </text>
                                <text
                                    id="Deshabilitar"
                                    transform="translate(0 25)"
                                    fill="#3b3b3b"
                                    font-size="16"
                                    font-family="SegoeUI, Segoe UI"
                                >
                                    <tspan x="0" y="0">Deshabilitar</tspan>
                                </text>
                                <g
                                    id="Component_47_12"
                                    data-name="Component 47 – 12"
                                    transform="translate(93)"
                                >
                                    <g
                                        id="Rectangle_1919"
                                        data-name="Rectangle 1919"
                                        fill="#fff"
                                        stroke="#df438b"
                                        stroke-width="1"
                                    >
                                        <rect
                                            width="80"
                                            height="40"
                                            rx="20"
                                            stroke="none"
                                        />
                                        <rect
                                            x="0.5"
                                            y="0.5"
                                            width="79"
                                            height="39"
                                            rx="19.5"
                                            fill="none"
                                        />
                                    </g>
                                    <g
                                        id="Ellipse_1"
                                        data-name="Ellipse 1"
                                        transform="translate(40)"
                                        fill="#df438b"
                                        stroke="#df438b"
                                        stroke-width="1"
                                    >
                                        <circle
                                            cx="20"
                                            cy="20"
                                            r="20"
                                            stroke="none"
                                        />
                                        <circle
                                            cx="20"
                                            cy="20"
                                            r="19.5"
                                            fill="none"
                                        />
                                    </g>
                                </g>
                            </svg>
                        </center>
                    </div>
                    <div v-if="form.liston" class="col-12 py-2 px-2">
                        <label for="" style="color: #df438b"
                            ><b>Texto de listón</b></label
                        >
                        <input
                            class="form-control"
                            v-model="form.ribbonTitle"
                            type="text"
                        />
                        <span
                            class="text-danger"
                            v-if="validation.validRibbon == true"
                            >{{ errorMessages.generalError }}</span
                        >
                    </div>
                    <!-- price input -->
                    <div class="col-12 py-2 px-2">
                        <label for="" style="color: #df438b"
                            ><b>Precio:</b></label
                        >
                        <input
                            class="form-control"
                            v-model="form.price"
                            type="number"
                            step="0.01"
                            min="0"
                        />
                        <span
                            class="text-danger"
                            v-if="validation.validPrice"
                            >{{ errorMessages.generalError }}</span
                        >
                        <span
                            class="text-danger"
                            v-if="validation.validPositivePrice"
                            >{{ errorMessages.negativeError }}</span
                        >
                    </div>
                </div>
                <!--start Checklist div -->
                <div class="col-4">
                    <div class="container">
                        <!--start Checklist title -->
                        <h5 for="" style="color: #df438b">
                            <b>Tipo de producto:</b>
                        </h5>
                        <!--end checklist title-->

                        <!--start product types Select -->

                        <div class="form-group">
                            <Select2
                                v-model="form.productType"
                                :options="productTypesSelectValues"
                                :settings="{
                                    placeholder: placeholder,
                                    dropdownCssClass: 'product_select',
                                }"
                            />
                            <span
                                class="text-danger"
                                v-if="validation.validProductType"
                                >{{ errorMessages.generalError }}</span
                            >
                        </div>
                        <!--end product types select -->
                        <h5 for="" style="color: #df438b">
                            <b>Categorias:</b>
                        </h5>
                        <!--start product_types loop -->
                        <div class="row">
                            <div class="col-12">
                                <ul>
                                    <!--start categories loop -->
                                    <div v-for="cat in cats" :key="cat.id">
                                        <label>
                                            <input
                                                class="form-check-input create_product"
                                                type="checkbox"
                                                :value="cat.id"
                                                v-model="form.productCategories"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="categorie-name"
                                                style="color: #df438b"
                                            >{{ cat.name }}
                                            </label>
                                        </label>
                                    </div>
                                    <!--end categories loop -->
                                </ul>
                            </div>
                        </div>
                        <!-- end product type loop -->
                    </div>
                    <div class="container">
                        <h5 for="" style="color: #df438b">
                            <b>Tipo de Filtros:</b>
                        </h5>
                        <!--start filter loops -->
                        <div v-for="filter in filters" :key="filter.id">
                            <!--verifying if the filter has a parent id -->
                            <div v-if="filter.parent_id == null">
                                <label for="" style="color: #df438b">
                                    <!-- Displaying titles -->
                                    {{ filter.name }}
                                </label>
                                <div class="row">
                                    <div class="col-12">
                                        <ul>
                                            <!--start children filters loop -->
                                            <div v-for="fil in filters" :key="fil.id">
                                                <!-- filtering children with parent id -->
                                                <div v-if="fil.parent_id == filter.id">
                                                    <!-- ocassion check list -->
                                                    <li>
                                                        <label>
                                                            <input
                                                                class="form-check-input create_product"
                                                                type="checkbox"
                                                                :value="fil.id"
                                                                v-model="
                                                                    form.productFilter
                                                                "
                                                            />
                                                            <label
                                                                class="form-check-label"
                                                                for="categorie-name"
                                                                style="
                                                                    color: #df438b;
                                                                "
                                                                >{{
                                                                    fil.name
                                                                }}</label
                                                            >
                                                        </label>
                                                    </li>
                                                </div>
                                                <!--end if-->
                                            </div>
                                            <!--end children loop-->
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end filter loops -->
                    </div>
                    <span
                        class="text-danger"
                        v-if="validation.validCategories"
                        >{{ errorMessages.categoriesError }}</span
                    >
                    <center>
                        <div class="col-8" style="text-align: center">
                            <input
                                class="form-control btn btn-danger"
                                style="
                                    margin-top: 8.8%;
                                    background-color: #df438b;
                                    border-color: #df438b;
                                    font-weight: bold;
                                "
                                type="submit"
                                value="Guardar producto"
                            />
                        </div>
                    </center>
                </div>
                <!--end Check box list div -->
                <!--start div (extras and images)  -->
                <div class="col-4">
                    <div class="container">
                        <!-- extras title  -->
                        <label
                            class="form-check-label"
                            for="categorie-name"
                            style="color: #df438b"
                            ><h5><b>Extras</b></h5></label
                        >
                        <!-- start combobox for product options -->
                        <div class="form-group">
                            <Select2
                                v-model="form.extraProduct1"
                                :options="productOptions"
                                :settings="{
                                    placeholder: placeholder,
                                    dropdownCssClass: 'product_select',
                                    allowClear: true,
                                }"
                            />
                        </div>
                        <div class="form-group">
                            <Select2
                                v-model="form.extraProduct2"
                                :options="productOptions"
                                :settings="{
                                    placeholder: placeholder,
                                    dropdownCssClass: 'product_select',
                                    allowClear: true,
                                }"
                            />
                        </div>
                        <div class="form-group">
                            <Select2
                                v-model="form.extraProduct3"
                                :options="productOptions"
                                :settings="{
                                    placeholder: placeholder,
                                    dropdownCssClass: 'product_select',
                                    allowClear: true,
                                }"
                            />
                        </div>
                        <!-- end combobox for product options -->
                        <!-- start product images details -->
                        <label for="images-title">
                            <label
                                class="form-check-label"
                                for="categorie-name"
                                style="color: #df438b"
                                ><b>Imágenes</b></label
                            >
                            (máximo 6, medidas de 340x440px, JPG, Tamaño máximo
                            3MB).</label
                        >
                        <!-- start div file images inputs container -->
                        <div>
                            <label for="images-input"> Foto principal: </label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span
                                        class="input-group-text"
                                        id="inputGroupFileAddon01"
                                        >Subir foto</span
                                    >
                                </div>
                                <div class="custom-file">
                                    <input
                                        type="file"
                                        class="custom-file-input"
                                        accept="image/*"
                                        v-on:change="mainPic"
                                    />
                                    <label
                                        class="custom-file-label"
                                        for="inputGroupFile01"
                                        >{{
                                            form.mainPicture == null ||
                                            form.mainPicture == undefined
                                                ? "Buscar"
                                                : form.mainPicture.name
                                        }}</label
                                    >
                                </div>
                                &nbsp;
                                <div v-if="screen_title != 'add' &&
                                        form.mainPicture != undefined &&
                                        form.mainPicture != ''">
                                    <button
                                        v-if="
                                            typeof form.mainPicture == 'string'
                                        "
                                        type="button"
                                        class="btn btn-primary"
                                        @click="setUrlImage(form.mainPicture)"
                                    >
                                        <i class="fas fa-eye"></i>
                                    </button>
                                </div>
                                <span
                                    class="text-danger"
                                    v-if="
                                        validation.validMainPic &&
                                        screen_title == 'add'
                                    "
                                    >{{ errorMessages.generalError }}</span
                                >
                                <span
                                    class="text-danger"
                                    v-if="
                                        validation.validMainPic &&
                                        screen_title == 'edit'
                                    "
                                    >{{ errorMessages.generalError }}</span
                                >
                            </div>
                        </div>
                        <div>
                            <label for="images-input"> Fotos extras: </label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span
                                        class="input-group-text"
                                        id="inputGroupFileAddon01"
                                        >Subir foto</span
                                    >
                                </div>
                                <div class="custom-file">
                                    <input
                                        type="file"
                                        class="custom-file-input"
                                        accept="image/*"
                                        ref="extraPic1InputFile"
                                        @change="extraPic1"
                                    />
                                    <label
                                        class="custom-file-label"
                                        for="inputGroupFile01"
                                        >{{
                                            form.extraPicture1 == null
                                                ? "Buscar"
                                                : form.extraPicture1 == ""
                                                ? "Buscar"
                                                : form.extraPicture1.name
                                        }}</label
                                    >
                                </div>
                                &nbsp;
                                <div>
                                    <button
                                        v-if="
                                            screen_title != 'add' &&
                                            typeof form.extraPicture1 ==
                                                'string'
                                        "
                                        :hidden="
                                            form.extraPicture1 == null ||
                                            form.extraPicture1 == ''
                                        "
                                        type="button"
                                        class="btn btn-primary"
                                        @click="setUrlImage(form.extraPicture1)"
                                    >
                                        <i class="fas fa-eye"></i>
                                    </button>
                                    <button
                                        v-if="
                                            form.extraPicture1 != '' &&
                                            form.extraPicture1 != undefined
                                        "
                                        type="button"
                                        class="btn btn-danger"
                                        @click="
                                            () => {
                                                deleteProductPhoto(
                                                    form.extraPicture1,
                                                    1
                                                );
                                                form.extraPicture1 = '';
                                            }
                                        "
                                    >
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span
                                        class="input-group-text"
                                        id="inputGroupFileAddon01"
                                        >Subir foto</span
                                    >
                                </div>
                                <div class="custom-file">
                                    <input
                                        type="file"
                                        class="custom-file-input"
                                        v-on:change="extraPic2"
                                        accept="image/*"
                                        ref="extraPic2InputFile"
                                    />
                                    <label
                                        class="custom-file-label"
                                        for="inputGroupFile01"
                                        >{{
                                            form.extraPicture2 == null
                                                ? "Buscar"
                                                : form.extraPicture2 == ""
                                                ? "Buscar"
                                                : form.extraPicture2.name
                                        }}</label
                                    >
                                </div>
                                &nbsp;
                                <div>
                                    <button
                                        v-if="
                                            typeof form.extraPicture2 ==
                                            'string'
                                        "
                                        :hidden="
                                            form.extraPicture2 == null ||
                                            form.extraPicture2 == ''
                                        "
                                        type="button"
                                        class="btn btn-primary"
                                        @click="setUrlImage(form.extraPicture2)"
                                    >
                                        <i class="fas fa-eye"></i>
                                    </button>
                                    <button
                                        v-if="
                                            form.extraPicture2 != '' &&
                                            form.extraPicture2 != undefined
                                        "
                                        type="button"
                                        class="btn btn-danger"
                                        @click="
                                            () => {
                                                deleteProductPhoto(
                                                    form.extraPicture2,
                                                    2
                                                );
                                                form.extraPicture2 = '';
                                            }
                                        "
                                    >
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                            </div>

                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span
                                        class="input-group-text"
                                        id="inputGroupFileAddon01"
                                        >Subir foto</span
                                    >
                                </div>
                                <div class="custom-file">
                                    <input
                                        type="file"
                                        class="custom-file-input"
                                        v-on:change="extraPic3"
                                        accept="image/*"
                                        ref="extraPic3InputFile"
                                    />
                                    <label
                                        class="custom-file-label"
                                        for="inputGroupFile01"
                                        >{{
                                            form.extraPicture3 == null
                                                ? "Buscar"
                                                : form.extraPicture3 == ""
                                                ? "Buscar"
                                                : form.extraPicture3.name
                                        }}</label
                                    >
                                </div>
                                &nbsp;
                                <div>
                                    <button
                                        v-if="
                                            screen_title != 'add' &&
                                            typeof form.extraPicture3 ==
                                                'string'
                                        "
                                        :hidden="
                                            form.extraPicture3 == null ||
                                            form.extraPicture3 == ''
                                        "
                                        type="button"
                                        class="btn btn-primary"
                                        @click="setUrlImage(form.extraPicture3)"
                                    >
                                        <i class="fas fa-eye"></i>
                                    </button>
                                    <button
                                        v-if="
                                            form.extraPicture3 != '' &&
                                            form.extraPicture3 != undefined
                                        "
                                        type="button"
                                        class="btn btn-danger"
                                        @click="
                                            () => {
                                                deleteProductPhoto(
                                                    form.extraPicture3,
                                                    3
                                                );
                                                form.extraPicture3 = '';
                                            }
                                        "
                                    >
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span
                                        class="input-group-text"
                                        id="inputGroupFileAddon01"
                                        >Subir foto</span
                                    >
                                </div>
                                <div class="custom-file">
                                    <input
                                        type="file"
                                        class="custom-file-input"
                                        v-on:change="extraPic4"
                                        accept="image/*"
                                        ref="extraPic4InputFile"
                                    />
                                    <label
                                        class="custom-file-label"
                                        for="inputGroupFile01"
                                        >{{
                                            form.extraPicture4 == null
                                                ? "Buscar"
                                                : form.extraPicture4 == ""
                                                ? "Buscar"
                                                : form.extraPicture4.name
                                        }}</label
                                    >
                                </div>
                                &nbsp;
                                <div>
                                    <button
                                        v-if="
                                            screen_title != 'add' &&
                                            typeof form.extraPicture4 ==
                                                'string'
                                        "
                                        :hidden="
                                            form.extraPicture4 == null ||
                                            form.extraPicture4 == ''
                                        "
                                        type="button"
                                        class="btn btn-primary"
                                        @click="setUrlImage(form.extraPicture4)"
                                    >
                                        <i class="fas fa-eye"></i>
                                    </button>
                                    <button
                                        v-if="
                                            form.extraPicture4 != '' &&
                                            form.extraPicture4 != undefined
                                        "
                                        type="button"
                                        class="btn btn-danger"
                                        @click="
                                            () => {
                                                deleteProductPhoto(
                                                    form.extraPicture4,
                                                    4
                                                );
                                                form.extraPicture4 = '';
                                            }
                                        "
                                    >
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span
                                        class="input-group-text"
                                        id="inputGroupFileAddon01"
                                        >Subir foto</span
                                    >
                                </div>
                                <div class="custom-file">
                                    <input
                                        type="file"
                                        class="custom-file-input"
                                        v-on:change="extraPic5"
                                        accept="image/*"
                                        ref="extraPic5InputFile"
                                    />
                                    <label
                                        class="custom-file-label"
                                        for="inputGroupFile01"
                                        >{{
                                            form.extraPicture5 == null
                                                ? "Buscar"
                                                : form.extraPicture5 == ""
                                                ? "Buscar"
                                                : form.extraPicture5.name
                                        }}</label
                                    >
                                </div>
                                &nbsp;
                                <div>
                                    <button
                                        v-if="
                                            screen_title != 'add' &&
                                            typeof form.extraPicture5 ==
                                                'string'
                                        "
                                        :hidden="
                                            form.extraPicture5 == null ||
                                            form.extraPicture5 == ''
                                        "
                                        type="button"
                                        class="btn btn-primary"
                                        @click="setUrlImage(form.extraPicture5)"
                                    >
                                        <i class="fas fa-eye"></i>
                                    </button>
                                    <button
                                        v-if="
                                            form.extraPicture5 != '' &&
                                            form.extraPicture5 != undefined
                                        "
                                        type="button"
                                        class="btn btn-danger"
                                        @click="
                                            () => {
                                                deleteProductPhoto(
                                                    form.extraPicture5,
                                                    5
                                                );
                                                form.extraPicture5 = '';
                                            }
                                        "
                                    >
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- end div file images inputs container -->
                        <!-- end product images details -->
                    </div>
                </div>
            </div>
        </form>
        <vue-modal ref="imageError" title="">
            <template v-slot:header> </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <div class="container">
                        <center>
                            <p
                                style="
                                    margin-top: 8px;
                                    font-size: 20px;
                                    font-weight: bold;
                                "
                            >
                                ¡Vaya, el formato de imagen no es soportado!
                                <br />
                                Los formatos aceptados son únicamente JPG y PNG.
                            </p>
                        </center>
                    </div>
                    <div
                        class="row"
                        style="margin-right: 0px; margin-left: 0px"
                    >
                        <div class="col-12" style="padding: 5px">
                            <div class="container">
                                <button
                                    @click="closeModalImage()"
                                    class="form-control btn btn-danger"
                                    style="
                                        margin-top: 8.8%;
                                        background-color: #df438b;
                                        border-color: #df438b;
                                    "
                                >
                                    Entendido!
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </vue-modal>
        <vue-modal ref="generalError" title="">
            <template v-slot:header> </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <div class="container">
                        <center>
                            <p
                                style="
                                    margin-top: 8px;
                                    font-size: 20px;
                                    font-weight: bold;
                                "
                            >
                                Vaya! ha sucedido un error!
                            </p>
                            <p style="
                                    margin-top: 8px;
                                    font-size: 20px;
                                    font-weight: bold;
                                ">
                                Si el error persiste, contacte con soporte
                                dronautic@gmail.com
                            </p>
                        </center>
                    </div>
                    <div
                        class="row"
                        style="margin-right: 0px; margin-left: 0px"
                    >
                        <div class="col-12" style="padding: 5px">
                            <div class="container">
                                <button
                                    @click="closeErrorModal()"
                                    class="form-control btn btn-danger"
                                    style="
                                        margin-top: 8.8%;
                                        background-color: #df438b;
                                        border-color: #df438b;
                                    ">
                                    Entendido!
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </vue-modal>
        <vue-modal ref="modalLoad" title="" :showclosed="false">
            <template v-slot:header> </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <center>
                        <div>
                            <img :src="loaderIcon" alt="Loader Icon" />
                        </div>
                        <h3>Espere, por favor...</h3>
                    </center>
                </div>
            </template>
        </vue-modal>
        <!-- Image previewModal -->
        <div
            class="modal fade"
            :class="{ mostrar: modal }"
            tabindex="-1"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Imagen actual
                        </h5>
                        <button
                            type="button"
                            class="close"
                            @click="closeModal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <center>
                            <img :src="'/administrador/inventario/picture' + urlSrc"
                                class="card-img-center"
                                style="
                                    max-height: 390px;
                                    width: auto;
                                    max-width: 100%;
                                "
                            />
                        </center>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="closeModal">
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
//*<--start ul styles for checklist
ul {
    column-count: 2;
    column-gap: 2rem;
    list-style: none;
}
//?<--end ul styles
//*<-- start checklist styles
.create_product {
    padding: 1;
    font: inherit;
    color: #00fbbb;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
}
.create_product {
    /* ...existing styles */
    display: grid;
    place-content: center;
}

.create_product::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
}

.create_product:checked::before {
    transform: scale(1);
}
.mostrar {
    display: list-item;
    opacity: 1;
    background: #d877a4b6;
    padding-top: 15%;
}
//?end<-- end check list styles
//*<--start combobox styles
.product_select {
    width: 100%;
    min-width: 15ch;
    max-width: 35ch;
    border: 1px solid var(--select-border);
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    font-size: 1.25rem;
    cursor: pointer;
    line-height: 1.1;
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
}
//?<--end combobox styles
</style>
