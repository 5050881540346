<script>
import { mapActions, mapGetters } from "vuex";
import VueModal from "./VueModal.vue";

export default {
    components: {
        VueModal,
    },
    props: ["product", "extras"],
    data() {
        return {
            qty: 1,
            btnQty: [1, 2, 3, 4],
            saving: false,
            errors: {
                date: "",
            },
            extraProducts: [],
            itemAvailability: true,
        };
    },
    computed: {
        ...mapGetters({
            cart: "cart",
        }),
        totalPrice() {
            return (this.product.price_float * this.qty).toFixed(2);
        }
    },
    methods: {
        closeModalExtras() {
            this.$refs.modalExtras.close();
        },
        setValue(qty) {
            this.qty = qty;
        },
        async handlerAddToCart() {
            if (!this.itemAvailability) {
                this.errors.date = "El producto no está disponible en la fecha seleccionada.";
                return;
            }

            this.saving = true;
            this.$refs.modalExtras.open();

            let product = JSON.stringify(this.product);
            product = JSON.parse(product);
            product.qty = this.qty;
            try {
                await this.addToCart(product);
            } catch (error) {
                console.error(error);
            }

            this.saving = false;
        },
        async handlerAddExtraToCart(product) {
            let _product = product;
            _product.saving = true;
            let vm = this;
            product = JSON.stringify(product);
            product = JSON.parse(product);
            try {
                await vm.addToCart(product);
                _product.saving = false;
            } catch (error) {
                console.log(error);
                _product.saving = false;
            }
        },
        loadExtras() {
            if (this.extras.length) {
                let extras = JSON.stringify(this.extras);
                extras = JSON.parse(extras);
                extras = extras.map((e) => {
                    e.product.qty = 1;
                    e.product.saving = false;
                    return e;
                });
                this.$set(this, "extraProducts", extras);
            }
        },
        isProductAvailable() {
            const today = new Date();
            const startDate = new Date(this.product.start_date.replace(' ', 'T'));
            const endDate = new Date(this.product.end_date.replace(' ', 'T'));

            return today >= startDate && today <= endDate;
        },
        ...mapActions({
            addToCart: "addToCart",
        }),
        getFormattedDate(date) {
            let month = date.getMonth() + 1;
            let day = date.getDate();
            const year = date.getFullYear();
            if (month < 10) month = "0" + month.toString();
            if (day < 10) day = "0" + day.toString();
            return `${year}-${month}-${day}`;
        },
    },
    mounted() {
        this.loadExtras();
        this.itemAvailability = this.isProductAvailable();
    },
};
</script>

<template>
    <div>
        <h4 class="sub-title text-secondary text-center font-weight-bold">
            ${{ totalPrice }} USD
        </h4>
        <div>
            <h5 class="font-weight-bold mb-0">Cantidad</h5>
            <div class="d-flex flex-wrap product__qty-controls">
                <div
                    class="p-1"
                    v-for="(q, index) in btnQty"
                    :key="`btnqty_${index}_${q}`"
                >
                    <button
                        class="btn btn-default"
                        :class="{ 'btn--selected': q == qty }"
                        @click="setValue(q)"
                    >
                        {{ q }}
                    </button>
                </div>
                <div class="p-1">
                    <div class="form-group">
                        <input
                            class="form-control"
                            v-model="qty"
                            type="number"
                        />
                    </div>
                </div>
            </div>
            <h5 v-if="!itemAvailability" class="text-danger">No disponible</h5>
            <div v-if="itemAvailability" class="p-1 mt-3">
                <button
                    class="btn btn-primary font-weight-bold btn-block"
                    @click="handlerAddToCart"
                    :disabled="saving"
                >
                    Añadir al carrito
                </button>
                <p v-if="errors.date" class="text-danger">{{ errors.date }}</p>
            </div>
        </div>

        <vue-modal ref="modalExtras" title="" :showclosed="true">
            <template v-slot:header>
                <div class="d-flex w-100">
                    <div class="col-6">
                        <h5 class="text-primary m-0">
                            <b>¡Agrega algo extra especial!</b>
                        </h5>
                    </div>
                    <div class="col-3 text-center">
                        <h6 class="text-primary m-0">Cantidad</h6>
                    </div>
                    <div class="col-3">
                        <h6 class="text-primary m-0">
                            <b>TOTAL: ${{ cart.total | numeral("0,0.00") }}</b>
                        </h6>
                    </div>
                    <button
                        type="button"
                        class="close"
                        style="
                    margin-top: -3%;
                    margin-left: -1%;
                    color: #d10043;
                "
                        @click="closeModalExtras"
                    >
                        <center>
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </center>
                    </button>
                </div>
            </template>
            <template v-slot:body>
                <div class="container-fluid">
                    <div
                        v-for="(product, index) in extraProducts"
                        :key="`extras_${index}_${product.product.id}`"
                        class="row mb-3 align-items-center"
                    >
                        <div class="col-12 col-md-6">
                            <div class="d-flex align-items-center">
                                <div class="extra__photo">
                                    <img :src="product.product.thumbnail" alt="" class="img-fluid" />
                                </div>
                                <div class="px-1">
                                    <h5 class="m-0 extra__title">{{ product.product.name }}</h5>
                                    <p class="extra__price m-0">+${{ product.product.price_float }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-md-3">
                            <div class="d-flex align-items-center">
                                <button
                                    class="btn--rounded"
                                    :disabled="product.product.qty <= 1"
                                    @click="product.product.qty -= 1"
                                >
                                    <i class="fa fa-minus-circle fa-lg" aria-hidden="true"></i>
                                </button>
                                <div class="px-2">
                                    <input
                                        type="number"
                                        min="1"
                                        disabled
                                        class="form-control text-center no-arrows"
                                        v-model="product.product.qty"
                                    />
                                </div>
                                <button class="btn--rounded" @click="product.product.qty += 1">
                                    <i class="fa fa-plus-circle fa-lg" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                        <div class="col-6 col-md-3">
                            <button
                                class="btn btn-light border-primary text-primary btn-block"
                                @click="handlerAddExtraToCart(product.product)"
                                :disabled="product.product.saving"
                            >
                                Añadir
                            </button>
                        </div>
                    </div>

                    <div class="border-top mx-n3 px-3">
                <span
                >*Las imágenes de los extras son de carácter
                    ilustrativo y serán seleccionados por el personal de
                    Eventos y Detalles para que sean acordes al tipo de
                    producto principal seleccionado</span
                >
                        <div class="d-flex pt-2">
                            <button
                                @click="$refs.modalExtras.close()"
                                class="btn btn-light border-primary text-primary"
                            >
                                Seguir comprando
                            </button>
                            <button
                                @click="$refs.modalExtras.close()"
                                class="btn btn-light border ml-auto"
                            >
                                No, gracias
                            </button>
                            <a href="/payment" class="btn btn-primary ml-2"
                            >Continuar</a
                            >
                        </div>
                    </div>
                </div>
            </template>
        </vue-modal>
    </div>
</template>

<style lang="scss" scoped>
.btn {
    &-default {
        background-color: #f1f1f1;
    }
    &--selected {
        border: 1px solid #d10043;
    }
}
.product__qty-controls {
    .form-control {
        max-width: 80px;
    }
}
.is-invalid {
    & ~ .valid-feedback {
        display: block;
    }
}
.extra__photo img {
    max-width: 100%;
    height: auto;
}
</style>
