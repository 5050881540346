<template>
    <div>
        <div class="cintillo__notification">
            <h1>text</h1>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.cintillo__notification {
    width: 100%;
    height: 16vh;
    background-color: red;
}
</style>
