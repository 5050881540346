<template>
    <div>
        <div class="py-2 px-2">
            <div class="bg-white">
                <div class="container" style="padding: 8">
                    <!-- start card info div -->
                    <div class="py-2 px-2">
                        <!-- start form -->
                        <form @submit="saveCarrousel">
                            <!-- start image input -->
                            <center>
                                <label
                                    for=""
                                    style="color: #df438b; margin-top: 3em"
                                    ><b>{{ title }}</b></label
                                >
                            </center>
                            <div class="input-group mb-3">
                                <div class="custom-file">
                                    <input
                                        type="file"
                                        class="custom-file-input"
                                        accept="image/*"
                                        v-on:change="CarrouselImage"
                                    />
                                    <label
                                        class="custom-file-label"
                                        for="inputGroupFile01"
                                        >Seleccionar imagen</label
                                    >
                                </div>
                                &nbsp;
                                <div
                                    v-if="
                                        form.carrousel_image != undefined &&
                                        form.carrousel_image != ''
                                    "
                                >
                                    <button
                                        v-if="
                                            typeof form.carrousel_image ==
                                            'string'
                                        "
                                        type="button"
                                        class="btn btn-primary"
                                        @click="
                                            setUrlImage(form.carrousel_image)
                                        "
                                    >
                                        <i class="fas fa-eye"></i>
                                    </button>
                                </div>

                                <span
                                    >Imagen con medidas de 1800x600px en JPG o
                                    PNG (recomendado)</span
                                >
                                <span
                                    class="text-danger"
                                    v-if="validation.carrousel_image"
                                    >{{ errorMessages.generalError }}</span
                                >
                            </div>
                            <!-- end image input -->
                            <!-- start titles inputs -->
                            <div class="form-group">
                                <label
                                    for=""
                                    class="mt-2"
                                    style="color: #df438b"
                                    ><b>Título</b></label
                                >
                                <input
                                    class="form-control"
                                    type="text"
                                    maxlength="70"
                                    v-model="form.title"
                                />
                                <span
                                    class="text-danger"
                                    v-if="validation.title"
                                    >{{ errorMessages.generalError }}</span
                                >
                            </div>
                            <!-- end titles inputs -->
                            <!-- start message input -->
                            <div class="form-group">
                                <label
                                    for=""
                                    class="mt-2"
                                    style="color: #df438b"
                                    ><b>Mensaje</b></label
                                >
                                <textarea
                                    class="form-control"
                                    type="text"
                                    maxlength="255"
                                    v-model="form.message"
                                ></textarea>
                                <span
                                    class="text-danger"
                                    v-if="validation.message"
                                    >{{ errorMessages.generalError }}</span
                                >
                            </div>
                            <!-- end message input -->
                            <div class="row">
                                <!-- start name input switch -->
                                <div class="col-12 py-2 px-2">
                                    <center>
                                        <label for="" style="color: #df438b"
                                            ><b>Botón</b></label
                                        ><br />
                                        <svg
                                            @click="activateButton"
                                            v-if="!form.btn_flag"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="239"
                                            height="40"
                                            viewBox="0 0 239 40"
                                        >
                                            <text
                                                id="Habilitar"
                                                transform="translate(179 25)"
                                                fill="#3b3b3b"
                                                font-size="16"
                                                font-family="SegoeUI, Segoe UI"
                                            >
                                                <tspan x="0" y="0">
                                                    Habilitar
                                                </tspan>
                                            </text>
                                            <text
                                                id="Deshabilitar"
                                                transform="translate(0 25)"
                                                fill="#3b3b3b"
                                                font-size="16"
                                                font-family="SegoeUI, Segoe UI"
                                            >
                                                <tspan x="0" y="0">
                                                    Deshabilitar
                                                </tspan>
                                            </text>
                                            <g
                                                id="Component_47_12"
                                                data-name="Component 47 – 12"
                                                transform="translate(93)"
                                            >
                                                <g
                                                    id="Rectangle_1919"
                                                    data-name="Rectangle 1919"
                                                    fill="#fff"
                                                    stroke="#707070"
                                                    stroke-width="1"
                                                >
                                                    <rect
                                                        width="80"
                                                        height="40"
                                                        rx="20"
                                                        stroke="none"
                                                    />
                                                    <rect
                                                        x="0.5"
                                                        y="0.5"
                                                        width="79"
                                                        height="39"
                                                        rx="19.5"
                                                        fill="none"
                                                    />
                                                </g>
                                                <g
                                                    id="Ellipse_1"
                                                    data-name="Ellipse 1"
                                                    fill="#707070"
                                                    stroke="#707070"
                                                    stroke-width="1"
                                                >
                                                    <circle
                                                        cx="20"
                                                        cy="20"
                                                        r="20"
                                                        stroke="none"
                                                    />
                                                    <circle
                                                        cx="20"
                                                        cy="20"
                                                        r="19.5"
                                                        fill="none"
                                                    />
                                                </g>
                                            </g>
                                        </svg>

                                        <svg
                                            @click="activateButton"
                                            v-if="form.btn_flag"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="239"
                                            height="40"
                                            viewBox="0 0 239 40"
                                        >
                                            <text
                                                id="Habilitar"
                                                transform="translate(179 25)"
                                                fill="#3b3b3b"
                                                font-size="16"
                                                font-family="SegoeUI, Segoe UI"
                                            >
                                                <tspan x="0" y="0">
                                                    Habilitar
                                                </tspan>
                                            </text>
                                            <text
                                                id="Deshabilitar"
                                                transform="translate(0 25)"
                                                fill="#3b3b3b"
                                                font-size="16"
                                                font-family="SegoeUI, Segoe UI"
                                            >
                                                <tspan x="0" y="0">
                                                    Deshabilitar
                                                </tspan>
                                            </text>
                                            <g
                                                id="Component_47_12"
                                                data-name="Component 47 – 12"
                                                transform="translate(93)"
                                            >
                                                <g
                                                    id="Rectangle_1919"
                                                    data-name="Rectangle 1919"
                                                    fill="#fff"
                                                    stroke="#df438b"
                                                    stroke-width="1"
                                                >
                                                    <rect
                                                        width="80"
                                                        height="40"
                                                        rx="20"
                                                        stroke="none"
                                                    />
                                                    <rect
                                                        x="0.5"
                                                        y="0.5"
                                                        width="79"
                                                        height="39"
                                                        rx="19.5"
                                                        fill="none"
                                                    />
                                                </g>
                                                <g
                                                    id="Ellipse_1"
                                                    data-name="Ellipse 1"
                                                    transform="translate(40)"
                                                    fill="#df438b"
                                                    stroke="#df438b"
                                                    stroke-width="1"
                                                >
                                                    <circle
                                                        cx="20"
                                                        cy="20"
                                                        r="20"
                                                        stroke="none"
                                                    />
                                                    <circle
                                                        cx="20"
                                                        cy="20"
                                                        r="19.5"
                                                        fill="none"
                                                    />
                                                </g>
                                            </g>
                                        </svg>
                                    </center>
                                </div>
                            </div>
                            <div v-if="form.btn_flag">
                                <!-- start button text titlw -->
                                <div class="form-group">
                                    <label
                                        for=""
                                        class="mt-2"
                                        style="color: #df438b"
                                        ><b>Texto de botón</b></label
                                    >
                                    <input
                                        class="form-control"
                                        type="text"
                                        maxlength="35"
                                        v-model="form.button_text"
                                    />
                                    <span
                                        class="text-danger"
                                        v-if="validation.button_text"
                                        >{{ errorMessages.generalError }}</span
                                    >
                                </div>
                                <!-- end button text title -->
                                <!-- start url title -->
                                <div class="form-group">
                                    <label
                                        for=""
                                        class="mt-2"
                                        style="color: #df438b"
                                        ><b>URL de botón</b></label
                                    >
                                    <input
                                        class="form-control"
                                        type="text"
                                        maxlength="255"
                                        v-model="form.url"
                                    />
                                    <span
                                        class="text-danger"
                                        v-if="validation.url"
                                        >{{ errorMessages.generalError }}</span
                                    >
                                </div>
                                <!-- end url title  -->
                            </div>
                            <!-- start save btn  -->
                            <center>
                                <button
                                    class="btn btn-success py-2 my-2"
                                    style="
                                        background-color: #df438b;
                                        border-color: #df438b;
                                        width: 50%;
                                    "
                                    type="submit"
                                >
                                    Guardar
                                </button>
                            </center>
                            <!-- end save btn -->
                        </form>
                        <!-- end form -->
                    </div>
                    <!-- ends card info div -->
                </div>
            </div>
        </div>

        <!-- image error modal -->
        <vue-modal ref="imageError" title="">
            <template v-slot:header> </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <div class="container">
                        <center>
                            <p
                                style="
                                    margin-top: 8px;
                                    font-size: 20px;
                                    font-weight: bold;
                                "
                            >
                                Vaya! formato de imagen no soportado!
                            </p>
                        </center>
                    </div>
                    <div
                        class="row"
                        style="margin-right: 0px; margin-left: 0px"
                    >
                        <div class="col-12" style="padding: 5px">
                            <div class="container">
                                <button
                                    @click="closeModalImage()"
                                    class="form-control btn btn-danger"
                                    style="
                                        margin-top: 8.8%;
                                        background-color: #df438b;
                                        border-color: #df438b;
                                    "
                                >
                                    Entendido!
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </vue-modal>
        <!-- end image error modal -->
        <!-- start load modal -->
        <vue-modal ref="modalLoad" title="" :showclosed="false">
            <template v-slot:header> </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <center>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="94"
                            height="94"
                            viewBox="0 0 94 94"
                        >
                            <g
                                id="Group_2373"
                                data-name="Group 2373"
                                transform="translate(-5834.694 -10263.994)"
                            >
                                <path
                                    id="Path_4721"
                                    data-name="Path 4721"
                                    d="M18,3V20.6"
                                    transform="translate(5863.694 10263.994)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4722"
                                    data-name="Path 4722"
                                    d="M18,27V44.6"
                                    transform="translate(5863.694 10310.395)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4723"
                                    data-name="Path 4723"
                                    d="M7.4,7.4,19.847,19.847"
                                    transform="translate(5843.191 10272.491)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4724"
                                    data-name="Path 4724"
                                    d="M24.36,24.36,36.812,36.812"
                                    transform="translate(5875.99 10305.29)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4725"
                                    data-name="Path 4725"
                                    d="M3,18H20.6"
                                    transform="translate(5834.694 10292.994)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4726"
                                    data-name="Path 4726"
                                    d="M27,18H44.6"
                                    transform="translate(5881.094 10292.994)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4727"
                                    data-name="Path 4727"
                                    d="M7.4,36.812,19.847,24.36"
                                    transform="translate(5843.191 10305.29)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4728"
                                    data-name="Path 4728"
                                    d="M24.36,19.847,36.812,7.4"
                                    transform="translate(5875.99 10272.491)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                            </g>
                        </svg>

                        <h3>Espere, por favor...</h3>
                    </center>
                </div>
            </template>
        </vue-modal>
        <!-- end load modal -->
        <!-- start error modal -->

        <vue-modal ref="generalError" title="">
            <template v-slot:header> </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <div class="container">
                        <center>
                            <p
                                style="
                                    margin-top: 8px;
                                    font-size: 20px;
                                    font-weight: bold;
                                "
                            >
                                 Vaya! ha sucedido un error!
                            </p>
                            <p
                                style="
                                    margin-top: 8px;
                                    font-size: 20px;
                                    font-weight: bold;
                                "
                            >
                                Si el error persiste, contacte con soporte dronautic@gmail.com
                            </p>
                        </center>
                    </div>
                    <div
                        class="row"
                        style="margin-right: 0px; margin-left: 0px"
                    >
                        <div class="col-12" style="padding: 5px">
                            <div class="container">
                                <button
                                    @click="closeErrorModal()"
                                    class="form-control btn btn-danger"
                                    style="
                                        margin-top: 8.8%;
                                        background-color: #df438b;
                                        border-color: #df438b;
                                    "
                                >
                                    Entendido!
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </vue-modal>

        <!-- end error modal -->

        <!-- start confirmation modal -->

        <vue-modal ref="confirmation" title="">
            <template v-slot:header> </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <div class="container">
                        <center>
                            <p
                                style="
                                    margin-top: 8px;
                                    font-size: 20px;
                                    font-weight: bold;
                                "
                            >
                                Se ha guardado el elemento!
                            </p>
                        </center>
                    </div>
                    <div
                        class="row"
                        style="margin-right: 0px; margin-left: 0px"
                    >
                        <div class="col-12" style="padding: 5px">
                            <div class="container">
                                <button
                                    @click="closeConfModal()"
                                    class="form-control btn btn-danger"
                                    style="
                                        margin-top: 8.8%;
                                        background-color: #df438b;
                                        border-color: #df438b;
                                    "
                                >
                                    Entendido!
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </vue-modal>

        <!-- end confirmation modal -->

        <!-- Image previewModal -->
        <div
            class="modal fade"
            tabindex="-1"
            :class="carrousel_name"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Imagen actual
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <center>
                            <img
                                :src="urlSrc"
                                class="card-img-center"
                                style="
                                    max-height: 390px;
                                    width: auto;
                                    max-width: 100%;
                                "
                            />
                        </center>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                        >
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["title", "carrousel_name", "carr", "carrusel"],
    data() {
        var cr = this.carrusel;
        return {
            form: {
                carrousel_image: cr.src,
                title: cr.title,
                message: cr.message,
                btn_flag: cr.btn_status=='true'?true:false,
                button_text: cr.button_text,
                url: cr.button_url,
            },
            validation: {
                carrousel_image: false,
                title: false,
                message: false,
                button_text: false,
                url: false,
            },
            errorMessages: {
                generalError: "Vaya! este campo es requerido!",
            },
            modal: 0,
            urlSrc: "",
        };
    },
    methods: {
        activateButton() {
            this.form.btn_flag = !this.form.btn_flag;
        },
        CarrouselImage(e) {
            this.form.carrousel_image = e.target.files[0];
        },
        //?close error modal
        closeErrorModal() {
            this.$refs.generalError.close();
        },
        closeConfModal() {
            this.$refs.confirmation.close();
        },
        //?close modal
        closeModalImage() {
            this.$refs.imageError.close();
        },
        emptyValidation() {
            var vl = this.validation;
            var frm = this.form;
            if (frm.title == "" || frm.title == undefined) {
                if (!vl.title) vl.title = !vl.title;
            } else {
                if (vl.title) vl.title = !vl.title;
            }
            if (frm.message == "" || frm.message == undefined) {
                if (!vl.message) vl.message = !vl.message;
            } else {
                if (vl.message) vl.message = !vl.message;
            }
            if (frm.button_text == "" || frm.button_text == undefined) {
                if (frm.btn_flag == true) {
                    if (!vl.button_text) vl.button_text = !vl.button_text;
                }
            } else {
                if (vl.button_text) vl.button_text = !vl.button_text;
            }
            if (frm.url == "" || frm.url == undefined) {
                if (frm.btn_flag == true) {
                    if (!vl.url) vl.url = !vl.url;
                }
            } else {
                if (vl.url) vl.url = !vl.url;
            }
            if (
                frm.carrousel_image == null ||
                frm.carrousel_image == undefined
            ) {
                if (!vl.carrousel_image)
                    vl.carrousel_image = !vl.carrousel_image;
            } else {
                if (vl.carrousel_image)
                    vl.carrousel_image = !vl.carrousel_image;
            }
        },
        saveCarrousel(e) {
            var vl = this.validation;
            var frm = this.form;
            e.preventDefault();
            this.emptyValidation();
            if (
                !vl.carrousel_image &&
                !vl.title &&
                !vl.message &&
                !vl.button_text &&
                !vl.url
            ) {
                this.$refs.modalLoad.open();
                if (this.validImagenFile(frm.carrousel_image)) {
                    const data = new FormData();
                    data.append("name", this.carrousel_name);
                    data.append("title", frm.title);
                    data.append("message", frm.message);
                    data.append("btn_flag", frm.btn_flag);
                    data.append("button_text", frm.button_text);
                    data.append("url", frm.url);
                    data.append("carrousel_image", frm.carrousel_image);
                    //?axios request
                    axios
                        .post("/administrador/carrousel", data)
                        .then((res) => {
                            //*error validation
                            if (res.data.message == "error") {
                                console.log(res.data.errorMessage);
                                this.$refs.generalError.open();
                                this.$refs.modalLoad.close();
                            } else {
                                this.$refs.modalLoad.close();
                                this.$refs.confirmation.open();
                            }
                        })
                        .catch(function (err) {
                            console.log(err);
                        });
                } else {
                    this.$refs.modalLoad.close();
                    this.$refs.imageError.open();
                }
            }
        },
        setUrlImage(url) {
            this.urlSrc = url;
            $("."+this.carrousel_name).modal("show");
        },
        //*valid imagen validation
        validImagenFile(imageFile) {
            if (imageFile != null) {
                if (!/\.(svg|jpg|png|gif)$/i.test(imageFile.name)) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        },
    },
};
</script>

<style>
.mostrar {
    display: list-item;
    opacity: 1;
    background: #d877a4b6;
    padding-top: 15%;
}
</style>
