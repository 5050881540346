class StoreFilters {

    constructor(target, applyBtn, uri, selected) {
        this.target = target
        this.applyBtn = applyBtn
        this.uri = uri
        this.filters = selected.filters.split('__') || []
        this.categories = selected.categories.split('__') || []
        this.price = selected.price || ''
        this.init()
    }

    init() {
        let self = this
        $(`${this.target}`).each(function (index, el) {
            let name = $(el).attr('name')
            $(el).prop('checked', self[name].includes($(el).val()))
        })
        $('body').on('change', this.target, this.toggleFilter.bind(this))
        $(this.applyBtn).on('click', this.apply.bind(this))
    }

    toggleFilter(event) {
        $(this.applyBtn).parent().removeClass('d-none');
        let $checkbox = $(event.target)
        let type = $checkbox.attr('name')
        let val = $checkbox.val()
        if(type != 'price') {
            if($checkbox.is(':checked')) {
                this[type].push(val)
            } else {
                let index = this[type].indexOf(val)
                this[type].splice(index, 1)
            }
        } else {
            this[type] = val
        }

        console.log(this.filters, this.categories, this.price)
    }

    apply(event) {
        let uri = this.uri
            uri = `${uri}/${this.categories.join('__') || 'todas'}`

        if(this.filters.length) {
            uri = `${uri}/${this.filters.join('__')}`
        }

        if(this.price) {
            uri = `${uri}?price=${this.price}`
        }

        window.location.href = `${window.location.origin}/${uri}`
    }


}
window.StoreFilters = StoreFilters


$(window).on('load', function(){

     // filtersPanel.init()
     // $('body').on('change', '[data-filter]', function(event) {
     //     $('#btnApplyFilters').removeClass('d-none');
     //     let $checkbox = $(this)
     //     let type = $checkbox.attr('name')
     //     let val = $checkbox.val()
     //
     //     if($checkbox.is(':checked')) {
     //         filters[type].push(val)
     //     } else {
     //         let index = filters[type].indexOf(val)
     //         filters[type].splice(index, 1)
     //     }
     //
     //     console.log(filters)
     // })
})
