<script>
import { mapActions, mapGetters } from 'vuex'
import VueModal from './VueModal.vue'
export default{
    components: {
        VueModal
    },
    props: ['orden'],
    data() {
        return {
        }
    },
    methods:{
        cancelarEliminar(){
            this.$refs.modalEliminar.close();
        },
        back(){
            window.history.back()
        },
        async elminarOrden(){
            var vm=this;
            try {
                var orden={
                    id:vm.orden.id
                }
                console.log('eliminar')
                vm.$refs.modalEliminar.close();
                vm.$refs.modalLoad.open();
                await vm.deleteOrden(orden);
                vm.$refs.modalLoad.close();
                window.location.href='/administrador/ordenes'
            } catch (error) {
                vm.$refs.modalLoad.close();
            }
        },
        showAlerEliminar(){
            //console.log(this.orden)
            this.$refs.modalEliminar.open();
        },
        ...mapActions({
            showLoading:'showLoading',
            hiddenLoading:'hiddenLoading',
            deleteOrden:'deleteOrden'
        }),
    },
    mounted(){
    }
}
</script>

<template>
    <div style="padding:10px">
        <svg style="cursor:pointer" @click="back()" id="Component_42_1" data-name="Component 42 – 1" xmlns="http://www.w3.org/2000/svg" width="150" height="54" viewBox="0 0 150 54">
            <rect id="Rectangle_1890" data-name="Rectangle 1890" width="150" height="54" rx="27" fill="#df438b"/>
            <g id="Group_1872" data-name="Group 1872" transform="translate(-295 -148)">
                <g id="Icon_feather-arrow-left" data-name="Icon feather-arrow-left" transform="translate(313.811 156.911)">
                <path id="Path_4585" data-name="Path 4585" d="M29.5,18H7.5" transform="translate(0 0.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                <path id="Path_4586" data-name="Path 4586" d="M18.5,29.5l-11-11,11-11" transform="translate(0 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                </g>
                <text id="Regresar" transform="translate(354 180)" fill="#fff" font-size="16" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="0" y="0">Regresar</tspan></text>
            </g>
        </svg>
        <div class="row" style="margin-top:60px">
            <div class="col-6" style="color:#191919;font-size:25px;font-weight: bold;">
                Detalles de la orden #00{{orden.id.toString().padStart(5,'0')}}
            </div>
            <div class="col-6" style="text-aling:right;align-items: end;display: flex;justify-content: end;">
                <span>Total de la orden: <span style="color:#191919;font-size:20px;font-weight: bold;">${{parseFloat(orden.total)}}</span></span>&nbsp;&nbsp;&nbsp;
                <svg @click="showAlerEliminar()" style="cursor:pointer" xmlns="http://www.w3.org/2000/svg" width="183" height="32" viewBox="0 0 183 32">
                    <g id="Group_1499" data-name="Group 1499" transform="translate(-1693 -628)">
                        <g id="Group_1438" data-name="Group 1438" transform="translate(1722 628)">
                        <rect id="Rectangle_10" data-name="Rectangle 10" width="183" height="32" rx="6" transform="translate(-29)" fill="#d10043"/>
                        <text id="CANCELAR_ORDEN" data-name="CANCELAR ORDEN" transform="translate(11 21)" fill="#fff" font-size="14" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="0" y="0">CANCELAR ORDEN</tspan></text>
                        </g>
                        <g id="Icon_feather-eye" data-name="Icon feather-eye" transform="translate(1701.6 629)">
                        <g id="Icon_feather-trash" data-name="Icon feather-trash" transform="translate(-0.417 1.065)">
                            <path id="Path_4565" data-name="Path 4565" d="M4.5,9H24.184" transform="translate(0 -1.626)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            <path id="Path_4566" data-name="Path 4566" d="M22.81,7.374v15.31a2.187,2.187,0,0,1-2.187,2.187H9.687A2.187,2.187,0,0,1,7.5,22.684V7.374m3.281,0V5.187A2.187,2.187,0,0,1,12.968,3h4.374a2.187,2.187,0,0,1,2.187,2.187V7.374" transform="translate(-0.813)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        </g>
                        </g>
                    </g>
                </svg>
            </div>
            <div class="card" style="margin-top:20px;width:100%;padding:10px">
                <div class="row" >
                    <div class="col-4">
                        <h6 style="color:#DF438B">Persona que hace la compra</h6>
                        <b>Email:</b> {{orden.email}}<br>
                        <b>Telefono:</b> {{orden.phone_number}}<br>
                        <b>Autoriza contactarle por WhatsApp:</b> {{orden.want_contact==0?'No':'Si'}}<br>
                        <b>Mantener actualizado con ofertas:</b> {{orden.subscribe==0?'No':'Si'}}<br>
                    </div>
                    <div class="col-4">
                        <h6 style="color:#DF438B">Persona que recibe</h6>
                        <b>Nombres:</b> {{orden.orderreceptor.first_name}}<br>
                        <b>Apellidos:</b> {{orden.orderreceptor.last_name}}<br>
                        <b>Nombre de la empresa:</b> {{orden.location_name==null?'N/A':orden.location_name}}<br>
                        <b>Teléfono móvil:</b> {{orden.phone_number}}<br>
                    </div>
                    <div class="col-4">
                        <h6 style="color:#DF438B">Mensaje impreso en la tarjeta</h6>
                        <b>De:</b> {{orden.from}}<br>
                        <b>Para:</b> {{orden.to}}<br>
                        <b>Mensaje:</b> {{orden.message}}<br>
                    </div>

                </div>
                <div class="row" style="margin-top: 45px;">
                    <div class="col-4">
                        <h6 style="color:#DF438B">Tipo de pago</h6>
                        <b>Tarjeta:</b> {{orden.typepay==1?"Si":"N/A"}}<br>
                        <b>Efectivo:</b> {{orden.typepay==2?"Si":"N/A"}}<br>
                        <b>Transferencia:</b> {{orden.typepay==3 || orden.typepay==4?'Si':'N/A'}}<br>
                        <b>Estado del Pago:</b> {{orden.payment_status || 'N/A'}}<br>
                    </div>
                    <div class="col-4">
                        <h6 style="color:#DF438B">Dirección de envío</h6>
                         {{
                                    orden.orderreceptor.municipalities
                                        .department.name
                                }},
                                {{ orden.orderreceptor.municipalities.name }},
                                {{ orden.orderreceptor.zones.name }},
                                {{ orden.orderreceptor.address }}
                        <br />
                        <h6 style="color:#DF438B">Fecha recoger tienda / entrega</h6>
                        {{ orden.orderreceptor.date || 'N/A' }} {{ orden.orderreceptor.time || '' }}
                    </div>
                    <div class="col-4">
                        <h6 style="color:#DF438B">Costo por envío</h6>
                        ${{orden.envio| numeral('0,0.00')}}
                    </div>
                </div>
            </div>
            <div class="card" style="margin-top:20px;width:100%;padding:10px">
                <div class="row" style="margin-top: 45px;">
                    <div class="col-12">
                        <table class="table table-bordered">
                            <tr>
                                <th style="text-align:center"><b>Producto</b></th>
                                <th style="text-align:center"><b>Detalle</b></th>
                                <th style="text-align:center"><b>Descripcion</b></th>
                                <th style="text-align:center"><b>Cantidad</b></th>
                                <th style="text-align:center"><b>Precio</b></th>
                            </tr>
                            <tr v-for="(item, index) in orden.items" :key="`item_${index}_${item.id}`">
                                <td>
                                    <center>
                                        <div class="extra__photo">
                                            <img :src="item.product.thumbnail" alt="">
                                        </div>
                                    </center>
                                </td>
                                <td style="text-align:center">{{item.product.name}}</td>
                                <td style="text-align:center">{{item.product.special}}</td>
                                <td style="text-align:center">{{item.qty}}</td>
                                <td style="text-align:center">${{item.price| numeral('0,0.00')}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <vue-modal ref="modalEliminar" title="">
            <template v-slot:header>
            </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <center>
                        <svg xmlns="http://www.w3.org/2000/svg" width="81.945" height="90.384" viewBox="0 0 81.945 90.384">
                            <g id="Icon_feather-trash-2" data-name="Icon feather-trash-2" transform="translate(3 3)">
                                <path id="Path_4732" data-name="Path 4732" d="M4.5,9H80.445" transform="translate(-4.5 7.877)" fill="none" stroke="#d10043" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4733" data-name="Path 4733" d="M66.569,19.877V78.945a8.438,8.438,0,0,1-8.438,8.438H15.938A8.438,8.438,0,0,1,7.5,78.945V19.877m12.658,0V11.438A8.438,8.438,0,0,1,28.6,3H45.473a8.438,8.438,0,0,1,8.438,8.438v8.438" transform="translate(0.938 -3)" fill="none" stroke="#d10043" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4734" data-name="Path 4734" d="M15,16.5V41.815" transform="translate(14.534 21.473)" fill="none" stroke="#d10043" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4735" data-name="Path 4735" d="M21,16.5V41.815" transform="translate(25.411 21.473)" fill="none" stroke="#d10043" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                            </g>
                        </svg>
                        <br>
                        <p style="margin-top:8px;font-size:20px;font-weight: bold;">¿Está seguro de eliminar <br>esta orden?</p>
                    </center>
                    <div class="row" style="margin-right: 0px;margin-left: 0px">
                        <div class="col-6" style="padding: 0px;">
                            <svg style="cursor:pointer"  @click="cancelarEliminar()" id="Group_2419" data-name="Group 2419" xmlns="http://www.w3.org/2000/svg" width="150" height="60" viewBox="0 0 150 60">
                                <g id="Rectangle_2020" data-name="Rectangle 2020" fill="#fff" stroke="#df438b" stroke-width="1">
                                    <path d="M0,0H150a0,0,0,0,1,0,0V60a0,0,0,0,1,0,0H10A10,10,0,0,1,0,50V0A0,0,0,0,1,0,0Z" stroke="none"/>
                                    <path d="M1,.5H149a.5.5,0,0,1,.5.5V59a.5.5,0,0,1-.5.5H10A9.5,9.5,0,0,1,.5,50V1A.5.5,0,0,1,1,.5Z" fill="none"/>
                                </g>
                                <text id="Cancelar" transform="translate(75 35)" fill="#df438b" font-size="19" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="-38.079" y="0">Cancelar</tspan></text>
                            </svg>
                        </div>
                        <div class="col-6" style="padding: 0px;">
                            <svg @click="elminarOrden()" style="cursor:pointer" id="Group_2364" data-name="Group 2364" xmlns="http://www.w3.org/2000/svg" width="150" height="60" viewBox="0 0 150 60">
                                <path id="Rectangle_2020" data-name="Rectangle 2020" d="M0,0H150a0,0,0,0,1,0,0V50a10,10,0,0,1-10,10H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" fill="#df438b"/>
                                <text id="Si_eliminar" data-name="Si, eliminar" transform="translate(73 35)" fill="#fff" font-size="18" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="-47.18" y="0">Si, eliminar</tspan></text>
                            </svg>
                        </div>
                    </div>
                </div>
            </template>
        </vue-modal>
        <vue-modal ref="modalLoad" title="" :showclosed="false">
            <template v-slot:header>
            </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <center>
                        <svg xmlns="http://www.w3.org/2000/svg" width="94" height="94" viewBox="0 0 94 94">
                            <g id="Group_2373" data-name="Group 2373" transform="translate(-5834.694 -10263.994)">
                                <path id="Path_4721" data-name="Path 4721" d="M18,3V20.6" transform="translate(5863.694 10263.994)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4722" data-name="Path 4722" d="M18,27V44.6" transform="translate(5863.694 10310.395)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4723" data-name="Path 4723" d="M7.4,7.4,19.847,19.847" transform="translate(5843.191 10272.491)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4724" data-name="Path 4724" d="M24.36,24.36,36.812,36.812" transform="translate(5875.99 10305.29)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4725" data-name="Path 4725" d="M3,18H20.6" transform="translate(5834.694 10292.994)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4726" data-name="Path 4726" d="M27,18H44.6" transform="translate(5881.094 10292.994)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4727" data-name="Path 4727" d="M7.4,36.812,19.847,24.36" transform="translate(5843.191 10305.29)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4728" data-name="Path 4728" d="M24.36,19.847,36.812,7.4" transform="translate(5875.99 10272.491)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                            </g>
                        </svg>

                        <h3>Espere, por favor...</h3>
                    </center>
                </div>
            </template>
        </vue-modal>
    </div>
</template>
