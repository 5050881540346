<script>
import { mapActions, mapGetters } from 'vuex'
import VueModal from './VueModal.vue'
export default{
    components: {
        VueModal
    },
    data() {
        return {
            errors: {
                name:false,
                date1:false,
                date2:false,
                code:false,
                discount:false,
                is_percentage:false,
                uses:false,
                limit:false
            },
            form:{
                name:'',
                date1:'',
                date2:'',
                code:'',
                discount:'',
                percentage:'',
                is_percentage:false,
                uses:'',
                limit:false
            },
            messages:'',
            messagesSuccess:''
        }
    },
    methods:{
        async addCoupons(){
            var vm=this;
            try {
                vm.clean();
                if(vm.form.name==''){
                    vm.messages='Campo Nombre Promocion<br> Requerido'
                    vm.$refs.modalvalid.open();
                    return;
                }
                if(vm.form.date1==''){
                    vm.messages='Fecha Inicio Requerido'
                    vm.$refs.modalvalid.open();
                    vm.errors.date1=true;
                    return;
                }
                if(vm.form.date2==''){
                    vm.messages='Fecha Final Requerido'
                    vm.$refs.modalvalid.open();
                    vm.errors.date2=true;
                    return;
                }
                if(vm.form.code==''){
                    vm.messages='Campo Coupon Requerido'
                    vm.$refs.modalvalid.open();
                    vm.errors.code=true;
                    return;
                }
                if(vm.form.discount!='' && vm.form.percentage!=''){
                    vm.messages='Los campos de descuento % y $<br> no deben ir llenos al <br>mismo tiempo'
                    vm.$refs.modalvalid.open();
                    return;
                }
                if(vm.form.uses==''){
                    vm.messages='Campo Usos Requerido'
                    vm.$refs.modalvalid.open();
                    vm.errors.uses=true;
                    return;
                }
                if(vm.form.discount=='' && vm.form.percentage!=''){
                    vm.form.is_percentage=true;
                }
                else{
                    vm.form.is_percentage=false;
                }
                vm.$refs.modalLoad.open();
                let {data} = await window.axios.post(`/administrador/coupones/add`,vm.form)
                vm.$refs.modalLoad.close();
                vm.messagesSuccess="Los cambios se han <br>guardado exitosamente"
                vm.$refs.modalsuccess.open();
                vm.cleanForm();
            } catch (error) {
                vm.$refs.modalLoad.close();
                console.log(error);
            }
        },
        closeValid(){
            this.$refs.modalvalid.close();
        },
        cleanForm(){
            this.form={
                name:'',
                date1:'',
                date2:'',
                code:'',
                discount:'',
                percentage:'',
                is_percentage:false,
                uses:'',
                limit:false
            }
        },
        closeSuccess(){
            this.$refs.modalsuccess.close();
        },
        generarCode(){
            var caracteres = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
            var code = "";
            for (var i=0; i<6; i++) 
            {
                code +=caracteres.charAt(Math.floor(Math.random()*caracteres.length)); 
            }

            this.form.code=code;

        },
        clean(){
             this.errors={
                name:false,
                date1:false,
                date2:false,
                code:false,
                discount:false,
                is_percentage:false,
                uses:false,
                limit:false
            }
        }
    },
    mounted(){
    }
}
</script>

<template>
    <div class="row" style="width:100%;margin-top: 80px;">
        <div class="col-12 row">
            <div class="col-12 py-2 px-2">
                <label for="" style="color:#DF438B"><b>Nombre de promoción</b></label>
                <input class="form-control"  :class="errors.name ? 'is-invalid' : ''" type="text"  v-model="form.name">
            </div>
            <div class="col-6 py-2 px-2">
                <label for="" style="color:#DF438B"><b>Fecha inicio</b></label>
                <input class="form-control"  :class="errors.date1 ? 'is-invalid' : ''" type="date"  v-model="form.date1">
            </div>
            <div class="col-6 py-2 px-2">
                <label for="" style="color:#DF438B"><b>Fecha final</b></label>
                <input class="form-control"  :class="errors.date2 ? 'is-invalid' : ''" type="date"  v-model="form.date2">
            </div>
            <div class="col-12 py-2 px-2">
                <div class="row">
                    <div class="col-2">
                        <label for="" style="color:#DF438B"><b>Cupón</b></label>  
                    </div>
                    <div class="col-10" style="display: flex;justify-content: end;">
                        <label for=""><b><a @click="generarCode()" style="color:#DF438B">Generar automáticamente</a></b></label>  
                    </div>
                </div>
                <input class="form-control"  :class="errors.code ? 'is-invalid' : ''" type="text"  v-model="form.code">
            </div>
            <div class="col-6 py-2 px-2">
                <label for="" style="color:#DF438B"><b>Descuento (%)</b></label>
                <input class="form-control"  :class="errors.percentage ? 'is-invalid' : ''" type="number"  v-model="form.percentage">
            </div>
            <div class="col-6 py-2 px-2">
                <label for="" style="color:#DF438B"><b>Descuento ($)</b></label>
                <input class="form-control"  :class="errors.discount ? 'is-invalid' : ''" type="number"  v-model="form.discount">
            </div>
            <div class="col-6 py-2 px-2">
                <label for="" style="color:#DF438B"><b>Usos máximos</b></label>
                <input class="form-control"  :class="errors.uses ? 'is-invalid' : ''" type="number"  v-model="form.uses">
            </div>
            <div class="col-6 py-2 px-2" style="display: flex;justify-content: center;align-items: end;">
                <label class="input-checkbox" style="display: flex;justify-content: center;align-items: end;">
                    <input type="checkbox" v-model="form.limit" >
                    <span class="input" style="width: 30px;height: 30px;    line-height: 30px;">
                        <span class="icon icon-check"></span>
                    </span>
                    <span class="ml-2 small" style="font-size: 20px;">Usos ilimitados</span>
                </label>
            </div>
            <div class="col-12">
                <button type="button" @click="addCoupons()" style="background-color: #DF438B" class="btn btn-primary btn-lg btn-block">Crear cupón</button>
            </div>
        </div>
        <vue-modal ref="modalLoad" title="" :showclosed="false">
            <template v-slot:header>
            </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <center>
                        <svg xmlns="http://www.w3.org/2000/svg" width="94" height="94" viewBox="0 0 94 94">
                            <g id="Group_2373" data-name="Group 2373" transform="translate(-5834.694 -10263.994)">
                                <path id="Path_4721" data-name="Path 4721" d="M18,3V20.6" transform="translate(5863.694 10263.994)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4722" data-name="Path 4722" d="M18,27V44.6" transform="translate(5863.694 10310.395)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4723" data-name="Path 4723" d="M7.4,7.4,19.847,19.847" transform="translate(5843.191 10272.491)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4724" data-name="Path 4724" d="M24.36,24.36,36.812,36.812" transform="translate(5875.99 10305.29)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4725" data-name="Path 4725" d="M3,18H20.6" transform="translate(5834.694 10292.994)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4726" data-name="Path 4726" d="M27,18H44.6" transform="translate(5881.094 10292.994)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4727" data-name="Path 4727" d="M7.4,36.812,19.847,24.36" transform="translate(5843.191 10305.29)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4728" data-name="Path 4728" d="M24.36,19.847,36.812,7.4" transform="translate(5875.99 10272.491)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                            </g>
                        </svg>

                        <h3>Espere, por favor...</h3>
                    </center>
                </div>
            </template>
        </vue-modal>
        <vue-modal ref="modalvalid" title="" :showclosed="false">
            <template v-slot:header>
            </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <center>
                        <svg xmlns="http://www.w3.org/2000/svg" width="90.346" height="90.346" viewBox="0 0 90.346 90.346">
                        <path id="Icon_material-error-outline" data-name="Icon material-error-outline" d="M43.656,61.725H52.69v9.035H43.656Zm0-36.138H52.69v27.1H43.656ZM48.128,3A45.173,45.173,0,1,0,93.346,48.173,45.151,45.151,0,0,0,48.128,3Zm.045,81.311A36.138,36.138,0,1,1,84.311,48.173,36.128,36.128,0,0,1,48.173,84.311Z" transform="translate(-3 -3)" fill="#d10043"/>
                        </svg>
                        <p style="margin-top:8px;font-size:20px;font-weight: bold;" v-html="messages"></p>
                    </center>
                    <div class="row" style="margin-right: 0px;margin-left: 0px">
                        <div class="col-12" style="padding: 0px;">
                            <svg style="cursor:pointer" @click="closeValid()" xmlns="http://www.w3.org/2000/svg" width="316" height="60" viewBox="0 0 316 60">
                                <g id="Group_2368" data-name="Group 2368" transform="translate(-802 -633)">
                                    <path id="Rectangle_2020" data-name="Rectangle 2020" d="M0,0H316a0,0,0,0,1,0,0V50a10,10,0,0,1-10,10H10A10,10,0,0,1,0,50V0A0,0,0,0,1,0,0Z" transform="translate(802 633)" fill="#df438b"/>
                                    <text id="Aceptar" transform="translate(960 669)" fill="#fff" font-size="20" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="-36.694" y="0">Aceptar</tspan></text>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </template>
        </vue-modal>
        <vue-modal ref="modalsuccess" title="" :showclosed="false">
            <template v-slot:header>
            </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <center>
                        <svg xmlns="http://www.w3.org/2000/svg" width="91.287" height="90.147" viewBox="0 0 91.287 90.147">
                            <g id="Icon_feather-check-circle" data-name="Icon feather-check-circle" transform="translate(0.008 0.058)">
                                <path id="Path_4715" data-name="Path 4715" d="M87.036,41.169v3.866A42.018,42.018,0,1,1,62.12,6.63" transform="translate(0 0)" fill="none" stroke="#05ff8a" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4716" data-name="Path 4716" d="M68.124,6,26.105,48.06,13.5,35.455" transform="translate(18.913 5.42)" fill="none" stroke="#05ff8a" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                            </g>
                        </svg>
                        <p style="margin-top:8px;font-size:20px;font-weight: bold;" v-html="messagesSuccess"></p>
                    </center>
                    <div class="row" style="margin-right: 0px;margin-left: 0px">
                        <div class="col-12" style="padding: 0px;">
                            <svg style="cursor:pointer" @click="closeSuccess()" xmlns="http://www.w3.org/2000/svg" width="316" height="60" viewBox="0 0 316 60">
                                <g id="Group_2368" data-name="Group 2368" transform="translate(-802 -633)">
                                    <path id="Rectangle_2020" data-name="Rectangle 2020" d="M0,0H316a0,0,0,0,1,0,0V50a10,10,0,0,1-10,10H10A10,10,0,0,1,0,50V0A0,0,0,0,1,0,0Z" transform="translate(802 633)" fill="#df438b"/>
                                    <text id="Aceptar" transform="translate(960 669)" fill="#fff" font-size="20" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="-36.694" y="0">Aceptar</tspan></text>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </template>
        </vue-modal>
    </div>
</template>