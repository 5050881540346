// Dependencias de terceros
import Vue from 'vue'
import VModal from 'vue-js-modal'
import store from './store'
import vueNumeralFilterInstaller from 'vue-numeral-filter'
import Select2 from 'v-select2-component'
import 'bootstrap/dist/js/bootstrap.min.js'
import VueGtag from 'vue-gtag'

// Archivos personalizados
import './bootstrap'
import './ajax'
import './filters'

// Importa jQuery si es necesario
import $ from 'jquery'
window.$ = window.jQuery = $

// Registra los componentes y plugins de Vue
Vue.use(VModal)
Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' })
Vue.component('Select2', Select2)

Vue.use(VueGtag, {config: { id: 'G-7B7PXX64TY' }});// TODO: el ID de google analytics se debe agregar por variables de entorno.

const files = require.context('./components', true, /\.vue$/i)
files.keys().map(key =>
    Vue.component(
        key
            .split('/')
            .pop()
            .split('.')[0],
        files(key).default
    )
)

const app = new Vue({
    el: '#app',
    store,
})

function share_fb(e) {
    e.preventDefault()
    let url = e.target.dataset.shareUrl
    window.open(
        'https://www.facebook.com/sharer/sharer.php?u=' + url,
        'facebook-share-dialog',
        'width=626, height=436'
    )
}

document.querySelectorAll('.share-on-fb').forEach(element => {
    element.addEventListener('click', share_fb)
})
