<script>
export default {
    props: ['title', 'content','showclosed'],
    data() {
        return {
            show: false,
            closing: false,
            timer: null,
        }
    },
    methods: {
        open() {
            if(this.timer) {
                clearInterval(this.timer)
            }
            this.closing = false
            this.show = true
            return Promise.resolve(true);
        },

        close() {
            this.closing = true
            this.timer = setTimeout(() => {
                this.show = false
                this.closing = false
            }, 1000)
            // this.show = false
        },

    },

    mounted() {
        // debugger
        // this.$root.$el.append(this.$el);
    },
}
</script>
<template>
    <div v-if="show" class="v-modal" :class="{'v-modal--closing': closing}">
        <div class="v-modal__wrap">
            <header class="v-modal__header">
                <slot name="header">
                    {{title}}
                    <button v-if="showclosed" class="v-modal__close" @click="close">
                        <span class="icon-close"></span>
                    </button>
                </slot>
            </header>
            <div class="v-modal__body">
                <slot name="body">
                    <div v-html="content"></div>
                </slot>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .v-modal{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(4, 15, 59, 0.7);
        z-index: 10000;
        backdrop-filter: blur(2px);
        display: flex;
        animation: modalFadeIn 0.1s forwards;
        padding: 1rem;
        &__wrap{
            margin: auto;
            background-color: #fff;
            min-width: 300px;
            max-width: 800px;
            border-radius: 0.5rem;
            box-shadow:  0 0 3px rgba(4, 15, 59, 0.7);
            animation: slideFadeUp 0.3s forwards;
            opacity: 0;
            animation-delay: 0.1s;
        }
        &--closing{
            animation: modalFadeOut 0.1s forwards;
            animation-delay: 0.3s;

            .v-modal{
                &__wrap{
                    animation: slideFadeDown 0.3s forwards;
                    animation-delay: 0;
                }
            }
        }
        &__header{
            padding: 1rem;
            font-size: 1.5rem;
            border-bottom: 1px solid #eee;
            display: flex;
        }
        &__body{
            padding: 1rem;
            max-height: 50vh;
            overflow: auto;
        }
        &__close{
            background-color: #fff;
            border-radius: 50%;
            display: inline-block;
            border: 0 none;
            width: 2rem;
            height: 2rem;
            font-size: 1.5rem;
            margin-left: auto;
            padding: 0;
            line-height: 2rem;
            text-align: center;
            .si{
                color: rgb(133, 133, 133);
            }
            &:hover{
                .si{
                    color: #000;
                }
            }
        }
    }

    @keyframes modalFadeIn {
        0%{
            opacity: 0;
        } 100%{
            opacity: 1;
        }
    }

  @keyframes modalFadeOut {
        0%{
            opacity: 1;
        } 100%{
            opacity: 0;
        }
    }

    @keyframes slideFadeUp {
        0%{
            opacity: 0;
            transform: translateY(20%);
        } 100%{
            opacity: 1;
            transform: translateY(0);
        }
    }

   @keyframes slideFadeDown {
        0%{
            opacity: 1;
            transform: translateY(0);
        } 100%{
            opacity: 0;
            transform: translateY(20%);
        }
    }
</style>
