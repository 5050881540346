<script>
import { mapActions, mapGetters } from "vuex";
export default {
    props: {
        products: {
            type: Array,
            default() {
                return [];
            },
        },
        step: {
            type: Number,
            default() {
                return 1;
            },
        },
        totalSteps: {
            type: Number,
            default() {
                return 1;
            },
        },
    },
    data() {
        return {
            form: {
                email: "",
                phone_number: "",
                shipping: "",
                receptor_name: "",
                receptor_lastname: "",
                receptor_location_name: "",
                receptor_city_id: "",
                receptor_adress: "",
                receptor_phone_number: "",
                shipping_time_id: "",
                form: "",
                to: "",
                message: "",
                want_contact: false,
                subscribe: "",
            },
        };
    },
    computed: {
        title() {
            if (this.products.length) {
                return this.products[0].type.name;
            }
            return "";
        },
        ...mapGetters({
            cart: "cart",
            inCart: "inCart",
        }),
    },
    methods: {
        debounceUpdateCart: window._.debounce(async function () {
            let products = this.cart.cart;
            try {
                let response = await this.updateCart({ products });
            } catch (error) {}
        }, 2000),
        diminish(p) {
            p.qty -= 1;
            this.debounceUpdateCart();
        },
        increase(p) {
            p.qty += 1;
            this.debounceUpdateCart();
        },

        getInCart(id) {
            return this.inCart(id);
        },
        async handlerAddExtraToCart(product) {
            let _product = product;
            this.loading = true;
            let vm = this;
            // if(!this.date) {
            //     this.errors.date = 'Seleccione fecha'
            //     return
            // }
            product = JSON.stringify(product);
            product = JSON.parse(product);
            product.qty = 1;
            // product.date = vm.date
            try {
                let result = await vm.addToCart(product);
                // _product.saving = false
                // vm.$refs.modalExtras.open()
            } catch (error) {
                console.log(error);
                // _product.saving = false
            }
        },
        ...mapActions({
            getCart: "getCart",
            updateCart: "updateCart",
            removeFromCart: "removeFromCart",
            addToCart: "addToCart",
        }),
    },
    mounted() {
        this.getCart();
    },
};
</script>
<template>
    <div class="custom_product_section">
        <div class="row d-flex">
            <div class="col-12 col-md-7 pb-3 products_list">
                <h3 class="text-center"><b>Personaliza tu detalle</b></h3>
                <h6 class="text-center">Paso {{ step }} de {{ totalSteps }}</h6>
                <!-- Por solicitud del cliente se elimina titulo <h3 class="text-primary m-0 font-weight-bold">{{ title }}</h3>-->
                <div>
                    <!-- <pre>{{products[0]}}</pre> -->
                </div>
                <div class="d-flex flex-wrap" style="min-height:80%;">
                    <div
                        v-for="(product, index) in products"
                        :key="`product_${index}_${product.id}`"
                        class="col-12 col-md-6 col-lg-4 col-xl-2 p-2"
                    >
                        <div class="product_image">
                            <img :src="product.thumbnail" :alt="product.name" />
                            <span
                                v-if="getInCart(product.id)"
                                class="product__added"
                            >
                                <span class="icon-check"></span>
                            </span>
                        </div>

                        <div class="py-2">
                            <h6
                                class="m-0 font-weight-bold"
                                style="
                                    white-space: pre;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                "
                            >
                                {{ product.name }}
                            </h6>
                            <p
                                class="m-0 text-muted"
                                style="
                                    white-space: pre;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                "
                                v-html="product.description"
                            ></p>
                            <p class="font-weight-bold text-primary">
                                ${{ product.price_float }}
                            </p>
                            <div>
                                <button
                                    v-if="!getInCart(product.id)"
                                    class="btn btn-ligth font-weight-bold border-primary text-primary btn-block"
                                    @click="handlerAddExtraToCart(product)"
                                >
                                    Agregar
                                </button>
                                <button
                                    v-else
                                    class="btn btn-ligth font-weight-bold btn-block border"
                                    disabled
                                >
                                    Agregado
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="custom__nav">
                    <slot name="nav"></slot>
                </div>
            </div>
            <div class="col-12 col-md-5">
                <div class="payment__resumen">
                    <div class="payment__products">
                        <div
                            v-for="(product, index) in cart.cart"
                            :key="`extras_${index}_${product.id}`"
                            class="d-flex mb-3 align-items-center"
                        >
                            <div class="col-3">
                                <div
                                    class="d-flex align-items-center"
                                    v-if="product.id != 38"
                                >
                                    <button
                                        class="btn--rounded"
                                        :disabled="product.qty <= 1"
                                        @click="diminish(product)"
                                    >
                                        <span class="icon-menus"></span>
                                    </button>
                                    <div class="px-2">
                                        <input
                                            type="number"
                                            class="form-control text-center border-primary"
                                            v-model="product.qty"
                                        />
                                    </div>
                                    <button
                                        class="btn--rounded"
                                        @click="increase(product)"
                                    >
                                        <span class="icon-plus"></span>
                                    </button>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="d-flex align-items-center">
                                    <div class="extra__photo">
                                        <img :src="product.thumbnail" alt="" />
                                    </div>
                                    <div class="px-1">
                                        <h5 class="m-0 extra__title">
                                            {{ product.name }}
                                        </h5>
                                        <p class="extra__price m-0">
                                            ${{ product.price }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 text-right">
                                <h6 class="m-0">
                                    ${{
                                        (product.price * product.qty)
                                            | numeral("0,0.00")
                                    }}
                                </h6>
                                <button
                                    class="btn btn-ligth btn-sm border-primary text-primary"
                                    @click="removeFromCart(product)"
                                    :disabled="product.saving"
                                >
                                    Remover
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap">
                        <div class="col-12 col-md-10 col-lg-8 mx-auto">
                            <div class="border-top border-bottom py-2">
                                <div class="d-flex">
                                    <div class="flex-grow-1">
                                        <input
                                            class="form-control"
                                            type="text"
                                            placeholder="Tarjeta de regalo o cupón de descuento"
                                        />
                                    </div>
                                    <div class="pl-3">
                                        <button class="btn btn-primary">
                                            Aplicar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap">
                        <div class="col-12 col-md-10 col-lg-8 mx-auto">
                            <div class="border-bottom py-2">
                                <div class="d-flex">
                                    <div>
                                        <p class="m-0">Subtotal</p>
                                    </div>
                                    <div class="flex-grow-1 text-right">
                                        <p class="m-0">
                                            ${{
                                                cart.total | numeral("0,0.00")
                                            }}
                                        </p>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <div>
                                        <p class="m-0">Envío</p>
                                    </div>
                                    <div class="flex-grow-1 text-right">
                                        <p class="m-0">
                                            Calculado al ingresar los datos de
                                            envío
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap">
                        <div class="col-12 col-md-10 col-lg-8 mx-auto">
                            <div class="border-bottom py-2">
                                <div class="d-flex">
                                    <div>
                                        <p class="m-0">Total</p>
                                    </div>
                                    <div class="flex-grow-1 text-right">
                                        <p class="m-0">
                                            USD
                                            <span style="font-size: 1.2rem">
                                                ${{
                                                    cart.total
                                                        | numeral("0,0.00")
                                                }}</span
                                            >
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.product__added {
    position: absolute;
    z-index: 2;
    bottom: 0.5rem;
    right: 0.5rem;
    background-color: #d10043;
    color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    text-align: center;
    line-height: 25px;
    span {
        line-height: 25px;
    }
}
.custom__nav {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
}
.products_list {
    position: relative;
    padding-bottom: 55px;
}
.product_image {
    width: 100%;
    padding-bottom: 100%;
    height: 0;
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;
    img {
        min-width: 100%;
        position: absolute;
        min-height: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }
}
</style>
