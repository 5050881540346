<script>
import { mapActions, mapGetters } from "vuex";
import VueModal from "./VueModal.vue";
export default {
    components: {
        VueModal,
    },
    props: ["products"],
    data() {
        return {
            idsToBeDeleted: [],
            selectedProduct: null,
            messagesSuccess: "Los cambios se han <br>guardado exitosamente",
            multipleDeleteFlag: false,
        };
    },
    methods: {
        showAlertEliminar(_producto) {
            this.selectedProduct = _producto;
            this.$refs.modalEliminar.open();
        },
        hideAlertEliminar() {
            this.$refs.modalEliminar.close();
            this.selectedProduct = null;
        },
        closeSuccess() {
            window.location.reload();
        },
        async elminarProducto() {
            var vm = this;
            if (!vm.multipleDeleteFlag) {
                try {
                    var producto = {
                        id: vm.selectedProduct.id,
                    };
                    console.log("eliminar");
                    vm.$refs.modalEliminar.close();
                    vm.$refs.modalLoad.open();
                    let { data } = await window.axios.post(
                        `/administrador/inventario/delete`,
                        producto
                    );
                    vm.$refs.modalLoad.close();
                    vm.$refs.modalsuccess.open();
                } catch (error) {
                    vm.$refs.modalLoad.close();
                    vm.selectedOrden = null;
                }
            } else {
                console.log(this.idsToBeDeleted);
                try {
                    var producto = {
                        id: vm.idsToBeDeleted,
                    };
                    console.log("eliminar");
                    vm.$refs.modalEliminar.close();
                    vm.$refs.modalLoad.open();
                    let { data } = await window.axios.post(
                        `/administrador/inventario/multdelete`,
                        producto
                    );
                    vm.$refs.modalLoad.close();
                    vm.$refs.modalsuccess.open();
                } catch (error) {
                    vm.$refs.modalLoad.close();
                    vm.selectedOrden = null;
                }
            }
        },
        deleteMultipleProducts() {
            this.multipleDeleteFlag = true;
            this.$refs.modalEliminar.open();
        },
        redirectEdit(id) {
            window.location.href = "/administrador/inventario/" + id;
        },
    },
};
</script>

<template>
    <div class="row">
        <div class="col-12 col-md-12 p-1 p-md-2 p-lg-4 ml-5" v-if="products.length == 0">
            <center>
                <div class="card ml-5">
                    <div class="card-body">
                        <h5>
                            No se encontraron resultados. Probablemente el
                            producto que buscas no ha sido añadido aún al
                            sistema
                        </h5>
                    </div>
                </div>
            </center>
        </div>
        <div
            :hidden="products.length == 0"
            :class="
                products.length >= 4
                    ? 'col-lg-3'
                    : products.length == 3
                    ? 'col-lg-4'
                    : products.length == 2
                    ? 'col-lg-6'
                    :  products.length == 1
                    ? 'col-lg-12'
                    : 'col-lg-12'
            "
            class="col-12 col-md-12 p-1 p-md-2 p-lg-4"
            v-for="(product, index) in products"
            :key="`product_${index}_${product.id}`"
        >
            <article class="product">
                <input
                    type="checkbox"
                    class="form-control"
                    v-model="idsToBeDeleted"
                    :value="product.id"
                    id=""
                    style="width: 10%; transform: scale(0.8)"
                />
                <div class="product__top">
                    <img
                        class="product__img"
                        :src="product.thumbnail"
                        :alt="product.name"
                    />
                    <div class="product__price-from">
                        <div class="product__price">
                            <p class="from">Desde</p>
                            <p class="m-0 price">
                                <span class="small">$</span>{{ product.price }}
                            </p>
                        </div>
                    </div>
                    <!-- <div class="product__occasion">
                            Para San Valentín
                        </div> -->
                </div>
                <div class="product__body">
                    <h2 class="product__title">{{ product.name }}</h2>
                </div>
                <div class="product__footer px-5">
                    <div class="row">
                        <div class="col-6">
                            <a
                                @click="redirectEdit(product.id)"
                                class="btn btn-dark btn-block"
                            >
                                EDITAR
                            </a>
                        </div>
                        <div class="col-6">
                            <a
                                @click="showAlertEliminar(product)"
                                href="#"
                                class="btn btn-primary btn-block"
                            >
                                ELIMINAR
                            </a>
                        </div>
                    </div>
                </div>
            </article>
        </div>
        <vue-modal ref="modalEliminar" title="">
            <template v-slot:header> </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <center>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="81.945"
                            height="90.384"
                            viewBox="0 0 81.945 90.384"
                        >
                            <g
                                id="Icon_feather-trash-2"
                                data-name="Icon feather-trash-2"
                                transform="translate(3 3)"
                            >
                                <path
                                    id="Path_4732"
                                    data-name="Path 4732"
                                    d="M4.5,9H80.445"
                                    transform="translate(-4.5 7.877)"
                                    fill="none"
                                    stroke="#d10043"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4733"
                                    data-name="Path 4733"
                                    d="M66.569,19.877V78.945a8.438,8.438,0,0,1-8.438,8.438H15.938A8.438,8.438,0,0,1,7.5,78.945V19.877m12.658,0V11.438A8.438,8.438,0,0,1,28.6,3H45.473a8.438,8.438,0,0,1,8.438,8.438v8.438"
                                    transform="translate(0.938 -3)"
                                    fill="none"
                                    stroke="#d10043"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4734"
                                    data-name="Path 4734"
                                    d="M15,16.5V41.815"
                                    transform="translate(14.534 21.473)"
                                    fill="none"
                                    stroke="#d10043"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4735"
                                    data-name="Path 4735"
                                    d="M21,16.5V41.815"
                                    transform="translate(25.411 21.473)"
                                    fill="none"
                                    stroke="#d10043"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                            </g>
                        </svg>
                        <br />
                        <p
                            style="
                                margin-top: 8px;
                                font-size: 20px;
                                font-weight: bold;
                            "
                        >
                            ¿Está seguro de eliminar <br />el/los Producto(s)?
                        </p>
                    </center>
                    <div
                        class="row"
                        style="margin-right: 0px; margin-left: 0px"
                    >
                        <div class="col-6" style="padding: 0px">
                            <svg
                                @click="hideAlertEliminar()"
                                style="cursor: pointer"
                                id="Group_2419"
                                data-name="Group 2419"
                                xmlns="http://www.w3.org/2000/svg"
                                width="150"
                                height="60"
                                viewBox="0 0 150 60"
                            >
                                <g
                                    id="Rectangle_2020"
                                    data-name="Rectangle 2020"
                                    fill="#fff"
                                    stroke="#df438b"
                                    stroke-width="1"
                                >
                                    <path
                                        d="M0,0H150a0,0,0,0,1,0,0V60a0,0,0,0,1,0,0H10A10,10,0,0,1,0,50V0A0,0,0,0,1,0,0Z"
                                        stroke="none"
                                    />
                                    <path
                                        d="M1,.5H149a.5.5,0,0,1,.5.5V59a.5.5,0,0,1-.5.5H10A9.5,9.5,0,0,1,.5,50V1A.5.5,0,0,1,1,.5Z"
                                        fill="none"
                                    />
                                </g>
                                <text
                                    id="Cancelar"
                                    transform="translate(75 35)"
                                    fill="#df438b"
                                    font-size="19"
                                    font-family="SegoeUI-Bold, Segoe UI"
                                    font-weight="700"
                                >
                                    <tspan x="-38.079" y="0">Cancelar</tspan>
                                </text>
                            </svg>
                        </div>
                        <div class="col-6" style="padding: 0px">
                            <svg
                                @click="elminarProducto()"
                                style="cursor: pointer"
                                id="Group_2364"
                                data-name="Group 2364"
                                xmlns="http://www.w3.org/2000/svg"
                                width="150"
                                height="60"
                                viewBox="0 0 150 60"
                            >
                                <path
                                    id="Rectangle_2020"
                                    data-name="Rectangle 2020"
                                    d="M0,0H150a0,0,0,0,1,0,0V50a10,10,0,0,1-10,10H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                                    fill="#df438b"
                                />
                                <text
                                    id="Si_eliminar"
                                    data-name="Si, eliminar"
                                    transform="translate(73 35)"
                                    fill="#fff"
                                    font-size="18"
                                    font-family="SegoeUI-Bold, Segoe UI"
                                    font-weight="700"
                                >
                                    <tspan x="-47.18" y="0">Si, eliminar</tspan>
                                </text>
                            </svg>
                        </div>
                    </div>
                </div>
            </template>
        </vue-modal>
        <vue-modal ref="modalLoad" title="" :showclosed="false">
            <template v-slot:header> </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <center>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="94"
                            height="94"
                            viewBox="0 0 94 94"
                        >
                            <g
                                id="Group_2373"
                                data-name="Group 2373"
                                transform="translate(-5834.694 -10263.994)"
                            >
                                <path
                                    id="Path_4721"
                                    data-name="Path 4721"
                                    d="M18,3V20.6"
                                    transform="translate(5863.694 10263.994)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4722"
                                    data-name="Path 4722"
                                    d="M18,27V44.6"
                                    transform="translate(5863.694 10310.395)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4723"
                                    data-name="Path 4723"
                                    d="M7.4,7.4,19.847,19.847"
                                    transform="translate(5843.191 10272.491)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4724"
                                    data-name="Path 4724"
                                    d="M24.36,24.36,36.812,36.812"
                                    transform="translate(5875.99 10305.29)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4725"
                                    data-name="Path 4725"
                                    d="M3,18H20.6"
                                    transform="translate(5834.694 10292.994)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4726"
                                    data-name="Path 4726"
                                    d="M27,18H44.6"
                                    transform="translate(5881.094 10292.994)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4727"
                                    data-name="Path 4727"
                                    d="M7.4,36.812,19.847,24.36"
                                    transform="translate(5843.191 10305.29)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4728"
                                    data-name="Path 4728"
                                    d="M24.36,19.847,36.812,7.4"
                                    transform="translate(5875.99 10272.491)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                            </g>
                        </svg>

                        <h3>Espere, por favor...</h3>
                    </center>
                </div>
            </template>
        </vue-modal>
        <vue-modal ref="modalsuccess" title="" :showclosed="false">
            <template v-slot:header> </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <center>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="91.287"
                            height="90.147"
                            viewBox="0 0 91.287 90.147"
                        >
                            <g
                                id="Icon_feather-check-circle"
                                data-name="Icon feather-check-circle"
                                transform="translate(0.008 0.058)"
                            >
                                <path
                                    id="Path_4715"
                                    data-name="Path 4715"
                                    d="M87.036,41.169v3.866A42.018,42.018,0,1,1,62.12,6.63"
                                    transform="translate(0 0)"
                                    fill="none"
                                    stroke="#05ff8a"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4716"
                                    data-name="Path 4716"
                                    d="M68.124,6,26.105,48.06,13.5,35.455"
                                    transform="translate(18.913 5.42)"
                                    fill="none"
                                    stroke="#05ff8a"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                            </g>
                        </svg>
                        <p
                            style="
                                margin-top: 8px;
                                font-size: 20px;
                                font-weight: bold;
                            "
                            v-html="messagesSuccess"
                        ></p>
                    </center>
                    <div
                        class="row"
                        style="margin-right: 0px; margin-left: 0px"
                    >
                        <div class="col-12" style="padding: 0px">
                            <svg
                                style="cursor: pointer"
                                @click="closeSuccess()"
                                xmlns="http://www.w3.org/2000/svg"
                                width="316"
                                height="60"
                                viewBox="0 0 316 60"
                            >
                                <g
                                    id="Group_2368"
                                    data-name="Group 2368"
                                    transform="translate(-802 -633)"
                                >
                                    <path
                                        id="Rectangle_2020"
                                        data-name="Rectangle 2020"
                                        d="M0,0H316a0,0,0,0,1,0,0V50a10,10,0,0,1-10,10H10A10,10,0,0,1,0,50V0A0,0,0,0,1,0,0Z"
                                        transform="translate(802 633)"
                                        fill="#df438b"
                                    />
                                    <text
                                        id="Aceptar"
                                        transform="translate(960 669)"
                                        fill="#fff"
                                        font-size="20"
                                        font-family="SegoeUI-Bold, Segoe UI"
                                        font-weight="700"
                                    >
                                        <tspan x="-36.694" y="0">Aceptar</tspan>
                                    </text>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </template>
        </vue-modal>

        <a
            v-if="idsToBeDeleted.length > 0"
            @click="deleteMultipleProducts"
            class="btn-fixed"
            ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 81.945 90.384"
            >
                <g
                    id="Icon_feather-trash-2"
                    data-name="Icon feather-trash-2"
                    transform="translate(3 3)"
                >
                    <path
                        id="Path_4732"
                        data-name="Path 4732"
                        d="M4.5,9H80.445"
                        transform="translate(-4.5 7.877)"
                        fill="none"
                        stroke="#d10043"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="6"
                    />
                    <path
                        id="Path_4733"
                        data-name="Path 4733"
                        d="M66.569,19.877V78.945a8.438,8.438,0,0,1-8.438,8.438H15.938A8.438,8.438,0,0,1,7.5,78.945V19.877m12.658,0V11.438A8.438,8.438,0,0,1,28.6,3H45.473a8.438,8.438,0,0,1,8.438,8.438v8.438"
                        transform="translate(0.938 -3)"
                        fill="none"
                        stroke="#d10043"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="6"
                    />
                    <path
                        id="Path_4734"
                        data-name="Path 4734"
                        d="M15,16.5V41.815"
                        transform="translate(14.534 21.473)"
                        fill="none"
                        stroke="#d10043"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="6"
                    />
                    <path
                        id="Path_4735"
                        data-name="Path 4735"
                        d="M21,16.5V41.815"
                        transform="translate(25.411 21.473)"
                        fill="none"
                        stroke="#d10043"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="6"
                    />
                </g></svg
            >&nbsp;&nbsp;Eliminar</a
        >
    </div>
</template>
<style>
.btn-fixed {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    color: #ffffff;
    border-radius: 5px;
    letter-spacing: 2px;
    background-color: #e91e626c;
    padding: 18px 30px;
    position: fixed;
    bottom: 40px;
    right: 40px;
    transition: all 300ms ease 0ms;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    z-index: 99;
}
.btn-fixed:hover {
    background-color: #df438b;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
    transform: translateY(-7px);
    text-decoration: none;
    color: #ffffff;
    cursor: pointer;
}
.btn-block {
    font-size: 11px;
}
</style>
