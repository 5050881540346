<template>
    <div class="row py-4 px-4">
        <div class="col-6">
            <h5 style="color: #df438b"><b>Ventana emergente</b></h5>
            <div class="bg-white">
                <div class="container" style="padding: 8px">
                    <div>
                        <input
                            type="checkbox"
                            v-model="alertflag"
                            style="width: 25px; height: 25px; margin-top: 10px"
                            @change="check"
                        />
                        <label class="align-middle" style="color: #df438b"
                            ><b>Habilitar</b></label
                        >
                    </div>
                    <!-- start card info div -->
                    <div :class="{ disabled: !alertflag }" class="py-2 px-2">
                        <!-- start form -->
                        <form @submit="saveAlert">
                            <!-- start image input -->
                            <label for="" style="color: #df438b"
                                ><b>Imagen</b></label
                            >
                            <div class="custom-file">
                                <div>
                                    <input
                                        type="file"
                                        class="custom-file-input"
                                        accept="image/*"
                                        v-on:change="alertImage"
                                    />
                                    <label
                                        class="custom-file-label"
                                        for="inputGroupFile01"
                                        >Seleccionar imagen</label
                                    >
                                    <span
                                        >Formato JPG o PNG con medidas 695 x
                                        120px (recomendado)</span
                                    >
                                    <span
                                        class="text-danger"
                                        v-if="validation.validimage"
                                        >{{ errorMessages.generalError }}</span
                                    >
                                </div>
                            </div>
                            <!-- end image input -->
                            <!-- start titles inputs -->
                            <div class="form-group">
                                <label
                                    for=""
                                    class="mt-2"
                                    style="color: #df438b"
                                    ><b>Título</b></label
                                >
                                <input
                                    class="form-control"
                                    type="text"
                                    v-model="form.title"
                                    maxlength="70"
                                />
                                <span
                                    class="text-danger"
                                    v-if="validation.validtitle"
                                    >{{ errorMessages.generalError }}</span
                                >
                            </div>
                            <div class="form-group">
                                <label
                                    for=""
                                    class="mt-2"
                                    style="color: #df438b"
                                    ><b>Sub-título</b></label
                                >
                                <input
                                    class="form-control"
                                    type="text"
                                    v-model="form.subtitle"
                                    maxlength="100"
                                />
                                <span
                                    class="text-danger"
                                    v-if="validation.validsubtitle"
                                    >{{ errorMessages.generalError }}</span
                                >
                            </div>
                            <!-- end titles inputs -->
                            <!-- start message input -->
                            <div class="form-group">
                                <label
                                    for=""
                                    class="mt-2"
                                    style="color: #df438b"
                                    ><b>Mensaje</b></label
                                >
                                <textarea
                                    style="height: 150px"
                                    class="form-control"
                                    type="text"
                                    v-model="form.message"
                                    maxlength="255"
                                ></textarea>
                                <span
                                    class="text-danger"
                                    v-if="validation.validmessage"
                                    >{{ errorMessages.generalError }}</span
                                >
                            </div>
                            <!-- end message input -->

                            <!-- start button text titlw -->
                            <div class="form-group">
                                <label
                                    for=""
                                    class="mt-2"
                                    style="color: #df438b"
                                    ><b>Texto de botón</b></label
                                >
                                <input
                                    class="form-control"
                                    type="text"
                                    v-model="form.bottonTitle"
                                    maxlength="35"
                                />
                                <span
                                    class="text-danger"
                                    v-if="validation.validbtnText"
                                    >{{ errorMessages.generalError }}</span
                                >
                            </div>
                            <!-- end button text title -->
                            <!-- start footer title -->
                            <div class="form-group">
                                <label
                                    for=""
                                    class="mt-2"
                                    style="color: #df438b"
                                    ><b>Pie de página</b></label
                                >
                                <input
                                    class="form-control"
                                    type="text"
                                    v-model="form.footer"
                                    maxlength="255"
                                />
                                <span
                                    class="text-danger"
                                    v-if="validation.validfooter"
                                    >{{ errorMessages.generalError }}</span
                                >
                            </div>
                            <!-- end footer title  -->
                            <!-- start save btn  -->
                            <center>
                                <button
                                    class="btn btn-success"
                                    style="
                                        background-color: #df438b;
                                        border-color: #df438b;
                                        width: 50%;
                                    "
                                >
                                    Guardar
                                </button>
                            </center>
                            <!-- end save btn -->
                        </form>
                        <!-- end form -->
                    </div>
                    <!-- ends card info div -->
                </div>
            </div>
        </div>
        <div class="col-6">
            <!-- start main div  -->
            <h5 style="color: #df438b"><b>Previsualización</b></h5>
            <!-- preview alert div -->
            <div class="py-2 px-2" :class="{ disabled: !alertflag }">
                <div class="card mb-3">
                    <div
                        style="
                            height: 95px;
                            width: 100%;
                            background-color: gray;
                        "
                    >
                        <img
                            v-if="imageSrc != ''"
                            :src="imageSrc"
                            class="img"
                            style="object-fit: cover"
                        />
                    </div>
                    <div class="py-4 px-4">
                        <center>
                            <h3 class="card-title pt-3">
                                <b>{{
                                    form.title == "" ? "Titulo" : form.title
                                }}</b>
                            </h3>
                            <h5>
                                {{
                                    form.subtitle == ""
                                        ? "Sub-título"
                                        : form.subtitle
                                }}
                            </h5>
                            <p class="card-text">
                                {{
                                    form.message == ""
                                        ? "Mensaje"
                                        : form.message
                                }}
                            </p>
                            <div class="container">
                                <div class="row">
                                    <div
                                        class="col-6 mx-auto"
                                        v-if="form.nameInput"
                                    >
                                        <input
                                            class="form-control"
                                            placeholder="Input 1 - nombre"
                                            type="text"
                                        />
                                    </div>
                                    <div
                                        class="col-6 mx-auto"
                                        v-if="form.emailInput"
                                    >
                                        <input
                                            class="form-control"
                                            placeholder="Input 2 - correo"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="container py-3 px-2">
                                <center>
                                    <button
                                        class="btn btn-success"
                                        style="
                                            background-color: #df438b;
                                            border-color: #df438b;
                                            width: 50%;
                                        "
                                    >
                                        {{
                                            form.bottonTitle == ""
                                                ? "Titulo botón"
                                                : form.bottonTitle
                                        }}
                                    </button>
                                </center>
                            </div>
                            <p class="card-text">
                                <small class="text-muted">{{
                                    form.footer == ""
                                        ? "Pie de pagina"
                                        : form.footer
                                }}</small>
                            </p>
                        </center>
                    </div>
                </div>
            </div>
            <!-- end prevew card section -->
            <!-- start cintillo section -->
            <div>
                <h5 style="color: #df438b; margin-top: 1em"><b>Cintillo</b></h5>
                <div class="bg-white py-4 px-4">
                    <div>
                        <input
                            type="checkbox"
                            v-model="cintilloVar.status"
                            style="width: 25px; height: 25px; margin-top: 10px"
                            @change="checkCintillo"
                        />
                        <label class="align-middle" style="color: #df438b"
                            ><b>Habilitar</b></label
                        >
                        <div
                            :class="{ disabled: !cintilloVar.status }"
                            class="py-2 px-2"
                        >
                            <!-- start cintillo form -->
                            <form @submit="saveCintillo">
                                <div class="form-group">
                                    <label
                                        for=""
                                        class="mt-2"
                                        style="color: #df438b"
                                        ><b>Mensaje</b></label
                                    >
                                    <textarea
                                        style="height: 90px"
                                        class="form-control"
                                        v-model="cintilloVar.message"
                                    ></textarea>
                                    <span
                                        class="text-danger"
                                        v-if="validation.validcintillo"
                                        >{{ errorMessages.generalError }}</span
                                    >
                                </div>
                                <div class="container py-3 px-2">
                                    <center>
                                        <button
                                            class="btn btn-success"
                                            style="
                                                background-color: #df438b;
                                                border-color: #df438b;
                                                width: 50%;
                                            "
                                            type="submit"
                                        >
                                            Guardar Cintillo
                                        </button>
                                    </center>
                                </div>
                            </form>
                            <!-- end cintillo form -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- end cintillo section  -->
            <!-- end main div  -->
        </div>

        <!-- image error modal -->
        <vue-modal ref="imageError" title="">
            <template v-slot:header> </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <div class="container">
                        <center>
                            <p
                                style="
                                    margin-top: 8px;
                                    font-size: 20px;
                                    font-weight: bold;
                                "
                            >
                                Vaya! formato de imagen no soportado!
                            </p>
                        </center>
                    </div>
                    <div
                        class="row"
                        style="margin-right: 0px; margin-left: 0px"
                    >
                        <div class="col-12" style="padding: 5px">
                            <div class="container">
                                <button
                                    @click="closeModalImage()"
                                    class="form-control btn btn-danger"
                                    style="
                                        margin-top: 8.8%;
                                        background-color: #df438b;
                                        border-color: #df438b;
                                    "
                                >
                                    Entendido!
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </vue-modal>
        <!-- end image error modal -->
        <!-- start load modal -->
        <vue-modal ref="modalLoad" title="" :showclosed="false">
            <template v-slot:header> </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <center>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="94"
                            height="94"
                            viewBox="0 0 94 94"
                        >
                            <g
                                id="Group_2373"
                                data-name="Group 2373"
                                transform="translate(-5834.694 -10263.994)"
                            >
                                <path
                                    id="Path_4721"
                                    data-name="Path 4721"
                                    d="M18,3V20.6"
                                    transform="translate(5863.694 10263.994)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4722"
                                    data-name="Path 4722"
                                    d="M18,27V44.6"
                                    transform="translate(5863.694 10310.395)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4723"
                                    data-name="Path 4723"
                                    d="M7.4,7.4,19.847,19.847"
                                    transform="translate(5843.191 10272.491)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4724"
                                    data-name="Path 4724"
                                    d="M24.36,24.36,36.812,36.812"
                                    transform="translate(5875.99 10305.29)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4725"
                                    data-name="Path 4725"
                                    d="M3,18H20.6"
                                    transform="translate(5834.694 10292.994)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4726"
                                    data-name="Path 4726"
                                    d="M27,18H44.6"
                                    transform="translate(5881.094 10292.994)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4727"
                                    data-name="Path 4727"
                                    d="M7.4,36.812,19.847,24.36"
                                    transform="translate(5843.191 10305.29)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4728"
                                    data-name="Path 4728"
                                    d="M24.36,19.847,36.812,7.4"
                                    transform="translate(5875.99 10272.491)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                            </g>
                        </svg>

                        <h3>Espere, por favor...</h3>
                    </center>
                </div>
            </template>
        </vue-modal>
        <!-- end load modal -->
        <!-- start error modal -->

        <vue-modal ref="generalError" title="">
            <template v-slot:header> </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <div class="container">
                        <center>
                            <p
                                style="
                                    margin-top: 8px;
                                    font-size: 20px;
                                    font-weight: bold;
                                "
                            >
                                Vaya! ha sucedido un error!
                            </p>
                            <p
                                style="
                                    margin-top: 8px;
                                    font-size: 20px;
                                    font-weight: bold;
                                "
                            >
                                Si el error persiste, contacte con soporte dronautic@gmail.com
                            </p>
                        </center>
                    </div>
                    <div
                        class="row"
                        style="margin-right: 0px; margin-left: 0px"
                    >
                        <div class="col-12" style="padding: 5px">
                            <div class="container">
                                <button
                                    @click="closeErrorModal()"
                                    class="form-control btn btn-danger"
                                    style="
                                        margin-top: 8.8%;
                                        background-color: #df438b;
                                        border-color: #df438b;
                                    "
                                >
                                    Entendido!
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </vue-modal>
        <!-- end error modal -->
    </div>
</template>

<script>
import VueModal from "./VueModal.vue";
export default {
    components: {
        VueModal,
    },
    props: ["alert", "cintillo"],
    data() {
        var al = this.alert;
        var ct = this.cintillo;
        return {
            form: {
                image: null,
                title: al.title,
                subtitle: al.subtitle,
                message: al.message,
                nameInput: al.name_input == 0 ? false : true,
                emailInput: al.email_input == 0 ? false : true,
                bottonTitle: al.button_text,
                footer: al.footer,
            },
            cintilloVar: {
                message: ct.message,
                status: ct.status == 0 ? false : true,
            },
            alertflag: al.status == 0 ? false : true,
            cintilloflag: false,
            imageSrc: al.src,
            validation: {
                validtitle: false,
                validsubtitle: false,
                validmessage: false,
                validbtnText: false,
                validfooter: false,
                validimage: false,
                validcintillo: false,
            },
            errorMessages: {
                generalError: "Vaya! este campo es requerido!",
            },
        };
    },
    methods: {
        activeAlert() {
            this.alertflag = !this.alertflag;
        },
        activeNameInput() {
            this.form.nameInput = !this.form.nameInput;
        },
        activeEmailInput() {
            this.form.emailInput = !this.form.emailInput;
        },
        alertImage(e) {
            this.form.image = e.target.files[0];
            this.imageSrc = URL.createObjectURL(this.form.image);
        },
        //?change the alert status to disabled
        check() {
            if (!this.alertflag && this.alert.status == 1) {
                this.$refs.modalLoad.open();
                axios
                    .post("/administrador/alert/disable")
                    .then((res) => {
                        //*error validation
                        if (res.data.message == "error") {
                            console.log(res.data.errorMessage);
                            this.$refs.generalError.open();
                            this.$refs.modalLoad.close();
                        } else {
                            window.location.href = "/administrador/alert";
                            this.$refs.modalLoad.close();
                        }
                    })
                    .catch(function (err) {
                        console.log(err);
                    });
            }
        },
        checkCintillo() {
            if (!this.cintilloVar.status && this.cintillo.status == 1) {
                this.$refs.modalLoad.open();
                axios
                    .post("/administrador/cintillo/disable")
                    .then((res) => {
                        //*error validation
                        if (res.data.message == "error") {
                            console.log(res.data.errorMessage);
                            this.$refs.generalError.open();
                            this.$refs.modalLoad.close();
                        } else {
                            window.location.href = "/administrador/alert";
                            this.$refs.modalLoad.close();
                        }
                    })
                    .catch(function (err) {
                        console.log(err);
                    });
            }
        },
        //?close modal
        closeModalImage() {
            this.$refs.imageError.close();
        },
        //?close error modal
        closeErrorModal() {
            this.$refs.generalError.close();
        },
        emptyValidation() {
            var frm = this.form;
            var vl = this.validation;
            if (frm.title == "" || frm.title == undefined) {
                if (!vl.validtitle) vl.validtitle = !vl.validtitle;
            } else {
                if (vl.validtitle) vl.validtitle = !vl.validtitle;
            }
            if (frm.subtitle == "" || frm.subtitle == undefined) {
                if (!vl.validsubtitle) vl.validsubtitle = !vl.validsubtitle;
            } else {
                if (vl.validsubtitle) vl.validsubtitle = !vl.validsubtitle;
            }
            if (frm.message == "" || frm.message == undefined) {
                if (!vl.validmessage) vl.validmessage = !vl.validmessage;
            } else {
                if (vl.validmessage) vl.validmessage = !vl.validmessage;
            }
            if (frm.footer == "" || frm.footer == undefined) {
                if (!vl.validfooter) vl.validfooter = !vl.validfooter;
            } else {
                if (vl.validfooter) vl.validfooter = !vl.validfooter;
            }
            if (frm.bottonTitle == "" || frm.bottonTitle == undefined) {
                if (!vl.validbtnText) vl.validbtnText = !vl.validbtnText;
            } else {
                if (vl.validbtnText) vl.validbtnText = !vl.validbtnText;
            }
            if (frm.image == "" || frm.image == undefined) {
                if (!vl.validimage) vl.validimage = !vl.validimage;
            } else {
                if (vl.validimage) vl.validimage = !vl.validimage;
            }
        },
        emptyCintilloVal() {
            var vl = this.validation;
            if (
                this.cintilloVar.message == "" ||
                this.cintilloVar.message == undefined
            ) {
                if (!vl.validcintillo) vl.validcintillo = !vl.validcintillo;
            } else {
                if (vl.validcintillo) vl.validcintillo = !vl.validcintillo;
            }
        },
        saveAlert(e) {
            e.preventDefault();
            this.emptyValidation();
            var vl = this.validation;
            var frm = this.form;
            if (
                !vl.validtitle &&
                !vl.validsubtitle &&
                !vl.validmessage &&
                !vl.validbtnText &&
                !vl.validfooter &&
                !vl.validimage
            ) {
                this.$refs.modalLoad.open();
                if (frm.image != null) {
                    //*validate imagen content
                    if (this.validImagenFile(frm.image)) {
                        const data = new FormData();
                        data.append("title", frm.title);
                        data.append("subtitle", frm.subtitle);
                        data.append("message", frm.message);
                        data.append(
                            "name_input",
                            frm.nameInput == false ? 0 : 1
                        );
                        data.append(
                            "email_input",
                            frm.emailInput == false ? 0 : 1
                        );
                        data.append("button_text", frm.bottonTitle);
                        data.append("footer", frm.footer);
                        data.append("src", frm.image);
                        data.append("status", 1);
                        //?axios request
                        axios
                            .post("/administrador/alert", data)
                            .then((res) => {
                                //*error validation
                                if (res.data.message == "error") {
                                    console.log(res.data.errorMessage);
                                    this.$refs.generalError.open();
                                    this.$refs.modalLoad.close();
                                } else {
                                    window.location.href =
                                        "/administrador/alert";
                                    this.$refs.modalLoad.close();
                                }
                            })
                            .catch(function (err) {
                                console.log(err);
                            });
                    } else {
                        this.$refs.modalLoad.close();
                        this.$refs.imageError.open();
                    }
                }
            }
        },
        saveCintillo(e) {
            e.preventDefault();
            this.emptyCintilloVal();
            if (!this.validation.validcintillo) {
                this.$refs.modalLoad.open();
                const data = new FormData();
                data.append("message", this.cintilloVar.message);
                axios
                    .post("/administrador/cintillo", data)
                    .then((res) => {
                        //*error validation
                        if (res.data.message == "error") {
                            console.log(res.data.errorMessage);
                            this.$refs.generalError.open();
                            this.$refs.modalLoad.close();
                        } else {
                            window.location.href = "/administrador/alert";
                            this.$refs.modalLoad.close();
                        }
                    })
                    .catch(function (err) {
                        console.log(err);
                    });
            }
        },
        //*valid imagen validation
        validImagenFile(imageFile) {
            if (this.alertflag) {
                if (imageFile != null) {
                    if (!/\.(svg|jpg|png|gif)$/i.test(imageFile.name)) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            } else {
                if (imageFile != null && typeof imageFile != "string") {
                    if (!/\.(svg|jpg|png|gif)$/i.test(imageFile.name)) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            }
        },
    },
    mounted() {},
};
</script>

<style>
.disabled {
    pointer-events: none;

    /* for "disabled" effect */
    opacity: 0.5;
    background: #ccc;
}
.img {
    width: 100%; /* or any custom size */
    height: 100%;
    object-fit: contain;
}
</style>
