<template>
    <div class="pt-2">
        <div class="row">
            <!-- start back button and title -->
            <div class="col-2">
                <svg
                    style="cursor: pointer"
                    @click="back()"
                    id="Component_42_1"
                    data-name="Component 42 – 1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="150"
                    height="54"
                    viewBox="0 0 150 54"
                >
                    <rect
                        id="Rectangle_1890"
                        data-name="Rectangle 1890"
                        width="150"
                        height="54"
                        rx="27"
                        fill="#df438b"
                    />
                    <g
                        id="Group_1872"
                        data-name="Group 1872"
                        transform="translate(-295 -148)"
                    >
                        <g
                            id="Icon_feather-arrow-left"
                            data-name="Icon feather-arrow-left"
                            transform="translate(313.811 156.911)"
                        >
                            <path
                                id="Path_4585"
                                data-name="Path 4585"
                                d="M29.5,18H7.5"
                                transform="translate(0 0.5)"
                                fill="none"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="3"
                            />
                            <path
                                id="Path_4586"
                                data-name="Path 4586"
                                d="M18.5,29.5l-11-11,11-11"
                                transform="translate(0 0)"
                                fill="none"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="3"
                            />
                        </g>
                        <text
                            id="Regresar"
                            transform="translate(354 180)"
                            fill="#fff"
                            font-size="16"
                            font-family="SegoeUI-Bold, Segoe UI"
                            font-weight="700"
                        >
                            <tspan x="0" y="0">Regresar</tspan>
                        </text>
                    </g>
                </svg>
            </div>
            <div class="col-8" style="text-align: center">
                <h3>
                    {{
                        screen_title == "add"
                            ? "Agregar pregunta frecuente"
                            : "Editar producto"
                    }}
                </h3>
            </div>
        </div>
        <!-- end back button and title -->
        <!-- start FAQ form -->
        <form @submit="saveQuestion">
            <div class="col-12 py-2 px-2 mt-5">
                <label for="" style="color: #df438b"
                    ><b>Escribe una pregunta</b></label
                >
                <input
                    class="form-control"
                    v-model="form.question"
                    type="text"
                />
                <span class="text-danger" v-if="validation.question">{{
                    errorMessages.generalError
                }}</span>
            </div>
            <div class="col-12 py-2 px-2">
                <label for="" style="color: #df438b"
                    ><b>Escribe una respuesta</b></label
                >
                <textarea
                    class="form-control"
                    type="text"
                    v-model="form.answer"
                    style="height: 300px"
                ></textarea>
                <span class="text-danger" v-if="validation.answer">{{
                    errorMessages.generalError
                }}</span>
            </div>
            <div class="row">
                <div class="col-4 mx-auto" style="text-align: center">
                    <input
                        class="form-control btn btn-danger"
                        style="
                            margin-top: 8.8%;
                            background-color: #df438b;
                            border-color: #df438b;
                        "
                        type="submit"
                        :value="screen_title == 'add' ? 'Guardar' : 'Editar'"
                    />
                </div>
            </div>
        </form>
        <!-- end FAQ form -->
        <!-- start load modal -->

        <vue-modal ref="modalLoad" title="" :showclosed="false">
            <template v-slot:header> </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <center>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="94"
                            height="94"
                            viewBox="0 0 94 94"
                        >
                            <g
                                id="Group_2373"
                                data-name="Group 2373"
                                transform="translate(-5834.694 -10263.994)"
                            >
                                <path
                                    id="Path_4721"
                                    data-name="Path 4721"
                                    d="M18,3V20.6"
                                    transform="translate(5863.694 10263.994)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4722"
                                    data-name="Path 4722"
                                    d="M18,27V44.6"
                                    transform="translate(5863.694 10310.395)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4723"
                                    data-name="Path 4723"
                                    d="M7.4,7.4,19.847,19.847"
                                    transform="translate(5843.191 10272.491)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4724"
                                    data-name="Path 4724"
                                    d="M24.36,24.36,36.812,36.812"
                                    transform="translate(5875.99 10305.29)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4725"
                                    data-name="Path 4725"
                                    d="M3,18H20.6"
                                    transform="translate(5834.694 10292.994)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4726"
                                    data-name="Path 4726"
                                    d="M27,18H44.6"
                                    transform="translate(5881.094 10292.994)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4727"
                                    data-name="Path 4727"
                                    d="M7.4,36.812,19.847,24.36"
                                    transform="translate(5843.191 10305.29)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4728"
                                    data-name="Path 4728"
                                    d="M24.36,19.847,36.812,7.4"
                                    transform="translate(5875.99 10272.491)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                            </g>
                        </svg>

                        <h3>Espere, por favor...</h3>
                    </center>
                </div>
            </template>
        </vue-modal>
        <!-- end load modal -->
        <!-- start general error modal -->

        <vue-modal ref="generalError" title="">
            <template v-slot:header>
                <div class="mx-auto">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="90.346"
                        height="90.346"
                        viewBox="0 0 90.346 90.346"
                    >
                        <path
                            id="Icon_material-error-outline"
                            data-name="Icon material-error-outline"
                            d="M43.656,61.725H52.69v9.035H43.656Zm0-36.138H52.69v27.1H43.656ZM48.128,3A45.173,45.173,0,1,0,93.346,48.173,45.151,45.151,0,0,0,48.128,3Zm.045,81.311A36.138,36.138,0,1,1,84.311,48.173,36.128,36.128,0,0,1,48.173,84.311Z"
                            transform="translate(-3 -3)"
                            fill="#d10043"
                        />
                    </svg>
                </div>
            </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <div class="container">
                        <center>
                            <p
                                style="
                                    margin-top: 8px;
                                    font-size: 20px;
                                    font-weight: bold;
                                "
                            >
                                Los cambios no pudieron ser guardados. Si el
                            </p>
                            <p
                                style="
                                    margin-top: 8px;
                                    font-size: 20px;
                                    font-weight: bold;
                                "
                            >
                                error persiste, contacte con soporte técnico.
                            </p>
                        </center>
                    </div>
                    <div
                        class="row"
                        style="margin-right: 0px; margin-left: 0px"
                    >
                        <div class="col-12" style="padding: 5px">
                            <div class="container">
                                <button
                                    @click="closeErrorModal()"
                                    class="form-control btn btn-danger"
                                    style="
                                        margin-top: 8.8%;
                                        background-color: #df438b;
                                        border-color: #df438b;
                                    "
                                >
                                    Entendido!
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </vue-modal>
        <!-- end general error modal -->
        <!-- start confirm modal -->
        <vue-modal ref="confModal" title="">
            <template v-slot:header>
                <div class="mx-auto">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="91.287"
                        height="90.147"
                        viewBox="0 0 91.287 90.147"
                    >
                        <g
                            id="Icon_feather-check-circle"
                            data-name="Icon feather-check-circle"
                            transform="translate(0.008 0.058)"
                        >
                            <path
                                id="Path_4715"
                                data-name="Path 4715"
                                d="M87.036,41.169v3.866A42.018,42.018,0,1,1,62.12,6.63"
                                transform="translate(0 0)"
                                fill="none"
                                stroke="#05ff8a"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="6"
                            />
                            <path
                                id="Path_4716"
                                data-name="Path 4716"
                                d="M68.124,6,26.105,48.06,13.5,35.455"
                                transform="translate(18.913 5.42)"
                                fill="none"
                                stroke="#05ff8a"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="6"
                            />
                        </g>
                    </svg>
                </div>
            </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <div class="container">
                        <center>
                            <p
                                style="
                                    margin-top: 8px;
                                    font-size: 20px;
                                    font-weight: bold;
                                "
                            >
                                Los cambios se han guardado exitosamente
                            </p>
                        </center>
                    </div>
                    <div
                        class="row"
                        style="margin-right: 0px; margin-left: 0px"
                    >
                        <div class="col-12" style="padding: 5px">
                            <div class="container">
                                <button
                                    @click="closeConfModal()"
                                    class="form-control btn btn-danger"
                                    style="
                                        margin-top: 8.8%;
                                        background-color: #df438b;
                                        border-color: #df438b;
                                    "
                                >
                                    Entendido!
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </vue-modal>
        <!-- end confirm modal -->
    </div>
</template>

<script>
import VueModal from "./VueModal.vue";
export default {
    components: { VueModal },
    props: ["screen_title",'faq'],
    data() {
        return {
            form: {
                question: this.faq.question,
                answer: this.faq.answer,
            },
            validation: {
                question: false,
                answer: false,
            },
            errorMessages: {
                generalError: "Vaya! este campo es requerido!",
            },
        };
    },
    methods: {
        back() {
            window.history.back();
        },
        closeErrorModal() {
            this.$refs.generalError.close();
        },
        closeConfModal() {
            this.$refs.confModal.close();
            window.location.href = "/administrador/faq";
        },
        emptyValidation() {
            var frm = this.form;
            var vl = this.validation;
            //* question validation
            if (frm.question == "" || frm.question == undefined) {
                if (!vl.question) vl.question = !vl.question;
            } else {
                if (vl.question) vl.question = !vl.question;
            }
            //* answer validation
            if (frm.answer == "" || frm.answer == undefined) {
                if (!vl.answer) vl.answer = !vl.answer;
            } else {
                if (vl.answer) vl.answer = !vl.answer;
            }
        },
        saveQuestion(e) {
            e.preventDefault();
            var frm = this.form;
            var vl = this.validation;
            this.emptyValidation();
            if (!vl.question && !vl.answer) {
                this.$refs.modalLoad.open();
                //?creating form data
                const data = new FormData();
                //?attaching form values
                data.append("question", frm.question);
                data.append("answer", frm.answer);
                if (this.screen_title == "add") {
                    axios
                        .post("/administrador/faq", data)
                        .then((res) => {
                            //*error validation
                            if (res.data.message == "error") {
                                console.log(res.data.errorMessage);
                                this.$refs.generalError.open();
                                this.$refs.modalLoad.close();
                            } else {
                                this.$refs.modalLoad.close();
                                this.$refs.confModal.open();
                            }
                        })
                        .catch(function (err) {
                            console.log(err);
                        });
                } else {
                    axios
                        .post(
                            "/administrador/faq/" + this.faq.id,
                            data
                        )
                        .then((res) => {
                            //*error validation
                            if (res.data.message == "error") {
                                console.log(res.data.errorMessage);
                                this.$refs.generalError.open();
                                this.$refs.modalLoad.close();
                            } else {
                                this.$refs.modalLoad.close();
                                this.$refs.confModal.open();
                            }
                        })
                        .catch(function (err) {
                            console.log(err);
                        });
                }
            }
        },
    },
};
</script>

<style></style>
