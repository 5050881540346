import {card, card as productCard} from '../products'

export const renderBestProducts = ({data, target}) => {
    let $target = $(target)
    let html = data.data.map(product => {
        let $wrap = $('<div class="col-12 col-md-6 col-lg-4 p-1 p-md-2 p-lg-4"/>')
        $wrap.html(card(product))
        $target.append($wrap)
    })
}
