<template>
    <div>
        <div>
            <!-- Image previewModal -->
            <!-- Modal -->
            <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                ref="modal"
            >
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body" style="padding: 0.3rem">
                            <div class="card mb-3">
                                <div
                                    style="
                                        height: 95px;
                                        width: 100%;
                                        background-color: transparent;
                                    "
                                >
                                    <div
                                        style="
                                            height: 25px;
                                            width: 25px;
                                            background-color: transparent;
                                            padding-right: 5px;
                                            position: relative;
                                        "
                                        class="float-right"
                                    ></div>
                                    <img
                                        :src="src"
                                        class="image"
                                        style="object-fit: cover"
                                    />
                                </div>
                                <div class="py-4 px-4">
                                    <center>
                                        <h3 class="card-title pt-3">
                                            <b>{{
                                                title == "" ? "Titulo" : title
                                            }}</b>
                                        </h3>
                                        <h5>
                                            {{
                                                subtitle == ""
                                                    ? "Sub-título"
                                                    : subtitle
                                            }}
                                        </h5>
                                        <p class="card-text">
                                            {{
                                                message == ""
                                                    ? "Mensaje"
                                                    : message
                                            }}
                                        </p>
                                        <div class="container">
                                            <div class="row">
                                                <div
                                                    class="col-6 mx-auto"
                                                    v-if="name_input"
                                                >
                                                    <input
                                                        class="form-control"
                                                        placeholder="Ingresar Nombre"
                                                        type="text"
                                                    />
                                                </div>
                                                <div
                                                    class="col-6 mx-auto"
                                                    v-if="email_input"
                                                >
                                                    <input
                                                        class="form-control"
                                                        placeholder="Ingresar Correo"
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container py-3 px-2">
                                            <center>
                                                <button
                                                    class="btn btn-success"
                                                    style="
                                                        background-color: #df438b;
                                                        border-color: #df438b;
                                                        width: 50%;
                                                    "
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    {{
                                                        button_text == ""
                                                            ? "Titulo botón"
                                                            : button_text
                                                    }}
                                                </button>
                                            </center>
                                        </div>
                                        <p class="card-text">
                                            <small class="text-muted">{{
                                                footer == ""
                                                    ? "Pie de pagina"
                                                    : footer
                                            }}</small>
                                        </p>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["alert"],
    data() {
        var al = this.alert;
        return {
            title: al.title,
            subtitle: al.subtitle,
            status: al.status == 0 ? false : true,
            message: al.message,
            src: al.src,
            name_input: al.name_input,
            email_input: al.email_input,
            button_text: al.button_text,
            footer: al.footer,
            modal: 0,
        };
    },
    methods: {
        openModal() {
            $("#exampleModal").modal("show");
        },
        //?close modal
        closeModal() {
            this.modal = 0;
        },
        setUrlImage() {
            this.modal = 1;
        },
    },
    mounted() {
        if (this.status) {
            this.openModal();
        }
    },
};
</script>

<style>
.mostrar {
    display: list-item;
    opacity: 1;
    background: #d877a4b6;
    padding-top: 15%;
}
.image {
    width: 100%; /* or any custom size */
    height: 118%;
    object-fit: contain;
    margin-top: -25px;
    z-index: 100;
}
</style>
