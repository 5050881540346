<template>
    <div class="w-100">
        <p
            class="p-2"
            style="
                border-color: #df438b;
                border-width: 1px;
                border-style: solid;
                border-radius: 8px;
            "
            data-toggle="collapse"
            :href="'#card' + faq.id"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
            @click="clicked = !clicked"
        >
            <span class="container">
                <span v-if="!clicked"
                        ><i class="fa fa-plus" aria-hidden="true"></i
                    ></span>
                <span v-if="clicked"
                    ><i class="fa fa-minus" aria-hidden="true"></i
                ></span>
                &nbsp;&nbsp; {{ faq.question }}
            </span>
        </p>
        <div class="collapse" :id="'card' + faq.id">
            <div class="card card-body">
                {{ faq.answer }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["faq"],
    data() {
        return {
            clicked: false,
        };
    },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
