<script>
import { mapActions, mapGetters } from "vuex";
import VueModal from "./VueModal.vue";
export default {
    components: {
        VueModal,
    },
    props: ["ordenes", "search", "keywordres"],
    data() {
        return {
            ordenes_adomicilo: [],
            ordenes_tienda: [],
            ordenes_archivo: [],
            selectedOrden: null,
            keyword: this.keywordres == undefined ? "" : this.keywordres,
            searchFlag: this.search,
        };
    },
    methods: {
        changeArchivar(_orden) {
            this.cancelarCheckAsignarCheck(_orden);
            if (_orden.archive) {
                //
                this.$refs.modalArchivar.open();
                this.selectedOrden = _orden;
                console.log(_orden);
            }
        },
        cancelarCheckAsignarCheck(orden) {
            this.ordenes_adomicilo.forEach((element) => {
                if (element.id == orden.id) {
                    this.ordenes_adomicilo[
                        this.ordenes_adomicilo.indexOf(element)
                        ].archive = true;
                } else {
                    this.ordenes_adomicilo[
                        this.ordenes_adomicilo.indexOf(element)
                        ].archive = false;
                }
            });
            this.ordenes_tienda.forEach((element) => {
                if (element.id == orden.id) {
                    this.ordenes_tienda[
                        this.ordenes_tienda.indexOf(element)
                        ].archive = true;
                } else {
                    this.ordenes_tienda[
                        this.ordenes_tienda.indexOf(element)
                        ].archive = false;
                }
            });
        },
        cancelarArchivar() {
            this.$refs.modalArchivar.close();
            if (this.selectedOrden != null) {
                this.selectedOrden.archive = false;
            }
            this.ordenes_adomicilo.forEach((element) => {
                this.ordenes_adomicilo[
                    this.ordenes_adomicilo.indexOf(element)
                    ].archive = false;
            });
            this.ordenes_tienda.forEach((element) => {
                this.ordenes_tienda[
                    this.ordenes_tienda.indexOf(element)
                    ].archive = false;
            });
            console.log(this.ordenes_adomicilo);
        },
        cancelarEliminar() {
            this.$refs.modalEliminar.close();
        },
        async elminarOrden() {
            var vm = this;
            try {
                var orden = {
                    id: vm.selectedOrden.id,
                };
                console.log("eliminar");
                vm.$refs.modalEliminar.close();
                vm.$refs.modalLoad.open();
                await vm.deleteOrden(orden);
                vm.$refs.modalLoad.close();
                console.log(vm.ordenes_archivo);
                vm.ordenes_archivo.splice(
                    vm.ordenes_archivo.indexOf(vm.selectedOrden),
                    1
                );
                console.log(vm.ordenes_archivo);
                vm.selectedOrden = null;
            } catch (error) {
                vm.$refs.modalLoad.close();
                vm.selectedOrden = null;
            }
        },
        showAlerEliminar(_orden) {
            this.$refs.modalEliminar.open();
            this.selectedOrden = _orden;
        },
        searchOrder(keyword) {
            window.location.href = "/administrador/ordenes/" + keyword;
        },
        async Archive() {
            var vm = this;
            try {
                var orden = {
                    id: vm.selectedOrden.id,
                };
                vm.$refs.modalArchivar.close();
                vm.$refs.modalLoad.open();
                await vm.archiveOrden(orden);
                vm.$refs.modalLoad.close();
                vm.ordenes_adomicilo.forEach((element, index) => {
                    if (element.id == vm.selectedOrden.id) {
                        vm.ordenes_adomicilo.splice(index, 1);
                    }
                });
                vm.ordenes_tienda.forEach((element, index) => {
                    if (element.id == vm.selectedOrden.id) {
                        vm.ordenes_tienda.splice(index, 1);
                    }
                });
                vm.ordenes_archivo.push(vm.selectedOrden);
                vm.selectedOrden = null;
            } catch (error) {
                console.log(error);
                vm.$refs.modalLoad.close();
                vm.selectedOrden = null;
            }
            this.cancelarArchivar();
        },
        ...mapActions({
            showLoading: "showLoading",
            hiddenLoading: "hiddenLoading",
            archiveOrden: "archiveOrden",
            deleteOrden: "deleteOrden",
        }),
    },
    mounted() {
        this.ordenes.forEach((element) => {
            if (element.shipping == 1) {
                element.archive = false;
                this.ordenes_adomicilo.push(element);
            } else if (element.shipping == 2) {
                element.archive = false;
                this.ordenes_archivo.push(element);
            } else if (element.shipping == 0) {
                element.archive = false;
                this.ordenes_tienda.push(element);
            }
        });
    },
};
</script>

<template>
    <div>
        <ul class="nav nav-tabs" role="tablist" id="myTab">
            <li
                class="nav-item nav-tabs-width-orders"
                role="presentation"
                style="border: 0">
                <a class="nav-link active"
                   id="ordenes-tab"
                   role="tab"
                   data-toggle="tab"
                   href="#ordenes">Órdenes a domicilio</a>
            </li>
            <li class="nav-item nav-tabs-width-orders"
                role="presentation"
                style="border: 0">
                <a class="nav-link"
                   id="tienda-tab"
                   role="tab"
                   data-toggle="tab"
                   href="#tienda">Pasa a tienda</a>
            </li>
            <li class="nav-item nav-tabs-width-orders"
                role="presentation"
                style="border: 0">
                <a class="nav-link"
                   id="archivo-tab"
                   role="tab"
                   data-toggle="tab"
                   href="#archivo">Archivo</a>
            </li>
            <li>
                <div class="input-group ml-2"
                     style="margin-top: 0.7rem !important">
                    <input
                        type="text"
                        class="form-control"
                        name="key"
                        id="key"
                        placeholder="Nombre cliente, numero orden."
                        aria-label="Nombre cliente, numero orden."
                        aria-describedby="button-addon2"
                        v-model="keyword"/>

                    <div class="input-group-append">
                        <button
                            :disabled="keyword == ''"
                            class="btn btn-outline-secondary"
                            id="button-addon2"
                            @click="searchOrder(keyword)">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div v-if="searchFlag == 1" class="input-group-append">
                        <a class="btn btn-outline-secondary"
                           id="button-addon1"
                           href="/administrador/ordenes">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
            </li>
        </ul>
        <div class="tab-content">
            <div
                class="tab-pane active white"
                style="padding: 10px"
                id="ordenes"
                role="tabpanel"
                aria-labelledby="ordenes-tab">
                <table class="table table-bordered" aria-describedby="">
                    <tr>
                        <th style="text-align: center">Horario de entrega</th>
                        <th style="text-align: center">Nombre quien recibe</th>
                        <th style="text-align: center">Teléfono</th>
                        <th style="text-align: center">Pago</th>
                        <th style="text-align: center">Estado de Pago</th>
                        <th style="text-align: center">Dirección</th>
                        <th style="text-align: center">Fecha de entrega</th>
                        <th style="text-align: center">Entregado</th>
                        <th style="text-align: center">Detalle</th>
                    </tr>
                    <tbody>
                    <tr v-for="(orden, index) in ordenes_adomicilo"
                        :key="`orden_adomicilio_${index}_${orden.id}`">
                        <td style="text-align: center">
                            {{ orden.delivery_period }}
                        </td>
                        <td style="text-align: center">
                            {{ orden.orderreceptor.first_name }}
                            {{ orden.orderreceptor.last_name }}
                        </td>
                        <td style="text-align: center">
                            {{ orden.orderreceptor.phone_number }}
                        </td>
                        <td style="text-align: center">
                            {{
                                orden.typepay == 1
                                    ? "Tarjeta"
                                    : orden.typepay == 2
                                        ? "Efectivo"
                                        : "Transferencia"
                            }}
                        </td>
                        <td style="text-align: center">
                            {{
                                orden.payment_status || 'N/A'
                            }}
                        </td>
                        <td style="text-align: center">
                            {{
                                orden.orderreceptor.municipalities.department.name
                            }},
                            {{ orden.orderreceptor.municipalities.name }},
                            {{ orden.orderreceptor.zones.name }},
                            {{ orden.orderreceptor.address }}
                        </td>
                        <td style="text-align: center">
                            {{ orden.orderreceptor.date  || 'N/A' }} {{ orden.orderreceptor.time  || '' }}
                        </td>
                        <td>
                            <center>
                                <label class="input-checkbox">
                                    <input
                                        type="checkbox"
                                        v-model="orden.archive"
                                        @click="changeArchivar(orden)"
                                    />
                                    <span class="input">
                                            <span
                                                class="icon icon-check"
                                            ></span>
                                        </span>
                                </label>
                            </center>
                        </td>
                        <td>
                            <center>
                                <a
                                    style="font-size: 12px"
                                    :href="
                                            '/administrador/orden/' + orden.id
                                        "
                                    class="btn btn-dark"
                                >
                                    <svg
                                        style="width: 19px"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="26.75"
                                        height="20"
                                        viewBox="0 0 26.75 20"
                                    >
                                        <g
                                            id="Icon_feather-eye"
                                            data-name="Icon feather-eye"
                                            transform="translate(-0.5 -5)"
                                        >
                                            <path
                                                id="Path_4546"
                                                data-name="Path 4546"
                                                d="M1.5,15S6,6,13.875,6,26.25,15,26.25,15s-4.5,9-12.375,9S1.5,15,1.5,15Z"
                                                fill="none"
                                                stroke="#fff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            />
                                            <path
                                                id="Path_4547"
                                                data-name="Path 4547"
                                                d="M20.25,16.875A3.375,3.375,0,1,1,16.875,13.5,3.375,3.375,0,0,1,20.25,16.875Z"
                                                transform="translate(-3 -1.875)"
                                                fill="none"
                                                stroke="#fff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            />
                                        </g>
                                    </svg>

                                    VER ORDEN
                                </a>
                            </center>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div
                class="tab-pane white"
                style="padding: 10px"
                id="tienda"
                role="tabpanel"
                aria-labelledby="tienda-tab"
            >
                <table class="table table-bordered">
                    <tr>
                        <th style="text-align: center">Horario de entrega</th>
                        <th style="text-align: center">Nombre quien recibe</th>
                        <th style="text-align: center">Teléfono</th>
                        <th style="text-align: center">Pago</th>
                        <th style="text-align: center">Estado de Pago</th>
                        <th style="text-align: center">
                            Fecha a recoger en tienda
                        </th>
                        <th style="text-align: center">Entregado</th>
                        <th style="text-align: center">Detalle</th>
                    </tr>
                    <tbody>
                    <tr v-for="(orden, index) in ordenes_tienda"
                        :key="`orden_adomicilio_${index}_${orden.id}`">
                        <td style="text-align: center">
                            {{ orden.delivery_period }}
                        </td>
                        <td style="text-align: center">
                            {{ orden.orderreceptor.first_name }}
                            {{ orden.orderreceptor.last_name }}
                        </td>
                        <td style="text-align: center">
                            {{ orden.orderreceptor.phone_number }}
                        </td>
                        <td style="text-align: center">
                            {{
                                orden.typepay == 1
                                    ? "Tarjeta"
                                    : orden.typepay == 2
                                        ? "Efectivo"
                                        : "Transferencia"
                            }}
                        </td>
                        <td style="text-align: center">
                            {{ orden.payment_status || 'N/A'}}
                        </td>
                        <td style="text-align: center">
                            {{ orden.orderreceptor.date  || 'N/A' }} {{ orden.orderreceptor.time  || '' }}
                        </td>
                        <td>
                            <center>
                                <label class="input-checkbox">
                                    <input
                                        type="checkbox"
                                        v-model="orden.archive"
                                        @change="changeArchivar(orden)"
                                    />
                                    <span class="input">
                                            <span
                                                class="icon icon-check"
                                            ></span>
                                        </span>
                                </label>
                            </center>
                        </td>
                        <td>
                            <center>
                                <a
                                    :href="
                                            '/administrador/orden/' + orden.id
                                        "
                                    style="font-size: 12px"
                                    class="btn btn-dark"
                                >
                                    <svg
                                        style="width: 19px"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="26.75"
                                        height="20"
                                        viewBox="0 0 26.75 20"
                                    >
                                        <g
                                            id="Icon_feather-eye"
                                            data-name="Icon feather-eye"
                                            transform="translate(-0.5 -5)"
                                        >
                                            <path
                                                id="Path_4546"
                                                data-name="Path 4546"
                                                d="M1.5,15S6,6,13.875,6,26.25,15,26.25,15s-4.5,9-12.375,9S1.5,15,1.5,15Z"
                                                fill="none"
                                                stroke="#fff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            />
                                            <path
                                                id="Path_4547"
                                                data-name="Path 4547"
                                                d="M20.25,16.875A3.375,3.375,0,1,1,16.875,13.5,3.375,3.375,0,0,1,20.25,16.875Z"
                                                transform="translate(-3 -1.875)"
                                                fill="none"
                                                stroke="#fff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            />
                                        </g>
                                    </svg>

                                    VER ORDEN
                                </a>
                            </center>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div
                class="tab-pane white"
                style="padding: 10px"
                id="archivo"
                role="tabpanel"
                aria-labelledby="archivo-tab"
            >
                <table class="table table-bordered">
                    <tr>
                        <th style="text-align: center">Nombre quien recibe</th>
                        <th style="text-align: center">Teléfono</th>
                        <th style="text-align: center">Pago</th>
                        <th style="text-align: center">
                            Fecha a recoger en tienda
                        </th>
                        <th style="text-align: center">Eliminar</th>
                        <th style="text-align: center">Detalle</th>
                    </tr>
                    <tbody>
                    <tr
                        v-for="(orden, index) in ordenes_archivo"
                        :key="`ordenes_archivo_${index}_${orden.id}`"
                    >
                        <td style="text-align: center">
                            {{ orden.orderreceptor.first_name }}
                            {{ orden.orderreceptor.last_name }}
                        </td>
                        <td style="text-align: center">
                            {{ orden.orderreceptor.phone_number }}
                        </td>
                        <td style="text-align: center">
                            {{
                                orden.typepay == 1
                                    ? "Tarjeta"
                                    : orden.typepay == 2
                                        ? "Efectivo"
                                        : "Transferencia"
                            }}
                        </td>
                        <td style="text-align: center">
                            {{ orden.orderreceptor.date  || 'N/A' }} {{ orden.orderreceptor.time  || '' }}
                        </td>
                        <td>
                            <center>
                                <svg
                                    style="cursor: pointer"
                                    @click="showAlerEliminar(orden)"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="33"
                                    viewBox="0 0 30 33"
                                >
                                    <g
                                        id="Icon_feather-trash-2"
                                        data-name="Icon feather-trash-2"
                                        transform="translate(-3 -1.5)"
                                    >
                                        <path
                                            id="Path_4711"
                                            data-name="Path 4711"
                                            d="M4.5,9h27"
                                            fill="none"
                                            stroke="#d10043"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="3"
                                        />
                                        <path
                                            id="Path_4712"
                                            data-name="Path 4712"
                                            d="M28.5,9V30a3,3,0,0,1-3,3h-15a3,3,0,0,1-3-3V9M12,9V6a3,3,0,0,1,3-3h6a3,3,0,0,1,3,3V9"
                                            fill="none"
                                            stroke="#d10043"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="3"
                                        />
                                        <path
                                            id="Path_4713"
                                            data-name="Path 4713"
                                            d="M15,16.5v9"
                                            fill="none"
                                            stroke="#d10043"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="3"
                                        />
                                        <path
                                            id="Path_4714"
                                            data-name="Path 4714"
                                            d="M21,16.5v9"
                                            fill="none"
                                            stroke="#d10043"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="3"
                                        />
                                    </g>
                                </svg>
                            </center>
                        </td>
                        <td>
                            <center>
                                <a
                                    :href="
                                            '/administrador/orden/' + orden.id
                                        "
                                    style="font-size: 12px"
                                    class="btn btn-dark"
                                >
                                    <svg
                                        style="width: 19px"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="26.75"
                                        height="20"
                                        viewBox="0 0 26.75 20"
                                    >
                                        <g
                                            id="Icon_feather-eye"
                                            data-name="Icon feather-eye"
                                            transform="translate(-0.5 -5)"
                                        >
                                            <path
                                                id="Path_4546"
                                                data-name="Path 4546"
                                                d="M1.5,15S6,6,13.875,6,26.25,15,26.25,15s-4.5,9-12.375,9S1.5,15,1.5,15Z"
                                                fill="none"
                                                stroke="#fff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            />
                                            <path
                                                id="Path_4547"
                                                data-name="Path 4547"
                                                d="M20.25,16.875A3.375,3.375,0,1,1,16.875,13.5,3.375,3.375,0,0,1,20.25,16.875Z"
                                                transform="translate(-3 -1.875)"
                                                fill="none"
                                                stroke="#fff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                            />
                                        </g>
                                    </svg>

                                    VER ORDEN
                                </a>
                            </center>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <vue-modal ref="modalEliminar" title="">
            <template v-slot:header></template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <center>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="81.945"
                            height="90.384"
                            viewBox="0 0 81.945 90.384"
                        >
                            <g
                                id="Icon_feather-trash-2"
                                data-name="Icon feather-trash-2"
                                transform="translate(3 3)"
                            >
                                <path
                                    id="Path_4732"
                                    data-name="Path 4732"
                                    d="M4.5,9H80.445"
                                    transform="translate(-4.5 7.877)"
                                    fill="none"
                                    stroke="#d10043"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4733"
                                    data-name="Path 4733"
                                    d="M66.569,19.877V78.945a8.438,8.438,0,0,1-8.438,8.438H15.938A8.438,8.438,0,0,1,7.5,78.945V19.877m12.658,0V11.438A8.438,8.438,0,0,1,28.6,3H45.473a8.438,8.438,0,0,1,8.438,8.438v8.438"
                                    transform="translate(0.938 -3)"
                                    fill="none"
                                    stroke="#d10043"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4734"
                                    data-name="Path 4734"
                                    d="M15,16.5V41.815"
                                    transform="translate(14.534 21.473)"
                                    fill="none"
                                    stroke="#d10043"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4735"
                                    data-name="Path 4735"
                                    d="M21,16.5V41.815"
                                    transform="translate(25.411 21.473)"
                                    fill="none"
                                    stroke="#d10043"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                            </g>
                        </svg>
                        <br/>
                        <p
                            style="
                                margin-top: 8px;
                                font-size: 20px;
                                font-weight: bold;
                            "
                        >
                            ¿Está seguro de eliminar <br/>esta orden?
                        </p>
                    </center>
                    <div
                        class="row"
                        style="margin-right: 0px; margin-left: 0px"
                    >
                        <div class="col-6" style="padding: 0px">
                            <svg
                                style="cursor: pointer"
                                @click="cancelarEliminar()"
                                id="Group_2419"
                                data-name="Group 2419"
                                xmlns="http://www.w3.org/2000/svg"
                                width="150"
                                height="60"
                                viewBox="0 0 150 60"
                            >
                                <g
                                    id="Rectangle_2020"
                                    data-name="Rectangle 2020"
                                    fill="#fff"
                                    stroke="#df438b"
                                    stroke-width="1"
                                >
                                    <path
                                        d="M0,0H150a0,0,0,0,1,0,0V60a0,0,0,0,1,0,0H10A10,10,0,0,1,0,50V0A0,0,0,0,1,0,0Z"
                                        stroke="none"
                                    />
                                    <path
                                        d="M1,.5H149a.5.5,0,0,1,.5.5V59a.5.5,0,0,1-.5.5H10A9.5,9.5,0,0,1,.5,50V1A.5.5,0,0,1,1,.5Z"
                                        fill="none"
                                    />
                                </g>
                                <text
                                    id="Cancelar"
                                    transform="translate(75 35)"
                                    fill="#df438b"
                                    font-size="19"
                                    font-family="SegoeUI-Bold, Segoe UI"
                                    font-weight="700"
                                >
                                    <tspan x="-38.079" y="0">Cancelar</tspan>
                                </text>
                            </svg>
                        </div>
                        <div class="col-6" style="padding: 0px">
                            <svg
                                @click="elminarOrden()"
                                style="cursor: pointer"
                                id="Group_2364"
                                data-name="Group 2364"
                                xmlns="http://www.w3.org/2000/svg"
                                width="150"
                                height="60"
                                viewBox="0 0 150 60"
                            >
                                <path
                                    id="Rectangle_2020"
                                    data-name="Rectangle 2020"
                                    d="M0,0H150a0,0,0,0,1,0,0V50a10,10,0,0,1-10,10H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                                    fill="#df438b"
                                />
                                <text
                                    id="Si_eliminar"
                                    data-name="Si, eliminar"
                                    transform="translate(73 35)"
                                    fill="#fff"
                                    font-size="18"
                                    font-family="SegoeUI-Bold, Segoe UI"
                                    font-weight="700"
                                >
                                    <tspan x="-47.18" y="0">Si, eliminar</tspan>
                                </text>
                            </svg>
                        </div>
                    </div>
                </div>
            </template>
        </vue-modal>
        <vue-modal ref="modalArchivar" title="" :showclosed="false">
            <template v-slot:header></template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <center>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="91.768"
                            height="91.768"
                            viewBox="0 0 91.768 91.768"
                        >
                            <path
                                id="Icon_metro-warning"
                                data-name="Icon metro-warning"
                                d="M48.455,10.242,86.909,86.885H10L48.455,10.242Zm0-8.314c-1.978,0-3.956,1.334-5.456,4L3.829,84c-3,5.335-.448,9.7,5.673,9.7h77.9c6.121,0,8.674-4.365,5.673-9.7h0L53.911,5.929c-1.5-2.668-3.478-4-5.456-4ZM54.19,76.489a5.735,5.735,0,1,1-5.735-5.735A5.736,5.736,0,0,1,54.19,76.489ZM48.455,65.018a5.736,5.736,0,0,1-5.735-5.735V42.076a5.735,5.735,0,1,1,11.471,0V59.283A5.736,5.736,0,0,1,48.455,65.018Z"
                                transform="translate(-2.571 -1.928)"
                                fill="#d10043"
                            />
                        </svg>

                        <br/>
                        <p
                            style="
                                margin-top: 8px;
                                font-size: 20px;
                                font-weight: bold;
                            "
                        >
                            Si activa esta opción<br/>su orden pasará a
                            Archivo.
                        </p>
                    </center>
                    <div
                        class="row"
                        style="margin-right: 0px; margin-left: 0px"
                    >
                        <div class="col-6" style="padding: 0">
                            <svg
                                @click="cancelarArchivar()"
                                style="cursor: pointer"
                                id="Group_2419"
                                data-name="Group 2419"
                                xmlns="http://www.w3.org/2000/svg"
                                width="150"
                                height="60"
                                viewBox="0 0 150 60"
                            >
                                <g
                                    id="Rectangle_2020"
                                    data-name="Rectangle 2020"
                                    fill="#fff"
                                    stroke="#df438b"
                                    stroke-width="1"
                                >
                                    <path
                                        d="M0,0H150a0,0,0,0,1,0,0V60a0,0,0,0,1,0,0H10A10,10,0,0,1,0,50V0A0,0,0,0,1,0,0Z"
                                        stroke="none"
                                    />
                                    <path
                                        d="M1,.5H149a.5.5,0,0,1,.5.5V59a.5.5,0,0,1-.5.5H10A9.5,9.5,0,0,1,.5,50V1A.5.5,0,0,1,1,.5Z"
                                        fill="none"
                                    />
                                </g>
                                <text
                                    id="Cancelar"
                                    transform="translate(75 35)"
                                    fill="#df438b"
                                    font-size="19"
                                    font-family="SegoeUI-Bold, Segoe UI"
                                    font-weight="700"
                                >
                                    <tspan x="-38.079" y="0">Cancelar</tspan>
                                </text>
                            </svg>
                        </div>
                        <div class="col-6" style="padding: 0px">
                            <svg
                                @click="Archive()"
                                style="cursor: pointer"
                                id="Group_2364"
                                data-name="Group 2364"
                                xmlns="http://www.w3.org/2000/svg"
                                width="150"
                                height="60"
                                viewBox="0 0 150 60"
                            >
                                <path
                                    id="Rectangle_2020"
                                    data-name="Rectangle 2020"
                                    d="M0,0H150a0,0,0,0,1,0,0V50a10,10,0,0,1-10,10H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                                    fill="#df438b"
                                />
                                <text
                                    id="Si_eliminar"
                                    data-name="Si, eliminar"
                                    transform="translate(73 35)"
                                    fill="#fff"
                                    font-size="18"
                                    font-family="SegoeUI-Bold, Segoe UI"
                                    font-weight="700"
                                >
                                    <tspan x="-35.18" y="0">Aceptar</tspan>
                                </text>
                            </svg>
                        </div>
                    </div>
                </div>
            </template>
        </vue-modal>
        <vue-modal ref="modalLoad" title="" :showclosed="false">
            <template v-slot:header></template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <center>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="94"
                            height="94"
                            viewBox="0 0 94 94"
                        >
                            <g
                                id="Group_2373"
                                data-name="Group 2373"
                                transform="translate(-5834.694 -10263.994)"
                            >
                                <path
                                    id="Path_4721"
                                    data-name="Path 4721"
                                    d="M18,3V20.6"
                                    transform="translate(5863.694 10263.994)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4722"
                                    data-name="Path 4722"
                                    d="M18,27V44.6"
                                    transform="translate(5863.694 10310.395)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4723"
                                    data-name="Path 4723"
                                    d="M7.4,7.4,19.847,19.847"
                                    transform="translate(5843.191 10272.491)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4724"
                                    data-name="Path 4724"
                                    d="M24.36,24.36,36.812,36.812"
                                    transform="translate(5875.99 10305.29)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4725"
                                    data-name="Path 4725"
                                    d="M3,18H20.6"
                                    transform="translate(5834.694 10292.994)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4726"
                                    data-name="Path 4726"
                                    d="M27,18H44.6"
                                    transform="translate(5881.094 10292.994)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4727"
                                    data-name="Path 4727"
                                    d="M7.4,36.812,19.847,24.36"
                                    transform="translate(5843.191 10305.29)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                                <path
                                    id="Path_4728"
                                    data-name="Path 4728"
                                    d="M24.36,19.847,36.812,7.4"
                                    transform="translate(5875.99 10272.491)"
                                    fill="none"
                                    stroke="#eb98c1"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="6"
                                />
                            </g>
                        </svg>

                        <h3>Espere, por favor...</h3>
                    </center>
                </div>
            </template>
        </vue-modal>
    </div>
</template>
