<script>
import { mapActions } from "vuex";
import balloonIcon from "../../svg/icon-balloons.svg";

const emailRegEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneWithDashRegEx = /^\+?(\d{1,4})\)?[-]?(\d{1,4})[-]?(\d{1,4})$/;
const phoneNoDashRegEx = /^\+?\d{1,12}$/;

export default {
    data() {
        return {
            balloonIcon,
            form: {
                name: "",
                email: "",
                phone: "",
                phone2: "",
                comments: "",
            },
            saving: false,
            exito: false,
            confirmationModal: false,
            errors: {
                name: false,
                email: false,
                money: false,
                phone: false,
                phone2: false,
                comments: false,
            },
        };
    },
    methods: {
        clearError() {
            this.errors = {
                otherevent: false,
                typeevent: false,
                numperson: false,
                othertheme: false,
                globos: false,
                flores: false,
                ambos: false,
                date: false,
                initime: false,
                finitime: false,
                name: false,
                email: false,
                money: false,
                phone: false,
                location: false,
                comments: false,
                presu: false,
            };
        },
        async handlerAddEvent() {
            this.clearError();

            this.validateForm();

            if (!this.hasErrors()) {
                this.showLoading();
                this.saving = true;
                try {
                    let result = await this.addContact(this.form);
                    this.confirmationModal = true;
                    console.log(result);
                    this.hiddenLoading();
                    this.resetForm();
                } catch (error) {
                    console.log(error);
                    this.hiddenLoading();
                }
            }
        },
        validateForm() {
            this.errors.name = this.form.name === "";
            this.errors.email = this.form.email === "";
            this.errors.phone = this.form.phone === "";

            if (this.form.phone2 !== "") {
                this.validatePhoneNumber(this.form.phone, "phone2");
            }
            this.validateEmail(this.form.email);
            this.validatePhoneNumber(this.form.phone, "phone1");
        },
        hasErrors() {
            return this.errors.email || this.errors.phone || this.errors.phone2;
        },
        validateEmail(email) {
            this.errors.email = !emailRegEx.test(email);
        },
        validatePhoneNumber(phone, key) {
            const isValid =
                phoneWithDashRegEx.test(phone) || phoneNoDashRegEx.test(phone);
            this.errors[key === "phone1" ? "phone" : "phone2"] = !isValid;
        },
        resetForm() {
            this.form = {
                name: "",
                email: "",
                phone: "",
                phone2: "",
                comments: "",
            };
        },
        ...mapActions({
            addContact: "addContact",
            showLoading: "showLoading",
            hiddenLoading: "hiddenLoading",
        }),
    },
};
</script>
<template>
    <div class="h-auto">
        <div class="h-auto row d-flex" style="height: 80vh">
            <div class="h-auto col-12 col-sm-6">
                <div class="form-events">
                    <div>
                        <h6>
                            Si posees alguna duda sobre nuestros servicios,
                            considera antes de escribirnos, darle un vistazo a
                            nuestra sección de
                            <b
                                ><a
                                    href="/faqs"
                                    style="
                                        text-decoration: underline;
                                        color: #eb98c1;
                                    "
                                    >Preguntas Frecuentes.</a
                                ></b
                            >
                        </h6>
                        <br />
                        <br />
                        <h6>
                            <b style="color: rgb(108 110 100)"
                                >Cuéntanos, ¿Cómo podemos ayudarte?</b
                            >
                        </h6>
                        <div class="row">
                            <div class="col-12 col-sm-6 py-2">
                                <input
                                    class="form-control"
                                    :class="errors.name ? 'is-invalid' : ''"
                                    type="text"
                                    placeholder="Nombre Completo"
                                    v-model="form.name"
                                />
                            </div>
                            <div class="col-12 col-sm-6 py-2">
                                <input
                                    class="form-control"
                                    :class="errors.email ? 'is-invalid' : ''"
                                    type="text"
                                    placeholder="Correo electrónico"
                                    v-model="form.email"
                                />
                            </div>

                            <div class="col-12 col-sm-6 py-2">
                                <input
                                    class="form-control"
                                    :class="errors.phone ? 'is-invalid' : ''"
                                    type="text"
                                    placeholder="Número de teléfono móvil"
                                    v-model="form.phone"
                                />
                            </div>
                            <div class="col-12 col-sm-6 py-2">
                                <input
                                    class="form-control"
                                    :class="errors.phone2 ? 'is-invalid' : ''"
                                    type="text"
                                    placeholder="Número de teléfono fijo (opcional)"
                                    v-model="form.phone2"
                                />
                            </div>
                            <div class="col-12 col-sm-12 py-2">
                                <textarea
                                    class="form-control"
                                    :class="errors.comments ? 'is-invalid' : ''"
                                    v-model="form.comments"
                                    placeholder="Cuéntanos más (opcional)"
                                    style="
                                        width: 100%;
                                        height: 30vh;
                                        padding: 20px;
                                    "
                                ></textarea>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex">
                        <button
                            @click="handlerAddEvent"
                            class="btn btn-lg btn-primary ml-auto"
                            :disabled="saving"
                        >
                            Enviar
                        </button>
                    </div>
                </div>
            </div>
            <div class="h-auto col-12 col-sm-6 p-4">
                <div class="form-events">
                    <div>
                        <h6><b>Horario de atención</b></h6>
                        <p>
                            Lunes a sábado: 9:00am - 4:00pm (Sin cerrar a medio
                            día). Domingo: Cerrado
                        </p>
                        <br />
                        <div class="row">
                            <div class="col-12 col-sm-4">
                                <div>
                                    <h6><b>Nuestras redes sociales</b></h6>
                                    <div class="d-flex">
                                        <a
                                            class="mx-1 social_icons"
                                            target="blank"
                                            href="https://www.facebook.com/eventosydetalleschalatenango"
                                        >
                                            <span
                                                class="icon-facebook h1"
                                            ></span>
                                        </a>
                                        <a
                                            class="mx-1 social_icons"
                                            target="blank"
                                            href="https://www.instagram.com/eventosdetallesinfo/"
                                        >
                                            <span
                                                class="icon-instagram h1"
                                            ></span>
                                        </a>
                                        <a
                                            class="mx-1 social_icons"
                                            target="blank"
                                            href="https://wa.me/50372512842?text=Hola,%20quisiera%20cotizar%20/%20agendar%20un%20pedido."
                                        >
                                            <span
                                                class="icon-whatsapp h1"
                                            ></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <div
                                    class="d-flex"
                                    style="flex-direction: column"
                                >
                                    <a
                                        href="mailto:info@eventosydetalles.com.sv"
                                        class="mx-1"
                                        style="
                                            font-size: 13px;
                                            align-items: center;
                                            justify-content: left;
                                            display: flex;
                                            color: #eb98c1;
                                        "
                                    >
                                        <span
                                            class="icon-email display-4"
                                            style="font-size: 20px"
                                        ></span
                                        >&nbsp;info@eventosydetalles.com.sv
                                    </a>
                                    <a
                                        class="mx-1"
                                        style="
                                            font-size: 12px;
                                            align-items: center;
                                            justify-content: left;
                                            display: flex;
                                            margin-top: 10px;
                                            color: #eb98c1;
                                        "
                                    >
                                        <span
                                            class="icon-phone display-4"
                                            style="font-size: 20px"
                                        ></span
                                        >&nbsp;+503 2335 2119 / 7251 2842
                                    </a>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <div class="d-flex">
                                    <a class="mx-1">
                                        <span
                                            class="icon-location display-4"
                                            style="
                                                font-size: 20px;
                                                color: #eb98c1;
                                            "
                                        ></span>
                                    </a>
                                    <a
                                        class="mx-1"
                                        style="color: #eb98c1; font-size: 13px"
                                        href="https://goo.gl/maps/LybFf9SictxxS5zB9"
                                        target="_blank"
                                    >
                                        Casa #28, Barrio San Antonio,4ta Calle
                                        Oriente, Chalatenango, El Salvador, C.A.
                                    </a>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 pt-4">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d967.6587385959224!2d-88.9366287!3d14.0396397!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTTCsDAyJzIzLjIiTiA4OMKwNTYnMDYuNiJX!5e0!3m2!1ses-419!2ssv!4v1679467999394!5m2!1ses-419!2ssv"
                                    style="border: 0; width: 100%; height: 31vh"
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--start notification modal -->
        <div class="modal" :class="{ showModal: confirmationModal }">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <p>
                            <center>
                                <img :src="balloonIcon" alt="Globos Icon" />
                            </center>
                        </p>
                        <center>
                            <div class="container">
                                <h4>
                                    <b
                                        >Tu mensaje ha sido enviado, nos
                                        contactaremos lo antes posible
                                        contigo.</b
                                    >
                                </h4>
                            </div>
                            <div>
                                <button
                                    @click="confirmationModal = false"
                                    class="btn btn-lg btn-primary ml-auto"
                                >
                                    Entendido
                                </button>
                            </div>
                        </center>
                    </div>
                </div>
            </div>
        </div>
        <!-- end notification modal -->
    </div>
</template>
<style lang="scss" scoped>
.showModal {
    display: list-item;
    opacity: 1;
    background: #707070a4;
}
</style>
