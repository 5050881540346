<script>
import { mapActions, mapGetters } from 'vuex'
import VueModal from './VueModal.vue'
export default{
    components: {
        VueModal
    },
    props: ['cupones'],
    data() {
        return {
            cupones:[],
            selectedCupon:null,
            messagesSuccess:'Los cambios se han <br>guardado exitosamente'
        }
    },
    methods:{
        cancelarEliminar(){
            this.$refs.modalEliminar.close();
        },
        async elminarCupon(){
            var vm=this;
            try {
                var cupon={
                    id:vm.selectedCupon.id
                }
                console.log('eliminar')
                vm.$refs.modalEliminar.close();
                vm.$refs.modalLoad.open();
                let {data} = await window.axios.post(`/administrador/coupones/delete`,cupon)
                vm.$refs.modalLoad.close();
                vm.$refs.modalsuccess.open();
            } catch (error) {
                vm.$refs.modalLoad.close();
                vm.selectedOrden=null
            }
        },
        closeSuccess(){
            window.location.reload();
        },
        showAlertEliminar(_cupon){
            this.$refs.modalEliminar.open();
            this.selectedCupon=_cupon;
        },
        ...mapActions({
            showLoading:'showLoading',
            hiddenLoading:'hiddenLoading',
            archiveOrden:'archiveOrden',
            deleteOrden:'deleteOrden'
        }),
    },
    mounted(){
    }
}
</script>

<template>
    <div class="col-12">
        <div class="tab-content">
            <div class="tab-pane active white" style="padding:10px" id="ordenes" role="tabpanel" aria-labelledby="ordenes-tab">
                <table class="table table-bordered">
                    <tr>
                        <th style="text-align:center">Nombre de promoción</th>
                        <th style="text-align:center">Cupón</th>
                        <th style="text-align:center">Descuento (%)</th>
                        <th style="text-align:center">Descuento ($)</th>
                        <th style="text-align:center">Fecha inicio</th>
                        <th style="text-align:center">Fecha final</th>
                        <th style="text-align:center">Usos máx.</th>
                        <th style="text-align:center">Usado</th>
                        <th style="text-align:center">Eliminar</th>
                    </tr>
                    <tbody>
                        <tr v-for="(cupon, index) in cupones" :key="`orden_adomicilio_${index}_${cupon.id}`">
                            <td style="text-align:center">{{cupon.name}}</td>
                            <td style="text-align:center">{{cupon.code}}</td>
                            <td style="text-align:center">{{cupon.is_percentage==1?cupon.discount+"%":'N/A'}}</td>
                            <td style="text-align:center">{{cupon.is_percentage==0?"$"+cupon.discount:'N/A'}}</td>
                            <td style="text-align:center">{{cupon.release_at}}</td>
                            <td style="text-align:center">{{cupon.expires_at}}</td>
                            <td style="text-align:center">{{cupon.uses}}</td>
                            <td style="text-align:center">{{cupon.useds}}</td>
                            <td>
                                <center>
                                    <svg style="cursor:pointer" @click="showAlertEliminar(cupon)" xmlns="http://www.w3.org/2000/svg" width="30" height="33" viewBox="0 0 30 33">
                                        <g id="Icon_feather-trash-2" data-name="Icon feather-trash-2" transform="translate(-3 -1.5)">
                                            <path id="Path_4711" data-name="Path 4711" d="M4.5,9h27" fill="none" stroke="#d10043" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                            <path id="Path_4712" data-name="Path 4712" d="M28.5,9V30a3,3,0,0,1-3,3h-15a3,3,0,0,1-3-3V9M12,9V6a3,3,0,0,1,3-3h6a3,3,0,0,1,3,3V9" fill="none" stroke="#d10043" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                            <path id="Path_4713" data-name="Path 4713" d="M15,16.5v9" fill="none" stroke="#d10043" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                            <path id="Path_4714" data-name="Path 4714" d="M21,16.5v9" fill="none" stroke="#d10043" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                        </g>
                                    </svg>
                                </center>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <vue-modal ref="modalEliminar" title="">
            <template v-slot:header>
            </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <center>
                        <svg xmlns="http://www.w3.org/2000/svg" width="81.945" height="90.384" viewBox="0 0 81.945 90.384">
                            <g id="Icon_feather-trash-2" data-name="Icon feather-trash-2" transform="translate(3 3)">
                                <path id="Path_4732" data-name="Path 4732" d="M4.5,9H80.445" transform="translate(-4.5 7.877)" fill="none" stroke="#d10043" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4733" data-name="Path 4733" d="M66.569,19.877V78.945a8.438,8.438,0,0,1-8.438,8.438H15.938A8.438,8.438,0,0,1,7.5,78.945V19.877m12.658,0V11.438A8.438,8.438,0,0,1,28.6,3H45.473a8.438,8.438,0,0,1,8.438,8.438v8.438" transform="translate(0.938 -3)" fill="none" stroke="#d10043" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4734" data-name="Path 4734" d="M15,16.5V41.815" transform="translate(14.534 21.473)" fill="none" stroke="#d10043" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4735" data-name="Path 4735" d="M21,16.5V41.815" transform="translate(25.411 21.473)" fill="none" stroke="#d10043" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                            </g>
                        </svg>
                        <br>
                        <p style="margin-top:8px;font-size:20px;font-weight: bold;">¿Está seguro de eliminar <br>este Cupón?</p>
                    </center>
                    <div class="row" style="margin-right: 0px;margin-left: 0px">
                        <div class="col-6" style="padding: 0px;">
                            <svg style="cursor:pointer"  @click="cancelarEliminar()" id="Group_2419" data-name="Group 2419" xmlns="http://www.w3.org/2000/svg" width="150" height="60" viewBox="0 0 150 60">
                                <g id="Rectangle_2020" data-name="Rectangle 2020" fill="#fff" stroke="#df438b" stroke-width="1">
                                    <path d="M0,0H150a0,0,0,0,1,0,0V60a0,0,0,0,1,0,0H10A10,10,0,0,1,0,50V0A0,0,0,0,1,0,0Z" stroke="none"/>
                                    <path d="M1,.5H149a.5.5,0,0,1,.5.5V59a.5.5,0,0,1-.5.5H10A9.5,9.5,0,0,1,.5,50V1A.5.5,0,0,1,1,.5Z" fill="none"/>
                                </g>
                                <text id="Cancelar" transform="translate(75 35)" fill="#df438b" font-size="19" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="-38.079" y="0">Cancelar</tspan></text>
                            </svg>
                        </div>
                        <div class="col-6" style="padding: 0px;">
                            <svg @click="elminarCupon()" style="cursor:pointer" id="Group_2364" data-name="Group 2364" xmlns="http://www.w3.org/2000/svg" width="150" height="60" viewBox="0 0 150 60">
                                <path id="Rectangle_2020" data-name="Rectangle 2020" d="M0,0H150a0,0,0,0,1,0,0V50a10,10,0,0,1-10,10H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" fill="#df438b"/>
                                <text id="Si_eliminar" data-name="Si, eliminar" transform="translate(73 35)" fill="#fff" font-size="18" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="-47.18" y="0">Si, eliminar</tspan></text>
                            </svg>
                        </div>
                    </div>
                </div>
            </template>
        </vue-modal>
        <vue-modal ref="modalLoad" title="" :showclosed="false">
            <template v-slot:header>
            </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <center>
                        <svg xmlns="http://www.w3.org/2000/svg" width="94" height="94" viewBox="0 0 94 94">
                            <g id="Group_2373" data-name="Group 2373" transform="translate(-5834.694 -10263.994)">
                                <path id="Path_4721" data-name="Path 4721" d="M18,3V20.6" transform="translate(5863.694 10263.994)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4722" data-name="Path 4722" d="M18,27V44.6" transform="translate(5863.694 10310.395)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4723" data-name="Path 4723" d="M7.4,7.4,19.847,19.847" transform="translate(5843.191 10272.491)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4724" data-name="Path 4724" d="M24.36,24.36,36.812,36.812" transform="translate(5875.99 10305.29)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4725" data-name="Path 4725" d="M3,18H20.6" transform="translate(5834.694 10292.994)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4726" data-name="Path 4726" d="M27,18H44.6" transform="translate(5881.094 10292.994)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4727" data-name="Path 4727" d="M7.4,36.812,19.847,24.36" transform="translate(5843.191 10305.29)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4728" data-name="Path 4728" d="M24.36,19.847,36.812,7.4" transform="translate(5875.99 10272.491)" fill="none" stroke="#eb98c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                            </g>
                        </svg>

                        <h3>Espere, por favor...</h3>
                    </center>
                </div>
            </template>
        </vue-modal>
        <vue-modal ref="modalsuccess" title="" :showclosed="false">
            <template v-slot:header>
            </template>
            <template v-slot:body>
                <div style="min-width: 200px">
                    <center>
                        <svg xmlns="http://www.w3.org/2000/svg" width="91.287" height="90.147" viewBox="0 0 91.287 90.147">
                            <g id="Icon_feather-check-circle" data-name="Icon feather-check-circle" transform="translate(0.008 0.058)">
                                <path id="Path_4715" data-name="Path 4715" d="M87.036,41.169v3.866A42.018,42.018,0,1,1,62.12,6.63" transform="translate(0 0)" fill="none" stroke="#05ff8a" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                                <path id="Path_4716" data-name="Path 4716" d="M68.124,6,26.105,48.06,13.5,35.455" transform="translate(18.913 5.42)" fill="none" stroke="#05ff8a" stroke-linecap="round" stroke-linejoin="round" stroke-width="6"/>
                            </g>
                        </svg>
                        <p style="margin-top:8px;font-size:20px;font-weight: bold;" v-html="messagesSuccess"></p>
                    </center>
                    <div class="row" style="margin-right: 0px;margin-left: 0px">
                        <div class="col-12" style="padding: 0px;">
                            <svg style="cursor:pointer" @click="closeSuccess()" xmlns="http://www.w3.org/2000/svg" width="316" height="60" viewBox="0 0 316 60">
                                <g id="Group_2368" data-name="Group 2368" transform="translate(-802 -633)">
                                    <path id="Rectangle_2020" data-name="Rectangle 2020" d="M0,0H316a0,0,0,0,1,0,0V50a10,10,0,0,1-10,10H10A10,10,0,0,1,0,50V0A0,0,0,0,1,0,0Z" transform="translate(802 633)" fill="#df438b"/>
                                    <text id="Aceptar" transform="translate(960 669)" fill="#fff" font-size="20" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="-36.694" y="0">Aceptar</tspan></text>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </template>
        </vue-modal>
    </div>
</template>