<template>
    <div v-if="isContentVisible">
        <div class="row d-flex"
             style="display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;">
            <img src="/images/successimg.png"
                alt="Orden completada"
                style="width: 320px; margin: 39px"/>
            <h3 style="font-weight: bold; text-align: center">¡Muchas gracias por tu compra!</h3>
            <p>Tu número de orden es</p>
            <p style="font-size: 50px; font-weight: bold">{{ orderId }}</p>
            <p style="text-align: center; width: 50%">
                Está siendo procesada y nos pondremos en contacto contigo lo
                antes posible para hacerte la entrega. Un correo de confirmación
                se te será enviado.
            </p>
            <a class="btn btn-lg btn-primary" href="/">Ir a Inicio</a>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            intervalId: null,
            orderId: null,
            orderProcessed: false,
            isContentVisible: false,
        };
    },
    props: {
        orderId: Number,
    },
    methods: {
        ...mapActions([
            'showLoading',
            'hiddenLoading'
        ]),
        async consultarEstadoOrden() {
            try {
                const response = await window.axios.get(`/payment/check/${this.orderId}`);
                if (response.data.order_status === 'PAGO_EXITOSO' && response.data.payment_status === 'ExitosaAprobada') {
                    this.orderProcessed = true;
                    clearInterval(this.intervalId);
                    await this.hiddenLoading();
                    await this.hiddenLoading();
                    this.isContentVisible = true;
                }
            } catch (error) {
                console.error("Error al consultar el estado de la orden: ", error);
            }
        },
    },

    mounted() {
        this.showLoading();
        this.intervalId = setInterval(this.consultarEstadoOrden, 3000);
    },

    beforeDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }
};
</script>
