import { render, staticRenderFns } from "./CarrouselComponent.vue?vue&type=template&id=1eadef96&"
import script from "./CarrouselComponent.vue?vue&type=script&lang=js&"
export * from "./CarrouselComponent.vue?vue&type=script&lang=js&"
import style0 from "./CarrouselComponent.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports