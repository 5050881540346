<script>
import {mapActions, mapGetters} from "vuex";
import {email, helpers, required} from 'vuelidate/lib/validators';
import {validationMixin} from 'vuelidate';

export default {
    name: 'PaymentForm',
    mixins: [validationMixin],
    data() {
        return {
            //Variables para calcular monto total de la orden.
            total: 0,
            shippingCost: 0,
            globalDiscount: 0,
            //Fin variables para calcular montos
            isinputdata: true,
            isvalitateinfo: false,
            ispay: false,
            isfinish: false,
            zone: {},
            errorMessage: '',
            form: {
                email: '',
                phone_number: '',
                shipping: true,
                typepay: 1,
                receptor_name: "",
                receptor_lastname: "",
                receptor_location_name: "",
                receptor_city_id: "",
                receptor_municipalities_id: "",
                receptor_department_id: "",
                receptor_adress: "",
                receptor_phone_number: '',
                shipping_time_id: "",
                from: "",
                to: "",
                message: "",
                want_contact: false,
                subscribe: false,
                date_1: false,
                date_2: false,

                receptor_date: "",
                receptor_time: "",

                //data credicard
                name_holder: "",
                cardnumber: "",
                expiredate: "",
                ccv: "",
                token: "",
                monto: 0,
                envio: 0,

                cardpayment: {
                    idTransaccion: "",
                    esReal: false,
                    esAprobada: false,
                    codigoAutorizacion: "",
                    mensaje: "",
                    formaPago: "",
                    monto: 0,
                },

                cart: [],
                delivery_period: 'AM',
            },
            errors: {
                email: false,
                phone_number: false,
                shipping: false,
                typepay: 1,
                receptor_name: false,
                receptor_lastname: false,
                receptor_location_name: false,
                receptor_city_id: false,
                receptor_municipalities_id: false,
                receptor_department_id: false,
                receptor_adress: false,
                receptor_phone_number: false,
                shipping_time_id: false,
                from: false,
                to: false,
                message: false,
                want_contact: false,
                subscribe: false,
                date_1: false,
                date_2: false,

                receptor_date: false,
                receptor_time: false,

                //data credicard
                name_holder: false,
                cardnumber: false,
                expiredate: false,
                ccv: false,

                delivery_period: false,
            },
            validCupon: true,
            coupon: "",
            couponsApplied: [],
            appliedSuccess: false,
            alreadyApplied: false,
            clientIpAddress: "",
        };
    },

    validations: {
        form: {
            email: {
                required,
                email
            },
            phone_number: {
                required,
                phoneNumber: helpers.regex('phoneNumberRegex', /^\(?([0-9]{4})\)?[-]?([0-9]{4})$/)
            },
            receptor_phone_number: {
                required,
                phoneNumber: helpers.regex('phoneNumberRegex', /^\(?([0-9]{4})\)?[-]?([0-9]{4})$/)
            }
        }
    },

    computed: {
        ...mapGetters({
            cart: "cart",
            department: "department",
            municipalities: "municipalities",
            zones: "zones",
            tokenwompi: "tokenwompi",
            paywompi: "paywompi",
            order: "order",
        }),
        grandTotal() {
            return this.getTotalAfterDiscounts(this.total, this.shippingCost, this.globalDiscount);
        },
    },
    methods: {
        calculateTotal() {
            this.total = this.cart.cart.reduce((total, product) => {
                if (product.id !== 38) {
                    const price = parseFloat(product.price);
                    total += price * product.qty;
                }
                return total;
            }, 0);
        },
        calculateShippingCost() {
            // TODO: el valor del costo de envio fuera de horario se tenia quemado, lo recomendable es configurarlo ($3)
            const additionalShippingCost = (this.form.date_1 || this.form.date_2) ? 3 : 0;
            const selectedZone = this.zones.find(zone => zone.id === this.form.receptor_city_id);
            this.shippingCost = selectedZone ? parseFloat(selectedZone.price_shipping) + additionalShippingCost : additionalShippingCost;
        },
        getTotalAfterDiscounts(total, shipping, globalDisc) {
            let finalTotal = total - globalDisc;
            return finalTotal < 0 ? 0 : finalTotal + shipping;
        },
        calculateTotalAndShipping() {
            this.calculateTotal();
            this.calculateShippingCost();
            this.form.monto = this.getTotalAfterDiscounts(this.total, this.shippingCost, this.globalDiscount);
        },
        updateTotalAndSubtotal() {
            this.calculateTotalAndShipping();
            this.form.monto = this.getTotalAfterDiscounts(this.total, this.shippingCost, this.globalDiscount);
        },
        // Asegúrate de que lodash está importado o disponible globalmente como `_`
        debounceUpdateCart: window._.debounce(async function() {
            try {
                const products = this.cart.cart;
                await this.updateCart({ products });
                this.updateTotalAndShipping(); // Recalcular el total y el envío después de actualizar el carrito
            } catch (error) {
                console.error("Error updating cart:", error);
            }
        }, 2000),

        nombreProductosConcatenados(cart) {
            return cart.map(product => product.name).join(', ');
        },

        diminish(p) {
            p.qty -= 1;
            this.debounceUpdateCart();
        },
        validateTime(time) {
            const storeOpeningTime = "08:00";
            const storeClosingTime = "17:00";

            if (time < storeOpeningTime || time > storeClosingTime) {
                this.errors.receptor_time = "La hora seleccionada está fuera del horario de la tienda.";
                return false;
            } else {
                this.errors.receptor_time = "";
                return true;
            }
        },

        updateShippingCost() {
            const additionalShippingCost = (this.form.date_1 || this.form.date_2) ? 3 : 0;

            const selectedZone = this.zones.find(zone => zone.id === this.form.receptor_city_id);
            const baseShippingCost = selectedZone ? parseFloat(selectedZone.price_shipping) : 0;

            this.cart.shipping = baseShippingCost + additionalShippingCost;
            this.form.envio = baseShippingCost + additionalShippingCost;

            //Nuevo metodo para actualizar los datos
            this.updateTotalAndSubtotal();

            this.$forceUpdate();
        },

        updateTotalAndShipping() {
            this.calculateTotal();
            this.calculateShippingCost();
            this.form.monto = this.getTotalAfterDiscounts(this.total, this.shippingCost, this.globalDiscount);
            this.form.envio = this.shippingCost;
            this.cart.shipping = this.shippingCost;

            this.$forceUpdate();
        },

        cleanErrors() {
            this.errors.email = false;
            this.errors.phone_number = false;
            this.errors.shipping = false;
            this.errors.typepay = 1;
            this.errors.receptor_name = false;
            this.errors.receptor_lastname = false;
            this.errors.receptor_location_name = false;
            this.errors.receptor_city_id = false;
            this.errors.receptor_municipalities_id = false;
            this.errors.receptor_department_id = false;
            this.errors.receptor_adress = false;
            this.errors.receptor_phone_number = false;
            this.errors.shipping_time_id = false;
            this.errors.from = false;
            this.errors.to = false;
            this.errors.message = false;
            this.errors.want_contact = false;
            this.errors.subscribe = false;
            this.errors.date_1 = false;
            this.errors.date_2 = false;

            //data credicard
            this.errors.name_holder = false;
            this.errors.cardnumber = false;
            this.errors.expiredate = false;
            this.errors.ccv = false;

            this.errors.delivery_period = false;
        },

        increase(p) {
            p.qty += 1;
            this.debounceUpdateCart();
        },

        addProductOutOfDate() {
            let productOutOfDate = {
                id: 38,
                date: "2025-04-14",
                name: "Envío fuera de hora",
                qty: 1,
                thumbnail: "/storage//products/out_date.svg",
                price: "3.00",
                isShipping: true
            };

            if (this.form.date_1 || this.form.date_2) {
                this.addToCart(productOutOfDate);
            } else {
                this.removeFromCart(productOutOfDate);
            }

            this.updateTotalAndShipping(); // Recalcular el total y el envío después de agregar/eliminar el producto de envío fuera de hora
        },

        //?validating the ip address and coupon
        async getIpAddressFromAmazon() {
            axios
                .get("/get-ip")
                .then((res) => {
                    this.clientIpAddress = res.data.ip;
                })
                .catch(function (err) {
                    console.error(err);
                });
        },

        //?apply coupon func
        async applyCoupon() {
            if (this.couponsApplied.includes(this.coupon)) {
                this.setCouponState(true, false, false); // Cupón ya aplicado
                return;
            }

            try {
                const res = await this.validateAndApplyCoupon(this.coupon, this.clientIpAddress);

                if (res.isValid) {
                    this.couponsApplied.push(this.coupon);
                    this.createElementDiscount(res.code, res.isPercentage, res.discount);
                    this.updateTotalAndShipping(); // Recalcular el total y el envío después de aplicar el cupón
                    this.setCouponState(false, true, true); // Cupón aplicado con éxito
                } else {
                    this.setCouponState(false, false, false); // Cupón inválido
                }
            } catch (err) {
                console.error(err);
                this.setCouponState(false, false, false); // Error al aplicar el cupón
            }
        },
        async validateAndApplyCoupon(coupon, ipAddress) {
            const data = new FormData();
            data.append("coupon", coupon);
            data.append("ip_client", ipAddress);
            const res = await axios.post("/coupon", data);
            const { useds, uses, code, is_percentage, discount } = res.data.coupon[0];
            const usedsInt = parseInt(useds, 10);
            const usesInt = parseInt(uses, 10);
            const discountParse = parseFloat(discount);

            const descuentoRedondeado = discountParse.toFixed(2);

            return {
                isValid: !isNaN(usedsInt) && !isNaN(usesInt) && usedsInt < usesInt,
                code,
                isPercentage: is_percentage,
                discount: descuentoRedondeado
            };
        },


        setCouponState(alreadyApplied, validCoupon, appliedSuccess) {
            this.alreadyApplied = alreadyApplied;
            this.validCupon = validCoupon;
            this.appliedSuccess = appliedSuccess;
        },


        createElementDiscount(code, typeDisc, discountVal) {
            // Calcula el descuento basado en el tipo
            const desc = typeDisc == 1
                ? this.cart.total * (discountVal / 100)
                : discountVal;

            // Actualiza el descuento global con precisión de 2 decimales
            this.globalDiscount += Math.round(desc * 100) / 100;

            // Si Vue.js está manejando tu UI, actualiza una lista de descuentos en tu data
            // y deja que Vue actualice el DOM. Por ejemplo:
            // this.coupons.push({ code, discount: desc.toFixed(2) });

            // Si necesitas manipular el DOM directamente:
            this.appendDiscountElement(code, desc);
        },

        appendDiscountElement(code, desc) {
            const element = `
            <div class="d-flex">
                <div><p class="m-0">${code}</p></div>
                <div class="flex-grow-1 text-right"><p class="m-0">-$${desc}</p></div>
            </div>`;
            document.getElementById("promoCodesApplied").innerHTML += element;
        },

        //?getting total after disc to avoid negative values
        getMunicipalitiesByDepartment() {
            this.form.receptor_municipalities_id = "";
            this.getMunicipalities(this.form.receptor_department_id);
        },

        getZonesByMunicipalities() {
            this.form.receptor_city_id = "";
            this.getZones(this.form.receptor_municipalities_id);
        },

        getPricesByZone() {
            const additionalShippingCost = this.cart.cart.some(item => item.id === 38) ? 5 : 0;
            const selectedZone = this.zones.find(zone => zone.id === this.form.receptor_city_id);

            if (selectedZone) {
                const totalShippingCost = parseFloat(selectedZone.price_shipping) + additionalShippingCost;
                this.cart.shipping = totalShippingCost;
                this.form.envio = totalShippingCost;
            } else {
                this.cart.shipping = additionalShippingCost;
                this.form.envio = additionalShippingCost;
            }

            // Si es necesario actualizar el costo de envío en otros lugares o realizar cálculos adicionales
            this.updateShippingCost();
        },

        /*
        * Validaciones
        * */
        // Validaciones individuales
        isSunday(date) {
            const day = new Date(date).getDay();
            return day === 6; // 6 es domingo
        },

        isEmpty(value) {
            return value == "";
        },

        isFieldLengthInvalid(value, length) {
            return value.length < length || value.length > length;
        },

        // Validaciones principales
        validateBasicFields() {
            const basicFields = [
                'email', 'phone_number', 'receptor_name', 'receptor_lastname', 'receptor_phone_number', 'from', 'to', 'receptor_date'
            ];
            for (let field of basicFields) {
                if (this.isEmpty(this.form[field])) {
                    this.errors[field] = true;
                    return false; // si un campo está vacío, retorna error
                }
            }

            return true;
             // no hay error
        },

        validateFormatField(){
            //Se valida formato de campos
            const isEmailValid = this.validateEmail(this.form.email);
            const isPhoneValid = this.validatePhoneNumber(this.form.phone_number, "0");
            const isReceptorPhoneValid = this.validatePhoneNumber(this.form.receptor_phone_number, "1");

            this.errors.email = !isEmailValid;
            this.errors.phone_number = isPhoneValid;
            this.errors.receptor_phone_number = isReceptorPhoneValid;

            return !(!isEmailValid || !isPhoneValid || !isReceptorPhoneValid);
        },

        validateShippingFields() {
            if (this.form.shipping == false) {
                return true;
            }
            const shippingFields = [
                'receptor_department_id', 'receptor_municipalities_id', 'receptor_city_id', 'receptor_adress', 'delivery_period'
            ];
            for (let field of shippingFields) {
                if (this.isEmpty(this.form[field])) {
                    this.errors[field] = true;
                    return false; // si un campo relacionado con el envío está vacío, retorna error
                }
            }
            return true; // validación exitosa
        },

        validateReceptionFields() {
            if (this.form.shipping == true) {
                return true;
            }
            const receptionFields = ['receptor_time'];
            for (let field of receptionFields) {
                if (this.isEmpty(this.form[field])) {
                    this.errors[field] = true;
                    return false; // si un campo relacionado con la recepción está vacío, retorna error
                }
            }

            if (this.isSunday(this.form.receptor_date)) {
                this.errors.receptor_date = true;
                this.errorMessage = 'Los domingos no están disponibles. Por favor, selecciona otra fecha.';
                return false; // si la fecha seleccionada es un domingo, retorna error
            }

            return true; // validación exitosa
        },

        validateCardFields() {
            if (this.form.typepay !== 0) {//TODO: Temporalmente se permite pasar al tipo pago con tarjeta. valor anterior 1, valor nuevo 0
                return true;
            }
            const cardFields = ['name_holder', 'cardnumber', 'expiredate', 'ccv'];
            for (let field of cardFields) {
                if (this.isEmpty(this.form[field])) {
                    this.errors[field] = true;
                    return false;
                }
            }
            if (this.isFieldLengthInvalid(this.form.ccv, 3)) {
                this.errors.ccv = true;
                return false;
            }
            if (this.isFieldLengthInvalid(this.form.expiredate, 7)) {
                this.errors.expiredate = true;
                return false;
            }
            return true;
        },

        valid() {
            this.cleanErrors();

            return this.validateBasicFields() &&
                (this.form.shipping ? this.validateShippingFields() : this.validateReceptionFields()) &&
                (!this.ispay || this.validateCardFields());
        },

        clearFieldsIsShipping(){
            //this.form.receptor_date = "";
            this.form.receptor_time = "";
        },

        clearFieldsIsStorePickup(){
            //this.form.delivery_period = "";
            this.form.receptor_department_id = "";
            this.form.receptor_municipalities_id = "";
            this.form.receptor_city_id = "";
            this.form.receptor_adress = "";
        },
        async updateCouponsApplied(coupons) {
            for (const element of coupons) {
                const data = new FormData();
                data.append("coupon", element);
                data.append("ip_add", this.clientIpAddress);

                try {
                    const response = await axios.post("/coupon/used", data);
                    console.log(response.data);
                } catch (err) {
                    console.error(err);
                    break;
                }
            }
        },

        async handlerContinue() {
            //this.cleanErrors();

            this.$v.form.$touch()
            if (this.$v.form.$invalid) {
                // Mostrar un mensaje de error o realizar alguna acción adicional
                return
            }

            const valid = this.valid();
            if (valid == false) {
                return;
            }

            if(this.form.shipping == true){
                this.clearFieldsIsShipping();
            }  else {
                this.clearFieldsIsStorePickup();
            }
            if (this.isinputdata == true) {
                this.isinputdata = false;
                this.isvalitateinfo = true;
            } else if (this.isvalitateinfo == true) {
                this.isinputdata = false;
                this.isvalitateinfo = false;
                this.ispay = true;
            } else if (this.ispay) {

                if (this.form.typepay == 3) {
                    await this.showDeposite();
                    this.form.typepay = 4;
                    return;
                }

                let vm = this;
                vm.hiddenDeposite();
                await this.showLoading();
                try {
                    vm.form.monto = this.getTotalAfterDiscounts(
                        this.cart.total,
                        this.form.envio,
                        this.globalDiscount
                    );
                    vm.form.envio = this.cart.shipping;
                    vm.cart.shipping = vm.form.envio;

                    vm.form.cart = vm.cart.cart;
                    vm.form.from = vm.form.from == ""
                        ? vm.form.receptor_name +
                        " " +
                        vm.form.receptor_lastname
                        : vm.form.from;
                    await this.updateCouponsApplied(this.couponsApplied);

                    //TODO: aqui se debe agregar llamada a enlace de pago
                    if (vm.form.typepay == 1) {
                        const orderId = await vm.addOrder(vm.form);
                        const data = this.loadComponentWompi(this.form, this.cart.cart);
                        const link = await this.createPaymentLink({
                            orderId,
                            ...data,
                        });
                        vm.hiddenLoading();
                        window.location.replace(link);
                    } else {
                        await vm.addOrder(vm.form);

                        vm.isfinish = true;
                        vm.hiddenLoading();
                    }
                } catch (ex) {
                    vm.hiddenLoading();
                    console.error(ex);
                    if (vm.form.typepay == 4) {
                        vm.form.typepay = 3;
                    }
                }
            }
        },

        async createPaymentLink(data) {
            this.isLoading = true;
            try {
                const response = await window.axios.post('payment/create-link', {
                    orderId: data.orderId,
                    totalAmount: data.monto,
                    productName: data.nombreProducto,
                    email: data.email,
                    telefono: data.telefono,
                });
                return response.data.paymentLink;
            } catch (error) {
                console.error("Error creating payment link: ", error);
                this.error = "Error al crear el enlace de pago.";
            } finally {
                this.isLoading = false;
            }
        },

        //cargar componente para pasarela de pagos
        loadComponentWompi(form, cart){
            //Bloque para enviar valores de pago si es con tarjeta...
            const montoTotal = this.getTotalAfterDiscounts(
                this.cart.total, form.envio, this.globalDiscount
            );
            const nombreProductos = this.nombreProductosConcatenados(cart);

            // Actualiza el estado para reflejar los valores correctos
            return {
                monto: montoTotal,
                nombreProducto: nombreProductos,
                email: form.email,
                telefono: form.phone_number
            };
        },

        //? validate right email format
        validateEmail(email) {
            const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return String(email).match(emailRegEx) !== null;
        },

        //? validate phone number format
        validatePhoneNumber(phone, flag) {
            const phoneWithDashRegEx = /^\(?([0-9]{4})\)?[-]?([0-9]{4})$/;
            const phoneNoDashRegEx = /^\(?([0-9]{8})\)$/;
            if (!(
                    String(phone).match(phoneWithDashRegEx) ||
                    String(phone).match(phoneNoDashRegEx)
            )) {
                if (flag == "1") {
                    this.errors.receptor_phone_number = true;
                    return false;
                } else {
                    this.errors.phone_number = true;
                    return false;
                }
            } else {
                if (flag == "1") {
                    this.errors.receptor_phone_number = false;
                    return true;
                } else {
                    this.errors.phone_number = false;
                    return true;
                }
            }
        },

        changeInfo() {
            this.isinputdata = true;
            this.isvalitateinfo = false;
        },

        addCharacterExpireDate() {
            if (this.form.expiredate.length == 2) {
                this.form.expiredate = this.form.expiredate + "/";
            }
        },

        gotoinfo() {
            if (
                this.isinputdata == true ||
                this.isvalitateinfo == true ||
                this.ispay == true
            ) {
                this.isinputdata = true;
                this.isvalitateinfo = false;
                this.ispay == false;
            }
        },
        gotovalid() {
            if (this.isvalitateinfo == true || this.ispay == true) {
                this.isinputdata = false;
                this.isvalitateinfo = true;
                this.ispay = false;
            }
        },
        displayAddress(id, key) {
            let fullAddress = "";
            if (key == "dep") {
                this.department.map((e) => {
                    if (e.id == id) {
                        fullAddress += e.name + ", ";
                    }
                });
            } else if (key == "muni") {
                this.municipalities.map((e) => {
                    if (e.id == id) {
                        fullAddress += e.name + ", ";
                    }
                });
            } else if (key == "zone") {
                this.zones.map((e) => {
                    if (e.id == id) {
                        fullAddress += e.name + ", ";
                    }
                });
            }
            return fullAddress;
        },
        gotopay() {
            if (this.ispay == true) {
                this.isinputdata = false;
                this.isvalitateinfo = false;
                this.ispay = true;
            }
        },
        ...mapActions({
            getCart: "getCart",
            updateCart: "updateCart",
            addToCart: "addToCart",
            removeFromCart: "removeFromCart",
            getDepartment: "getDepartment",
            getMunicipalities: "getMunicipalities",
            getZones: "getZones",
            WompiAuth: "WompiAuth",
            WompiPay: "WompiPay",
            showLoading: "showLoading",
            hiddenLoading: "hiddenLoading",
            showDeposite: "showDeposite",
            hiddenDeposite: "hiddenDeposite",
            addOrder: "addOrder",
        }),
    },
    async mounted() {
        await this.getCart();
        await this.getDepartment();
        if (this.cart.cart.length == 0) {
            window.location.href = "/";
        }
        await this.getIpAddressFromAmazon();
        this.updateTotalAndShipping();
        this.calculateTotalAndShipping();
    },
    watch: {
        'total': function(newVal) {
            console.log(`total: ${newVal}`);
            this.updateTotalAndShipping();
        },
        'cart.shipping': function(newVal) {
            this.form.envio = newVal;
            this.$forceUpdate();
        },
        'grandTotal': function(newVal) {
            this.form.monto = newVal;
            this.$forceUpdate();
        },
        'form.shipping': function (newVal) {
            if (newVal) {
                // Transferencia bancaria por defecto
                this.form.typepay = 3;
            } else {
                this.form.envio = 0;
            }
            console.log('Se ejecuta form.shipping...');
            this.updateTotalAndShipping();
        },
        'form.receptor_time': function (newTime) {
            this.validateTime(newTime);
        },
        'form.receptor_date': function(newDate) {
            if(newDate){
                const selectedDate = new Date(newDate);
                console.log('Dia seleccionado: ' + selectedDate.getDay());
                if (selectedDate.getDay() === 6) {  // 0 representa domingo
                    this.errors.receptor_date = true;
                    // Puedes poner un mensaje de error más descriptivo si lo deseas
                    this.errorMessage = "Lo sentimos, podemos realizar tu entrega entre lunes y sábado, favor seleccionar otra fecha.";
                    //this.form.receptor_date = '';  // Limpiar el campo de fecha si lo prefieres
                    return false;
                } else {
                    this.errors.receptor_date = false;
                    return true;
                }
            } else {
                this.errors.receptor_date = false;
                return true;
            }
        },
        'cart.cart': {
            handler() {
                this.updateTotalAndShipping();
            },
            deep: true
        },
        'form.typepay': function (newValue, oldValue) {
            if (newValue === 1) {
                // Código para manejar la selección de pago con tarjeta
                this.loadComponentWompi(this.form, this.cart.cart)
            }
        }
    },
};
</script>
<template>
    <div>
        <div class="row d-flex" v-if="!isfinish">
            <div
                class="col-12 col-md-6 p-5"
                style="overflow-x: scroll; height: 100vh"
                id="no-scrollbar"
            >
                <ul class="breadcrumbs py-3">
                    <li
                        class="breadcrumbs__item breadcrumbs__item--active"
                        style="cursor: pointer"
                    >
                        <a @click="gotoinfo()">Información de cliente</a>
                        <span class="icon-rigth"></span>
                    </li>
                    <li
                        style="cursor: pointer"
                        class="breadcrumbs__item"
                        :class="
                            isvalitateinfo == true || ispay == true
                                ? 'breadcrumbs__item--active'
                                : ''
                        "
                    >
                        <a @click="gotovalid()">Validación de información</a>
                        <span class="icon-rigth"></span>
                    </li>
                    <li
                        style="cursor: pointer"
                        class="breadcrumbs__item"
                        :class="
                            ispay == true ? 'breadcrumbs__item--active' : ''
                        "
                    >
                        <a @click="gotopay()">Pago</a>
                    </li>
                </ul>
                <div>
                    <h5 style="font-weight: bold" v-if="isinputdata">
                        Información de cliente
                    </h5>
                    <h6 v-if="isinputdata">
                        Datos de persona que hace la compra
                    </h6>
                    <h5 style="font-weight: bold" v-if="isvalitateinfo">
                        Validación de información
                    </h5>
                    <h5 style="font-weight: bold" v-if="ispay">Pago</h5>
                </div>
                <div class="row mx-n2" v-if="isinputdata">
                    <div class="col-12 py-2">
                        <input
                            class="form-control"
                            type="text"
                            :class="errors.email ? 'is-invalid' : ''"
                            placeholder="Correo electrónico"
                            v-model="form.email"
                        />
                        <div v-if="$v.form.email.$error" class="error">
                            <div class="error-message" v-if="!$v.form.email.required">El correo electrónico es requerido.</div>
                            <div class="error-message" v-if="!$v.form.email.email">El correo electrónico no es válido.</div>
                        </div>
                    </div>
                    <div class="col-12 py-2">
                        <input
                            class="form-control"
                            :class="errors.phone_number ? 'is-invalid' : ''"
                            type="text"
                            placeholder="Teléfono"
                            v-model="form.phone_number"
                        />
                        <div v-if="$v.form.phone_number.$error" class="error">
                            <div class="error-message" v-if="!$v.form.phone_number.required">El número de teléfono es requerido.</div>
                            <div class="error-message" v-if="!$v.form.phone_number.phoneNumber">El formato del número de teléfono no es válido.</div>
                        </div>
                    </div>
                    <div class="col-12 py-2">
                        <label class="input-checkbox">
                            <input
                                type="checkbox"
                                v-model="form.want_contact"
                            />
                            <span class="input">
                                <span class="icon icon-check"></span>
                            </span>
                            <span class="ml-2 small"
                            >Autorizo para que se pongan en contacto conmigo
                                a través de WhatsApp.</span
                            >
                        </label>
                    </div>
                    <div class="col-12 py-2">
                        <ul style="
                                list-style: none;
                                padding: 0px;
                                border: 1px solid #c0c0c0;
                                border-radius: 10px;">
                            <li style="
                                    padding: 10px;
                                    border-bottom: 1px solid #c0c0c0;
                                ">
                                <label class="input-radio">
                                    <input
                                        type="radio"
                                        name="shipping"
                                        :value="true"
                                        v-model="form.shipping"
                                    />
                                    <span class="input">
                                        <span class="icon"></span>
                                    </span>
                                    <span class="ml-2">
                                        Deseo entreguen mi pedido a domicilio (Solo dentro del depto. de Chalatenango)
                                    </span>
                                </label>
                            </li>
                            <li style="padding: 10px">
                                <label class="input-radio">
                                    <input
                                        type="radio"
                                        name="shipping"
                                        :value="false"
                                        v-model="form.shipping"
                                        :checked="true"
                                    />
                                    <span class="input">
                                        <span class="icon"></span>
                                    </span>
                                    <span class="ml-2">
                                        Deseo retirar mi pedido en tienda
                                    </span>
                                </label>
                            </li>
                        </ul>
                    </div>

                    <div class="col-12 py-2 px-2 mt-2">
                        <h6 class="font-weight-bold">
                            Datos de persona
                            {{
                                form.shipping
                                    ? "que recibe"
                                    : "que retirará el pedido"
                            }}
                        </h6>
                    </div>
                    <div class="col-12 col-md-6 py-2 px-2">
                        <input
                            class="form-control"
                            type="text"
                            :class="errors.receptor_name ? 'is-invalid' : ''"
                            placeholder="Nombres"
                            v-model="form.receptor_name"
                        />
                    </div>
                    <div class="col-12 col-md-6 py-2 px-2">
                        <input
                            class="form-control"
                            type="text"
                            :class="errors.receptor_lastname ? 'is-invalid' : ''"
                            placeholder="Apellidos"
                            v-model="form.receptor_lastname"
                        />
                    </div>
                    <div class="col-12 py-2 px-2">
                        <input
                            class="form-control"
                            type="text"
                            placeholder="Nombre de empresa (opcional)"
                            v-model="form.receptor_location_name"
                        />
                    </div>
                    <div class="col-12 col-lg-6 py-2 px-2" v-if="form.shipping">
                        <select
                            :class="errors.receptor_department_id ? 'is-invalid' : ''"
                            @change="getMunicipalitiesByDepartment()"
                            class="form-control"
                            type="text"
                            placeholder="Selecciona un Departamento"
                            v-model="form.receptor_department_id">
                            <option value="" disabled>
                                Selecciona un Departamento
                            </option>
                            <option
                                v-for="(depa, index) in department"
                                :style="
                                    depa.id != 4
                                        ? 'color:#C0C0C0'
                                        : 'color:#696969'
                                "
                                :disabled="depa.id != 4"
                                :key="`department_${index}`"
                                :value="depa.id"
                            >
                                {{ depa.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-12 col-lg-6 py-2 px-2" v-if="form.shipping">
                        <select
                            @change="getZonesByMunicipalities()"
                            class="form-control"
                            :class="errors.receptor_municipalities_id
                                    ? 'is-invalid'
                                    : ''"
                            type="text"
                            placeholder="Selecciona un Municipio"
                            v-model="form.receptor_municipalities_id"
                        >
                            <option value="" disabled>
                                Selecciona un Municipio
                            </option>
                            <option
                                v-for="(muni, index) in municipalities"
                                :key="`muni_${index}`"
                                :value="muni.id"
                            >
                                {{ muni.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-12 py-2 px-2" v-if="form.shipping">
                        <select
                            @change="getPricesByZone()"
                            class="form-control"
                            :class="errors.receptor_city_id ? 'is-invalid' : ''"
                            type="text"
                            placeholder="Selecciona zona"
                            v-model="form.receptor_city_id"
                        >
                            <option value="" disabled>Selecciona zona</option>
                            <option
                                v-for="(zone, index) in zones"
                                :key="`muni_${index}`"
                                :value="zone.id"
                            >
                                {{ zone.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-12 py-2 px-2" v-if="form.shipping">
                        <input
                            class="form-control"
                            :class="errors.receptor_adress ? 'is-invalid' : ''"
                            type="text"
                            placeholder="Número de casa, barrio, clúster, colonia, punto de referencia"
                            v-model="form.receptor_adress"
                        />
                    </div>
                    <div class="col-12 col-md-8 py-2 px-2">
                        <h6 class="font-weight-bold" v-if="form.shipping">Fecha en que desea recibir su pedido</h6>
                        <input
                            class="form-control"
                            :class="errors.receptor_date ? 'is-invalid' : ''"
                            type="date"
                            placeholder="Fecha para pasar a tienda"
                            v-model="form.receptor_date"/>
                        <p class="error-message" v-if="errors.receptor_date">{{ errorMessage }}</p>
                    </div>
                    <div class="col-12 col-md-4 py-2 px-2"
                         v-if="!form.shipping">
                        <div class="row">
                            <div class="col-3"
                                 style="
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                ">
                                Hora:
                            </div>
                            <div class="col-9">
                                <input
                                    class="form-control"
                                    :class="errors.receptor_time ? 'is-invalid' : ''"
                                    type="time"
                                    placeholder="Hora"
                                    v-model="form.receptor_time"
                                    min="08:00"
                                    max="17:00"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 py-2 px-2">
                        <input
                            class="form-control"
                            :class="errors.receptor_phone_number ? 'is-invalid' : ''"
                            type="text"
                            placeholder="Número de teléfono móvil"
                            v-model="form.receptor_phone_number"
                        />
                        <div v-if="$v.form.receptor_phone_number.$error" class="error">
                            <div class="error-message" v-if="!$v.form.receptor_phone_number.required">El número de teléfono del receptor es requerido.</div>
                            <div class="error-message" v-if="!$v.form.receptor_phone_number.phoneNumber">El formato del número de teléfono del receptor no es válido.</div>
                        </div>
                    </div>

                    <div class="col-12 py-2 px-2">
                        <h6 class="font-weight-bold">En que turno desea recibir su pedido:</h6>
                        <ul style="list-style: none; padding: 0;">
                            <li style="padding: 0;">
                                <label class="input-radio">
                                    <input type="radio" name="deliveryPeriod" id="morning" value="AM" v-model="form.delivery_period" />
                                    <span class="input">
                                        <span class="icon"></span>
                                    </span>
                                    <span class="ml-2">AM</span>
                                </label>
                            </li>
                            <li style="padding: 0;">
                                <label class="input-radio">
                                    <input type="radio" name="deliveryPeriod" id="afternoon" value="PM" v-model="form.delivery_period" />
                                    <span class="input">
                                        <span class="icon"></span>
                                    </span>
                                    <span class="ml-2">PM</span>
                                </label>
                            </li>
                        </ul>
                        <p class="error-message" v-if="errors.delivery_period">* Por favor, selecciona una opción de horario.</p>
                    </div>

                    <div class="col-12 py-2" v-if="form.shipping">
                        <label class="input-checkbox">
                            <input
                                type="checkbox"
                                @change="addProductOutOfDate()"
                                v-model="form.date_1"
                                :disabled="form.date_2"
                            />
                            <span class="input">
                                <span class="icon icon-check"></span>
                            </span>
                            <span class="ml-2 small"
                            >Deseo mi envío entre 8:00am y 9:00am
                                (+$3.00)</span
                            >
                        </label>
                    </div>
                    <div class="col-12 py-2" v-if="form.shipping">
                        <label class="input-checkbox">
                            <input
                                type="checkbox"
                                v-model="form.date_2"
                                @change="addProductOutOfDate()"
                                :disabled="form.date_1"
                            />
                            <span class="input">
                                <span class="icon icon-check"></span>
                            </span>
                            <span class="ml-2 small">
                                Deseo mi envío entre 4:00pm y 5:00pm
                                (+$3.00)</span>
                        </label>
                    </div>

                    <div class="col-12 py-2 px-2 mt-2">
                        <h6 class="font-weight-bold">
                            Incluir un mensaje impreso en la tarjeta
                        </h6>
                    </div>
                    <div class="col-12 col-md-6 py-2 px-2">
                        <input
                            class="form-control"
                            type="text"
                            :class="errors.from ? 'is-invalid' : ''"
                            placeholder="De:"
                            v-model="form.from"
                        />
                    </div>
                    <div class="col-12 col-md-6 py-2 px-2">
                        <input
                            class="form-control"
                            :class="errors.to ? 'is-invalid' : ''"
                            type="text"
                            placeholder="Para:"
                            v-model="form.to"
                        />
                    </div>
                    <div class="col-12 py-2 px-2">
                        <textarea
                            class="form-control"
                            maxlength="120"
                            type="text"
                            rows="5"
                            placeholder="Mensaje (máximo 120 caracteres)."
                            v-model="form.message"
                        >
                        </textarea>
                    </div>
                    <div class="col-12 py-2">
                        <label class="input-checkbox">
                            <input type="checkbox" v-model="form.subscribe" />
                            <span class="input">
                                <span class="icon icon-check"></span>
                            </span>
                            <span class="ml-2 small"
                            >Mantenerme actualizado con ofertas
                                exclusivas</span
                            >
                        </label>
                    </div>
                </div>

                <div class="row mx-n2" v-if="isvalitateinfo">
                    <div class="col-12 py-2">
                        <ul
                            style="
                                list-style: none;
                                padding: 0px;
                                border: 1px solid #c0c0c0;
                                border-radius: 10px;
                            "
                        >
                            <li
                                style="
                                    padding: 10px;
                                    border-bottom: 1px solid #c0c0c0;
                                "
                            >
                                <div class="row">
                                    <span class="col-12 col-md-2">
                                        Recibe
                                    </span>
                                    <span class="col-12 col-md-6">
                                        {{ form.receptor_name }}
                                        {{ form.receptor_lastname }}
                                    </span>
                                    <span
                                        class="col-12 col-md-4"
                                        style="
                                            display: flex;
                                            justify-content: flex-end;
                                        "
                                    >
                                        <a href="#" @click="changeInfo()"
                                        >Cambiar</a
                                        >
                                    </span>
                                </div>
                            </li>
                            <li
                                style="
                                    padding: 10px;
                                    border-bottom: 1px solid #c0c0c0;
                                "
                            >
                                <div class="row">
                                    <span class="col-12 col-md-2">
                                        Dirección
                                    </span>
                                    <span class="col-12 col-md-6">
                                        {{
                                            displayAddress(
                                                form.receptor_department_id,
                                                "dep"
                                            )
                                        }}
                                        {{
                                            displayAddress(
                                                form.receptor_municipalities_id,
                                                "muni"
                                            )
                                        }}

                                        {{
                                            displayAddress(
                                                form.receptor_city_id,
                                                "zone"
                                            )
                                        }}
                                        {{ form.receptor_adress }}
                                    </span>
                                    <span
                                        class="col-12 col-md-4"
                                        style="
                                            display: flex;
                                            justify-content: flex-end;
                                        "
                                    >
                                        <a href="#" @click="changeInfo()"
                                        >Cambiar</a
                                        >
                                    </span>
                                </div>
                            </li>
                            <li style="padding: 10px">
                                <div class="row">
                                    <span class="col-12 col-md-2">
                                        Teléfono
                                    </span>
                                    <span class="col-12 col-md-6">
                                        {{ form.receptor_phone_number }}
                                    </span>
                                    <span
                                        class="col-12 col-md-4"
                                        style="
                                            display: flex;
                                            justify-content: flex-end;
                                        "
                                    >
                                        <a href="#" @click="changeInfo()">Cambiar</a>
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="col-12 py-2 px-2 mt-2">
                        <h6 class="font-weight-bold">
                            Incluir un mensaje impreso en la tarjeta
                        </h6>
                    </div>
                    <div class="col-12 col-md-6 py-2 px-2">
                        <input
                            class="form-control"
                            type="text"
                            :class="errors.from ? 'is-invalid' : ''"
                            placeholder="De:"
                            v-model="form.from"
                        />
                    </div>
                    <div class="col-12 col-md-6 py-2 px-2">
                        <input
                            class="form-control"
                            type="text"
                            :class="errors.to ? 'is-invalid' : ''"
                            placeholder="Para:"
                            v-model="form.to"
                        />
                    </div>
                    <div class="col-12 py-2 px-2">
                        <textarea
                            class="form-control"
                            :class="errors.message ? 'is-invalid' : ''"
                            maxlength="120"
                            type="text"
                            rows="5"
                            placeholder="Mensaje (máximo 120 caracteres)."
                            v-model="form.message">
                        </textarea>
                    </div>
                </div>

                <div class="row mx-n2" v-if="ispay">
                    <div class="col-12 py-2">
                        <ul style="
                                list-style: none;
                                padding: 0;
                                border: 1px solid #c0c0c0;
                                border-radius: 10px;">
                            <li style="padding: 10px;border-bottom: 1px solid #c0c0c0;">
                                <div class="row">
                                    <span class="col-12 col-md-8">
                                        <label class="input-radio">
                                            <input
                                                type="radio"
                                                name="typepay"
                                                :value="1"
                                                :disabled="getTotalAfterDiscounts(cart.total, form.envio, globalDiscount) <= 0"
                                                v-model="form.typepay"
                                            />
                                            <span class="input">
                                                <span class="icon"></span>
                                            </span>
                                            <span class="ml-2">
                                                Tarjeta de crédito ó débito
                                            </span>
                                        </label>
                                    </span>
                                    <span
                                        class="col-12 col-md-4"
                                        style="
                                            display: flex;
                                            justify-content: flex-end;
                                            align-items: center;
                                        "
                                    >
                                        <img
                                            src="/images/imgcard.png"
                                            style="width: 100px"
                                            alt=""
                                        />
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 py-2">
                        <ul style="
                                list-style: none;
                                padding: 0;
                                border: 1px solid #c0c0c0;
                                border-radius: 10px;">
                            <li style="padding: 10px;border-bottom: 1px solid #c0c0c0;">
                                <div class="row">
                                    <span class="col-12 col-md-8">
                                        <label class="input-radio">
                                            <input
                                                type="radio"
                                                name="typepay"
                                                :disabled="
                                                    getTotalAfterDiscounts(
                                                        cart.total,
                                                        form.envio,
                                                        globalDiscount
                                                    ) == 0 || form.shipping
                                                "
                                                :value="2"
                                                v-model="form.typepay"
                                            />
                                            <span
                                                class="input"
                                                :class="form.shipping == true ? 'disableradio' : ''">
                                                <span class="icon"></span>
                                            </span>
                                            <span class="ml-2" :class="{ 'bold-text': form.typepay === 2 }">
                                                Efectivo (solo para recoger en tienda)
                                            </span>
                                        </label>
                                    </span>
                                    <span
                                        class="col-12 col-md-4"
                                        style="
                                            display: flex;
                                            justify-content: flex-end;
                                            align-items: center;
                                        "
                                    >
                                        <img :src="form.shipping == true ? '/images/moneicon.png' : '/images/icono_dinero.svg'"
                                             style="width: 30px"
                                             alt=""
                                        />
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 py-2">
                        <ul style="
                                list-style: none;
                                padding: 0px;
                                border: 1px solid #c0c0c0;
                                border-radius: 10px;
                            ">
                            <li style="
                                    padding: 10px;
                                    border-bottom: 1px solid #c0c0c0;
                                ">
                                <div class="row">
                                    <span class="col-12 col-md-8">
                                        <label class="input-radio">
                                            <input
                                                type="radio"
                                                name="typepay"
                                                :value="3"
                                                v-model="form.typepay"
                                                :disabled="
                                                    getTotalAfterDiscounts(
                                                        cart.total,
                                                        form.envio,
                                                        globalDiscount
                                                    ) <= 0
                                                "
                                            />
                                            <span class="input">
                                                <span class="icon"></span>
                                            </span>
                                            <span class="ml-2" :class="{ 'bold-text': form.typepay === 3 }">
                                                Transferencia bancaria
                                            </span>
                                        </label>
                                    </span>
                                    <span
                                        class="col-12 col-md-4"
                                        style="
                                            display: flex;
                                            justify-content: flex-end;
                                            align-items: center;
                                        "
                                    >
                                        <img
                                            src="/images/bankicon.png"
                                            style="width: 30px"
                                            alt=""
                                        />
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="d-flex">
                    <ul class="breadcrumbs py-3"
                        style="
                            padding: 0px !important;
                            margin: 0px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        ">
                        <li
                            v-if="isvalitateinfo"
                            class="breadcrumbs__item breadcrumbs__item--active"
                            style="cursor: pointer"
                        >
                            <span class="icon-left"></span>
                            <a @click="gotoinfo()">Información de cliente</a>
                        </li>
                        <li
                            v-if="ispay"
                            style="cursor: pointer"
                            class="breadcrumbs__item"
                            :class="
                                isvalitateinfo == true || ispay == true
                                    ? 'breadcrumbs__item--active'
                                    : ''
                            "
                        >
                            <span class="icon-left"></span>
                            <a @click="gotovalid()"
                            >Validación de información</a
                            >
                        </li>
                    </ul>
                    <button
                        class="btn btn-lg btn-primary ml-auto"
                        v-if="!ispay"
                        @click="handlerContinue()"
                    >
                        Continuar
                    </button>
                    <button
                        id="btnpago"
                        class="btn btn-lg btn-primary ml-auto"
                        v-if="ispay"
                        @click="handlerContinue()"
                    >
                        Pagar
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6" >
                <div style="background-color: #f1f1f1; height: 100%">
                    <div class="payment__resumen py-4 px-5">
                        <div
                            class="payment__products"
                            style="overflow-x: hidden"
                        >
                            <div
                                v-for="(product, index) in cart.cart"
                                :key="`extras_${index}_${product.id}`"
                                class="d-flex mb-3 align-items-center row"
                            >
                                <div class="col-6 col-md-3">
                                    <div class="d-flex align-items-center">
                                        <button
                                            class="btn--rounded--payment"
                                            :disabled="product.qty <= 1"
                                            @click="diminish(product)">
                                            <span class="icon-menus"></span>
                                        </button>
                                        <div class="px-2">
                                            <input
                                                type="number"
                                                min="1"
                                                disabled
                                                class="form-control text-center border-primary no-arrows"
                                                v-model="product.qty"
                                            />
                                        </div>
                                        <button class="btn--rounded--payment" @click="increase(product)">
                                            <span class="icon-plus"></span>
                                        </button>
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="d-flex align-items-center">
                                        <div class="extra__photo">
                                            <img
                                                :src="product.thumbnail"
                                                alt=""
                                            />
                                        </div>
                                        <div class="px-1">
                                            <h5 class="m-0 extra__title">
                                                {{ product.name
                                                }}{{
                                                    form.date_1 == true && product.id == 38
                                                        ? " 8:00am y 9:00am "
                                                        : ""
                                                }}
                                                {{
                                                    form.date_2 == true && product.id == 38
                                                        ? " 4:00pm y 5:00pm "
                                                        : ""
                                                }}
                                            </h5>
                                            <p class="extra__price m-0">
                                                ${{ product.price }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-3 text-right">
                                    <h6 class="m-0">
                                        ${{
                                            (parseFloat(product.price) * product.qty)
                                                | numeral("0,0.00")
                                        }}
                                    </h6>
                                    <button
                                        class="btn btn-ligth btn-sm border-primary text-primary"
                                        @click="removeFromCart(product)"
                                        :disabled="product.saving"
                                    >
                                        Remover
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-wrap">
                            <div class="col-12 col-md-10 col-lg-8 mx-auto">
                                <div class="border-top border-bottom py-2">
                                    <div class="d-flex">
                                        <div class="flex-grow-1">
                                            <input
                                                class="form-control"
                                                type="text"
                                                v-model="coupon"
                                                placeholder="Tarjeta de regalo o cupón de descuento"
                                            />
                                            <span
                                                class="text-danger"
                                                v-if="!validCupon"
                                            >
                                                Vaya! cupón no encontrado!
                                            </span>
                                            <span
                                                class="text-danger"
                                                v-if="alreadyApplied"
                                            >
                                                Cupón ingresado!
                                            </span>
                                            <span
                                                class="text-success"
                                                v-if="appliedSuccess"
                                            >
                                                Cupón aplicado!
                                            </span>
                                        </div>
                                        <div class="pl-3">
                                            <button class="btn btn-primary" @click="applyCoupon()">
                                                Aplicar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-wrap">
                            <div class="col-12 col-md-10 col-lg-8 mx-auto">
                                <div
                                    class="border-bottom py-2"
                                    id="promoCodesApplied"
                                >
                                    <div class="d-flex">
                                        <div>
                                            <p class="m-0">Subtotal</p>
                                        </div>
                                        <div class="flex-grow-1 text-right">
                                            <p class="m-0">
                                                ${{ total | numeral("0,0.00") }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div>
                                            <p class="m-0">Envío</p>
                                        </div>
                                        <div class="flex-grow-1 text-right">
                                            <p class="m-0">
                                                {{ shippingCost === 0 ? "" : "$" }}{{ shippingCost | numeral("0,0.00") }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-wrap">
                            <div class="col-12 col-md-10 col-lg-8 mx-auto">
                                <div class="border-bottom py-2">
                                    <div class="d-flex">
                                        <div>
                                            <p class="m-0">Total</p>
                                        </div>
                                        <div class="flex-grow-1 text-right">
                                            <p class="m-0">
                                                USD
                                                <!-- getting actual price after discounts -->
                                                <span style="font-size: 1.2rem">
                                                    ${{ grandTotal | numeral("0,0.00")}}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="row d-flex"
            v-if="isfinish"
            style="
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;">
            <img
                src="/images/successimg.png"
                alt=""
                style="width: 320px; margin: 39px"
            />
            <h3 style="font-weight: bold; text-align: center">
                ¡Muchas gracias por tu compra!
            </h3>
            <p>Tu número de orden es</p>
            <p style="font-size: 50px; font-weight: bold">{{ order }}</p>
            <p style="text-align: center; width: 50%">
                Está siendo procesada y nos pondremos en contacto contigo lo
                antes posible para hacerte la entrega. Un correo de confirmación
                se te será enviado.
            </p>
            <a class="btn btn-lg btn-primary" href="/">Ir a Inicio</a>
        </div>
    </div>
</template>
<style>
.bold-text {
    font-weight: bold;
}
.error-message {
    color: #ff4136;
    font-size: 12px;
    margin-top: 5px;
}
</style>
